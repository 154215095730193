import React, { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { useHistory, useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
// import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
// import classNames from 'classnames';
// import { TbEdit } from "react-icons/tb";
import { Triangle } from 'react-loader-spinner';
import './IndividualDataPending.css';
// import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';

function IndividualDataPending(props) {

  const [accountdetials, setAccountDetials] = useState('');
  const [statusoption, setSelectedOption] = useState('');
  const [comments, setComments] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const toast = React.useRef(null);
  const [, setSubmitted] = useState(false);
  const [, setBeneficiary] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tabledetials, setTabledetials] = useState('');
  // const [activeIndex, setActiveIndex] = useState(0);
  const [notForData, setNotForData] = useState(false);
  const location = useLocation();
  const clientId = location?.state?.clientId;
  const individualdatavalue = clientId;
  const accessToken = location?.state?.accessToken;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACKEND_HOST + '/accounts/pending?acc_client_id=' + individualdatavalue,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
          }
        }
      )
      .then(response => {
        if (response && response.data) {
          setAccountDetials(response.data); // Extract the data array from the response
          setBeneficiary(response.data.accounts || []);
          setLoading(false);
          setTabledetials(response?.data?.sanctions || [])
        } else {
          // console.log('Response or response data is missing.');
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false)
        setNotForData(true);
        setAccountDetials('');
        setTabledetials('');
        if (error?.response && error?.response?.data && error?.response?.data?.message) {
          const errorMessage = error?.response?.data?.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
        } else if (error?.response && error?.response?.status === 403) {
          window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
          sessionStorage.clear();
        } else if (error?.response?.status === 401) {
          toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
        }
      });
  }, [individualdatavalue,accessToken]);


  // const beneficiarytable = accountdetials?.sanctions || [];
  // let beneficiarytables = accountdetials.accounts;
  // const ownershiptable = accountdetials?.ownership || [];

  const actionbody = (rowData) => {
    const { sanctionRelationshipId, account } = rowData;

    const nextpageWithSessionStorage = () => {
      nextpage(sanctionRelationshipId, account);
    };
    return (
      <Button
        style={{ color: '#124A99', fontWeight: 'bold' }}
        className="p-button p-button-text p-button-info pi pi-info-circle"
        onClick={nextpageWithSessionStorage}
      />
    );
  };

  const [visible, setVisible] = useState(false);

  const hideDialog = () => {
    setVisible(false);
  };

  const statusoptions = [
    { name: 'On-going', value: 'On-going' },
    { name: 'Cleared', value: 'Cleared' },
    { name: 'Blocked', value: 'Blocked' }

  ];

  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

  };

  const profileTemplate = (rowData) => {
    const status = rowData.screeningStatus;
    let color = '';

    if (status === 'Pending') {
      color = '#f7af00';
    } else if (status === 'Blocked') {
      color = '#f42616';
    } else if (status === 'Cleared') {
      color = 'rgb(69 160 75)';
    } else if (status === 'On-going') {
      color = '#3746c6';
    }

    return (
      <div style={{
        color: color,
        fontWeight: '500',
      }}>
        {status}
      </div>
    );
  };

  // const email = location?.state?.emailId;

  const update = (event) => {

    event.preventDefault()
    setSubmitted(true);
    if (
      !statusoption
    ) {
      return; // Don't proceed with submission if any required field is empty
    }
    // let updateid = accountdetials.clientId;

    let bodyvalue = {
      status: statusoption,
      comment: comments,
      sanctionIds: selectedItems.reduce((acc, item) => {
        acc[item.sanctionRelationshipId] = true;
        return acc;
      }, {}),
    }

    if (selectedItems?.length >= 1) {

      axios.put(process.env.REACT_APP_BACKEND_HOST + '/accounts/pending/sanction' , bodyvalue, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      })
        .then(response => {
          // setAccountDetials(response.data);
          if (response.status === 200) {
            hideDialog();
            toast.current.show({ severity: 'success', summary: 'Successfully', detail: response?.data?.message, life: 4000 });
            setTimeout(() => {
              history.push({
                pathname: '/admin/account/pending',
                state: {
                      emailId: location?.state?.emailId,
                      accPemding: location?.state?.accPemding,
                      benPending: location?.state?.benPending,
                      traPending: location?.state?.traPending,
                      accessToken: accessToken,
                      getUserData: location?.state?.getUserData
                },
              });
            }, 5000);
          }
        })
        .catch(error => {
          setVisible(false);
          if (error?.response && error?.response?.data && error?.response?.data?.message) {
            const errorMessage = error?.response?.data.message;
            toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
          } else if (error?.response && error?.response?.status === 403) {
            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
            sessionStorage.clear();
          } else if (error?.response?.status === 401) {
            toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: '400 Bad Request', life: 3000 });
          }
        });

    } else {
      setVisible(false);
      toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Select a Similarities', life: 3000 });
    }
  }

  const nextpage = (sanctionRelationshipId, account) => {
    history.push({
      pathname: '/admin/account/individualpendingmoreinfo',
      state: {
        emailId: location?.state?.emailId,
        accPemding: location?.state?.accPemding,
        benPending: location?.state?.benPending,
        traPending: location?.state?.traPending,
        sanctionRelationshipId: sanctionRelationshipId,
        account: account,
        clientId: location?.state?.clientId,
        accessToken: accessToken,
        getUserData: location?.state?.getUserData
      },
    })
  }
  // const nextpages = () => {
  //   history.push('/admin/account/corporatependingmoreinfo')
  // }

  const updateaccdet = () => {
    if (selectedItems.length === 0) {
      toast.current.show({ severity: "warn", summary: "No Checkbox Selected", detail: "Please select similarities screened is at leaste one item.", life: 3000 });
    } else {
      setVisible(true);
    }
  }

  const items = [
    {
      label: 'Risk Check', command: () =>
        history.push({
          pathname: '/admin/account/pending',
          state: {
            emailId: location?.state?.emailId,
            accPemding: location?.state?.accPemding,
            benPending: location?.state?.benPending,
            traPending: location?.state?.traPending,
            accessToken: accessToken,
            getUserData: location?.state?.getUserData
          },
        })
    },
    {
      label: 'Pending List', command: () =>
        history.push({
          pathname: '/admin/account/pending',
          state: {
            emailId: location?.state?.emailId,
            accPemding: location?.state?.accPemding,
            benPending: location?.state?.benPending,
            traPending: location?.state?.traPending,
            accessToken: accessToken,
            getUserData: location?.state?.getUserData
          },
        })
    },
    { label: 'Account Details' },
  ];
  const home = {
    icon: 'pi pi-home', command: () =>
      history.push({
        pathname: '/admin/dashboard',
        state: {
          accessToken: location?.state?.accessToken,
          emailId: location?.state?.emailId,
          getUserData: location?.state?.getUserData,
        },
      })
  }
  const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
  // const handleNavigate = (url) => {
  //   history.push(url);
  // };

  // const isTabOpen = index => activeIndex === index;

  // const accordionTabHeader = (headerText, isOpen) => (
  //   <>
  //     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  //       <div style={{ fontWeight: 'bold' }}>
  //         <i className={`jj ${headerText === 'Account Details' ? 'pi pi-users' : 'pi pi-desktop'}`}><div style={{ fontFamily: 'system-ui' }}>{headerText}</div></i>
  //       </div>
  //     </div>

  //   </>
  // );


  // const handleTabChange = index => {
  //   setActiveIndex(prevIndex => (prevIndex === index ? null : index));
  // };

  return (
    <>
      <div className="mainSec" >
        <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
        <Toast ref={toast} style={{ marginTop: '50px' }} />
        {loading ? (
          <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
            <Triangle
              height="80"
              width="80"
              color="#124A99"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>

            <Card className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>

              {tabledetials?.length >= 1 && (
                <div style={{ display: 'flex', height: '50px', justifyContent: 'end', alignItems: 'center' }}>
                   {props.roleName === "ProAdmin" &&
                  <Button className='back_BT_N' label='Edit' onClick={updateaccdet} />}
                </div>
              )}

              {notForData && !accountdetials && tabledetials?.length < 1 && (
                <div className='head' style={{ width: 'fit-content', cursor: 'pointer' }} onClick={() => window.history.back()}>
                  Don't have data's
                </div>
              )}

              {accountdetials && (
                <div className='h_ing'>
                  Account Details
                </div>
              )}

              {accountdetials?.beneficiaryId && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Beneficiary Id <span>:</span></div>
                  <div className='value'>{accountdetials?.beneficiaryId || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.fullName && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Full Name <span>:</span></div>
                  <div className='value'>{accountdetials?.fullName || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.arabicName && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Arabic Name <span>:</span></div>
                  <div className='value'>{accountdetials?.arabicName || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.gender && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Gender <span>:</span></div>
                  <div className='value'>{accountdetials?.gender || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.city && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>City <span>:</span></div>
                  <div className='value'>{accountdetials?.city || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.country && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Country <span>:</span></div>
                  <div className='value'>{accountdetials?.country || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.idNumber && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Id Number <span>:</span></div>
                  <div className='value'>{accountdetials?.idNumber || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.nationality && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Nationality <span>:</span></div>
                  <div className='value'>{accountdetials?.nationality || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.id && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Postal Code <span>:</span></div>
                  <div className='value'>{accountdetials?.id || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.idNumber && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Id Card Type <span>:</span></div>
                  <div className='value'>{accountdetials?.idNumber || 'N/A'}</div>
                </div>
              )}

              {accountdetials?.address && (
                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Address <span>:</span></div>
                  <div className='value'>{accountdetials.address
                    .toLowerCase()
                    .split(' ')
                    .map((word, index) => index === 0 ? word : ` ${word.charAt(0).toUpperCase() + word.slice('6')}`)  // Add a comma and capitalize each word except the first one
                    .join('') || 'N/A'}</div>
                </div>
              )}

              {tabledetials?.length >= 1 && (
                <>
                  <div className='h_ing'>
                    Similarities
                  </div>

                  <div style={{ padding: '20px 0' }}>
                    <DataTable
                      value={tabledetials}
                      style={{ whiteSpace: 'nowrap', width: '100%' }}
                      selectionMode="multiple"
                      selection={selectedItems}
                      onSelectionChange={(e) => setSelectedItems(e.value)}
                      stripedRows
                      showGridlines
                      paginator
                      rows={5}
                      rowsPerPageOptions={[5, 10, 25, 50]}
                    >
                      {props.roleName === "ProAdmin" &&<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>}
                      <Column field="sanctionIndividual.fullName" bodyClassName="" header="Full Name"  ></Column>
                      <Column field="category" bodyClassName="" header="Category"></Column>
                      <Column field="comments" bodyClassName="" header="Comments"></Column>
                      <Column field="screeningStatus" bodyClassName="" header="Status" body={profileTemplate} ></Column>
                      <Column field="moreinfo" style={{ width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }} bodyClassName="" header="More Info" body={actionbody}></Column>
                    </DataTable>
                  </div>
                </>
              )}

            </Card>
          </>
        )}
      </div >

      <Dialog header="Update Beneficiary Pending" className='sing_up_main_Dialog' style={{ width: '30rem' }} visible={visible} onHide={hideDialog}>
        <div style={{ height: 'auto', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <div className="feild" style={{ padding: 'unset' }}>
            <div className="flex" style={{ flexDirection: 'column' }}>
              <div className="input">
                <div className="label">Status</div>
                <Dropdown value={statusoption} onChange={handleStatusChange} options={statusoptions} optionLabel="name" style={{ width: '100%', border: '1px solid #ced4da', borderRadius: '3px' }} />
              </div>
              <div className="input">
                <div className="label">Comments</div>
                <InputTextarea rows={2} cols={1} onChange={(event) => setComments(event.target.value)} className="w-full" />
              </div>
            </div>
            <div className="flex_footer_btn otp_footer">
              <Button className="footer_Sub_Btn" onClick={update} disabled={!comments || !statusoption}>Update</Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default IndividualDataPending