import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { IoInformationCircleOutline } from "react-icons/io5";
import { useHistory } from 'react-router-dom';
import './Remaining.css';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

const YourComponent = () => {
    const [countdownFinished, setCountdownFinished] = useState(false);
    const [remainingTime, setRemainingTime] = useState("");
    const toast = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    const roleName = location?.state?.roleName || location?.state?.role;
    const email = location?.state?.emailId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/remainingTime`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                setRemainingTime(response.data.remainingTime);
                // setRolename(response.data.remainingTime);
            } catch (error) {
                if (error?.response && error?.response?.status === 401) {
                    history.push({
                        pathname: '/payment',
                        state: {
                            emailId: email,
                            accessToken: accessToken,
                            type: 'Remain_ing',
                        },
                    });
                }
            }
        };

        fetchData();
    }, [email, accessToken,history ]);

    useEffect(() => {
        const parseRemainingTime = (timeString) => {
            const units = timeString?.match(/\d+\s\w+/g);
            let milliseconds = 0;
            if (units) {
                units.forEach(unit => {
                    const [value, type] = unit.split(' ');
                    switch (type) {
                        case 'days':
                            milliseconds += parseInt(value) * 24 * 60 * 60 * 1000;
                            break;
                        case 'hours':
                            milliseconds += parseInt(value) * 60 * 60 * 1000;
                            break;
                        case 'minutes':
                            milliseconds += parseInt(value) * 60 * 1000;
                            break;
                        case 'seconds':
                            milliseconds += parseInt(value) * 1000;
                            break;
                        default:
                            break;
                    }
                });
            }
            return milliseconds;
        };

        const timeLeft = parseRemainingTime(remainingTime);
        const endTimeMillis = new Date().getTime() + timeLeft;

        const intervalId = setInterval(() => {
            const gap = endTimeMillis - new Date().getTime();
            if (gap <= 0) {
                clearInterval(intervalId);
                setCountdownFinished(true);
            } else {
                const days = Math.floor(gap / (1000 * 60 * 60 * 24));
                const hours = Math.floor((gap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((gap % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((gap % (1000 * 60)) / 1000);
                setCountdown({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [remainingTime]);

    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    const handleInfoClick = () => {

        toast.current.show({ severity: 'info', summary: 'Info', detail: 'Upgrade your payment plan for additional features!', life: 1000 });
    };

    // useEffect(() => {
    //     if (countdownFinished) {
    //         const timeoutId = setTimeout(() => {
    //             history.push({
    //                 pathname: '/payment',
    //                 state: {
    //                     emailId: email,
    //                     accessToken: accessToken,
    //                     type: 'Remain_ing',
    //                 },
    //             });
    //             if(email) {
    //                 fetch(process.env.REACT_APP_IDM_BACKEND_HOST + "/reload/reload/" + email, {
    //                     method: "GET",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                     },
    //                 });
    //             }
    //         }, );

    //         return () => clearTimeout(timeoutId);
    //     }
    // }, [countdownFinished, history, email, accessToken]);
    
    // const totalRemainingSeconds = countdown.days * 24 * 60 * 60 + countdown.hours * 60 * 60 + countdown.minutes * 60 + countdown.seconds;
   
    const handleSignOut = () => {
        fetch(process.env.REACT_APP_IDM_BACKEND_HOST + "/reload/reload/" + email, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

    }
    const handleUpgradeClick = () => {
        setLoading(true);
        setTimeout(() => {
            history.push({
                pathname: '/payment',
                state: {
                    emailId: email,
                    accessToken: accessToken,
                    roleName: roleName,
                    type: 'Remain_ing',
                },
            });
            handleSignOut();
            window.location.reload();
        }, 3000);
    }
    return (
        <div className='remaining-time'>
            <Toast ref={toast} position="top-center" />
           
                <div className='remaining-times '>
                    <IoInformationCircleOutline
                        style={{ fontSize: '19px', cursor: 'pointer' }}
                        onClick={handleInfoClick}
                    />
                    &nbsp;<h5>{
                        countdown.days > 0 ? (
                            <>
                                {countdown.days} days left of your plan period.
                            </>
                        ) : (
                            <>
                                {countdown.hours} hours {countdown.minutes} minutes {countdown.seconds} seconds left of your plan period.
                            </>
                        )
                   }</h5>
                    <button
                    className='remaining-upgrade'
                    style={{
                        textDecoration: "underline",
                        marginLeft: "5px",
                        fontSize: '13px',
                        fontWeight: 'bold',
                        color: 'white',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={handleUpgradeClick}
                    disabled={loading}
                >
                    {loading ? (
                        <ClipLoader size={15} color={"#ffffff"} loading={loading} />
                    ) : (
                        'Upgrade now'
                    )}
                </button>
                </div>
        </div>
    );
};

export default YourComponent;
