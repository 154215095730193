import React from 'react'
import '../Thirdsection/Third.css'
import acaia_image from '../../Landing Page/media/acaia_image.png'
import { BsDot } from "react-icons/bs";

export default function Third() {

  const items = [
    { name: 'Extensive Aml data integration ' },
    { name: 'Advanced screening accuracy ' },
    { name: 'Responsive Support' },
    { name: 'Customizable Alert preference ' },
    { name: 'Soundex Algorithm' },
    { name: 'Regional Name Support' },
    { name: 'Advanced AI' },
    { name: 'Flexible Integration' },

  ];


  return (
    <div className='third-con'>
      <div className='third-main'>

        <p className="contact-heading" style={{ color: '#093EA4' }}> Why choose ACAIA?</p >
        <div className="">
          <div className="bLOG"><div></div></div>
        </div >

        <p className='third-para' style={{ textAlign: 'justify' }}>
          We are committed to offering advanced screening technology and improving data quality to deliver the most accurate results. By staying current with regulatory changes, we address compatibility concerns by including an extensive array of publicly available compliance lists, such as OFAC, HMT, UN, and Interpol, all covered under your Acaia license.
        </p>
        <br />

        <div className='third_C'>
          <div className='third_C_L'>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[0]?.name}</span>
            </div>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[1]?.name}</span>
            </div>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[2]?.name}</span>
            </div>
          </div>
          <div className='third_C_R'>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[3]?.name}</span>
            </div>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[4]?.name}</span>
            </div>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[5]?.name}</span>
            </div>
          </div>

          <div className='third_C_R'>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[6]?.name}</span>
            </div>
            <div className='third_C_'>
              <BsDot className='third_C_I' />
              <span className='third_C__N'>{items[7]?.name}</span>
            </div>
          </div>
        </div>
        <br />
        <p className='third-para' style={{ textAlign: 'justify' }}>
          Our system simplifies the screening process against financial crime, terrorism financing, and money laundering by reducing false positives through integrating, cleansing, and standardizing compliance data. Our state-of-the-art screening technology empowers clients to take control of their AML initiatives and streamline their compliance processes.
        </p>
        <img src={acaia_image} alt='acaiamain' className='acaia_image'></img>
        <br />
        <br />
        <br />

      </div>
    </div>
  )
}
