import React, { useEffect, useState, useRef } from 'react';
import './DashboardMain.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from "primereact/toast";
import { MdPendingActions } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';
import { GiProgression } from 'react-icons/gi';
import { ImBlocked } from 'react-icons/im';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import { RadioButton } from "primereact/radiobutton";
import 'react-circular-progressbar/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { BreadCrumb } from 'primereact/breadcrumb';
import { useLocation } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Triangle } from 'react-loader-spinner';
import Stomp from 'stompjs';

function DashboardMain() {

    const toast = useRef(null);
    const chartRef = useRef(null);
    const location = useLocation();
    const userData = location.state?.getUserData;
    const accessToken = location.state?.accessToken;
    const plan = location?.state?.getUserData?.premiumPlan;
    const [premiumPlan, setpremiumPlan] = useState();
    const [loading, setLoading] = useState(false);

    // const email = location.state?.emailId;
    const [, setWindowWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);

    const [percentage, setPercentage] = useState('42');

    const [selectOptionsName, setSelectOptionsName] = useState('');
    const [selectOptionsType, setSelectOptionsType] = useState('');
    const [selectOptionsData, setSelectOptionsData] = useState('');
    const [selectOptionsTypeOptions, setSelectOptionsTypeOptions] = useState('');

    const [accountOverviewChart, setAccountOverviewChart] = useState('');
    const [accountOverviewchartData, setAccountOverviewChartData] = useState({});
    const [accountOverviewchartOptions, setAccountOverviewChartOptions] = useState({});

    const [staffOverviewChart, setStaffOverviewChart] = useState('');
    const [staffOverviewData, setStaffOverviewData] = useState({});
    const [staffOverviewOptions, setStaffOverviewOptions] = useState({});

    const [currencyTransactionChart, setCurrencyTransactionChart] = useState('');
    const [currencyTransactionData, setCurrencyTransactionData] = useState({});
    const [currencyTransactionOption, setCurrencyTransactionOption] = useState({});

    const [lastYearTransactionChart, setLastYearTransactionChart] = useState('');
    const [lastYearTransactionData, setLastYearTransactionData] = useState({});
    const [lastYearTransactionOption, setLastYearTransactionOption] = useState({});

    const [transactionOverviewChart, setTransactionOverviewChart] = useState('');
    const [transactionOverviewData, setTransactionOverviewData] = useState({});
    const [transactionOverviewOption, setTransactionOverviewOption] = useState({});

    const [topCustomerTransactionChart, setTopCustomerTransactionChart] = useState('');
    const [topCustomerTransactionData, setTopCustomerTransactionData] = useState({});
    const [topCustomerTransactionOption, setTopCustomerTransactionOption] = useState({});

    const selectOptions = [
        { label: 'Account Overview', value: 'ACCOUNT OVERVIEW', },
        { label: 'Currency Transaction', value: 'CURRENCY TRANSACTION' },
        { label: 'Staff Overview', value: 'STAFF OVERVIEW' },
        { label: 'Last Year Transaction', value: 'LAST YEAR TRANSACTION' },
        { label: 'Transaction Overview', value: 'TRANSACTION OVERVIEW' },
        { label: 'Top Customer Transaction', value: 'TOP CUSTOMER OVERVIEW' },
    ];
    const [selectedCategory, setSelectedCategory] = useState(selectOptions[1]);

    const selectChartType = [
        { label: 'Bar Chart', value: 'bar' },
        { label: 'Line Chart', value: 'line' },
        { label: 'Pie Chart', value: 'pie' },
        { label: 'PolarArea Chart', value: 'polarArea' },
        { label: 'Radar Chart', value: 'radar' },
    ];

    const AccountProfile = () => {
        if (userData?.username && userData?.firstName) {
            setPercentage(85);
        } else {
            setPercentage(60);
        }
    }

    const [data, setData] = useState(null);
    const boxMenu = [
        { name: "Blocked", code: "Male", value: data?.blocked, color: '#f2ebfd', icon: <ImBlocked className='box-i' style={{ color: '#742bde', fontSize: '20px' }} /> },
        { name: "Inprogress", code: "Male", value: data?.ongoing, color: '#effaff', icon: <GiProgression className='box-i2' style={{ color: '#43b7ff', fontSize: '24px' }} /> },
        { name: "Cleared", code: "Male", value: data?.cleared, color: '#fff4ee', icon: <TiTick className='box-i3' style={{ color: '#ff8b4f', fontSize: '35px' }} /> },
        { name: "Pending", code: "Male", value: data?.pending, color: '#ffeef2', icon: <MdPendingActions className='box-i4' style={{ color: '#fb5282', fontSize: '35px' }} /> }
    ];

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_HOST}/get/todayCount`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                setData(response.data);
            })
            .catch((error) => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    console.log(errorMessage);
                }
            });
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        role();
        AccountOverview();
        AccountProfile();
        StaffOverview();
        CurrencyTransaction();
        LastYearTransactionview();
        TransactionOverview();
        TopCustomerTranaction();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handledSelectOptionsName = (value) => {
        setSelectOptionsName(value);
        setSelectedCategory(value);

        if (value === 'ACCOUNT OVERVIEW') {
            setSelectOptionsData(accountOverviewchartData);
            setSelectOptionsTypeOptions(accountOverviewchartOptions);
        } else if (value === 'CURRENCY TRANSACTION') {
            setSelectOptionsData(currencyTransactionData);
        } else if (value === 'STAFF OVERVIEW') {
            setSelectOptionsData(staffOverviewData);
        } else if (value === 'LAST YEAR TRANSACTION') {
            setSelectOptionsData(lastYearTransactionData);
        } else if (value === 'TRANSACTION OVERVIEW') {
            setSelectOptionsData(transactionOverviewData);
        } else if (value === 'TOP CUSTOMER OVERVIEW') {
            setSelectOptionsData(topCustomerTransactionData);
        }

    };

    const handledSelectOptionsType = (value) => {
        setSelectOptionsType(value);
    };

    const mapApiChartTypeToChartJsType = (apiChartType) => {
        switch (apiChartType) {
            case 'ChartType:bar':
                return 'bar';
            case 'ChartType:line':
                return 'line';
            case 'ChartType:radar':
                return 'radar';
            case 'ChartType:polarArea':
                return 'polarArea';
            case 'ChartType:pie':
                return 'pie';
            default:
                return 'bar';
        }
    };

    const role = () => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + `/remainingTime`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setpremiumPlan(response.data.premiumPlan);
            })
            .catch(error => {
                // console.log(error);
            });
    }
    const AccountOverview = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/branches/account', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                const chartType = Object?.keys(response.data)[0];
                const mappedChartType = mapApiChartTypeToChartJsType(chartType);
                setAccountOverviewChart(mappedChartType);

                const currencyData = response.data[chartType];
                const accountOverData = Object?.keys(currencyData);

                // const accountOverData = Object.keys(response?.data);

                const blockedData = accountOverData?.map(label => currencyData[label]?.blocked);
                const clearedData = accountOverData?.map(label => currencyData[label]?.cleared);

                const data = {
                    labels: accountOverData,
                    datasets: [
                        {
                            label: 'Blocked',
                            backgroundColor: '#02D89E ',
                            // hoverBackgroundColor: ['#00d89e'],
                            data: blockedData
                        },
                        {
                            label: 'Cleared',
                            backgroundColor: '#9159e3',
                            // hoverBackgroundColor: ['#e5e5e5', '#e5e5e5',],
                            data: clearedData
                        }
                    ]
                };

                const options = {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            // display: false,
                            position: "bottom",
                            labels: {
                                usePointStyle: true,
                            }
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        },
                        y: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        }
                    }
                };


                setAccountOverviewChartData(data);
                setAccountOverviewChartOptions(options);
                if (chartRef.current) {
                    chartRef.current.destroy();
                }

                const ctx = document.getElementById('accountOverviewChart');
                const newChartInstance = new Chart(ctx, {
                    type: chartType,
                    data: data,
                    // options: options
                });

                chartRef.current = newChartInstance;
            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            });
    }

    const CurrencyTransaction = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/currency-transaction-amount', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                const chartType = Object?.keys(response.data)[0];
                const mappedChartType = mapApiChartTypeToChartJsType(chartType);
                setCurrencyTransactionChart(mappedChartType);

                const currencyData = response.data[chartType];
                const currencyTransactionview = Object?.keys(currencyData);
                const thisMonthTransactionAmounts = currencyTransactionview?.map(currency => currencyData[currency]?.thisMonthTransactionAmount);
                const lastMonthTransactionAmounts = currencyTransactionview?.map(currency => currencyData[currency]?.lastMonthTransactionAmount);

                const data = {
                    labels: currencyTransactionview,
                    datasets: [
                        {
                            label: 'Last Month',
                            backgroundColor: '#ff8b4f',
                            data: thisMonthTransactionAmounts
                        },
                        {
                            label: 'This Month',
                            backgroundColor: 'rgb(67, 183, 255)',
                            data: lastMonthTransactionAmounts
                        }
                    ]
                };

                const options = {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: "bottom",
                            labels: {
                                usePointStyle: true,
                            }
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        },
                        y: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        }
                    }
                };

                setCurrencyTransactionData(data);
                setCurrencyTransactionOption(options);

                if (chartRef.current) {
                    chartRef.current.destroy();
                }

                const ctx = document.getElementById('currencyTransactionChart');
                const newChartInstance = new Chart(ctx, {
                    type: currencyTransactionChart,
                    data: data,
                });

                chartRef.current = newChartInstance;
            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            });
    }

    const StaffOverview = () => {
        axios
            .get(process.env.REACT_APP_BACKEND_HOST + '/history/get/max', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            })
            .then(response => {

                const chartType = Object?.keys(response?.data)[0];
                const mappedChartType = mapApiChartTypeToChartJsType(chartType);
                staffOverviewChart(mappedChartType);

                const stafData = response?.data[chartType];
                const staffOverview = Object?.keys(stafData);
                const modifiedStaffOverview = staffOverview.map(email => email.substring(0, 4));
                const data = {
                    labels: modifiedStaffOverview,
                    datasets: [
                        {
                            label: 'Cleared',
                            backgroundColor: '#e5e5e5',
                            // hoverBackgroundColor: ['#124A9969', '#124A99',],
                            data: staffOverview?.map(key => stafData[key]?.Cleared),
                        }
                    ],
                };

                const options = {
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    // context.datasetIndex is the index of the dataset, and context.dataIndex is the index of the hovered item
                                    const email = staffOverview[context.dataIndex]; // Get the full email address
                                    return email; // Return the full email address as the tooltip label
                                }
                            }
                        },
                        legend: {
                            // display: false,
                            position: "bottom",
                            labels: {
                                usePointStyle: true,
                            }
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        },
                        y: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        }
                    }
                };

                setStaffOverviewData(data);
                setStaffOverviewOptions(options);

                if (chartRef.current) {
                    chartRef.current.destroy();
                }

                if (Object?.keys(stafData).length > 0) {
                    const ctx = document?.getElementById('staffOverviewChart');
                    const newChartInstance = new Chart(ctx, {
                        type: mappedChartType,
                        data: data,
                        // options: options
                    });

                    chartRef.current = newChartInstance;
                }

            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            });
    }

    const LastYearTransactionview = () => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/branch-transaction-counts', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {

                const chartType = Object?.keys(response.data)[0];
                const mappedChartType = mapApiChartTypeToChartJsType(chartType);
                setLastYearTransactionChart(mappedChartType);

                const currencyData = response.data[chartType];
                const LastYearTransactionView = Object?.keys(currencyData);

                // const  = Object.keys(response?.data);
                const incomingCounts = LastYearTransactionView?.map(month => currencyData[month]?.incomingTransactionCount);
                const outgoingCounts = LastYearTransactionView?.map(month => currencyData[month]?.outgoingTransactionCount);

                const data = {
                    labels: LastYearTransactionView,
                    datasets: [
                        {
                            label: 'Incoming Transactions',
                            backgroundColor: 'rgb(251, 82, 130)',
                            // hoverBackgroundColor: ['#00d89e'],
                            data: incomingCounts
                        },
                        {
                            label: 'Outgoing Transactions',
                            backgroundColor: '#efac20',
                            // hoverBackgroundColor: ['#124A9969', '#e5e5e5',],
                            data: outgoingCounts
                        }
                    ]
                };

                const options = {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            // display: false,
                            position: "bottom",
                            labels: {
                                usePointStyle: true,
                            }
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        },
                        y: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        }
                    }
                };

                setLastYearTransactionData(data);
                setLastYearTransactionOption(options);

                if (chartRef.current) {
                    chartRef.current.destroy();
                }

                const ctx = document.getElementById('lastYearTransactionChart');
                const newChartInstance = new Chart(ctx, {
                    type: mappedChartType,
                    data: data,
                    // options: options
                });

                chartRef.current = newChartInstance;

            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            });
    }

    const TransactionOverview = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/branch-counts', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {

                const chartType = Object?.keys(response?.data)[0];
                const mappedChartType = mapApiChartTypeToChartJsType(chartType);
                setTransactionOverviewChart(mappedChartType);

                const currencyData = response.data[chartType];
                const transactionOverview = Object?.keys(currencyData);

                // const transactionOverview = Object.keys(response?.data);
                const blockedData = transactionOverview?.map(label => currencyData[label]?.BlockedCount);
                const clearedData = transactionOverview?.map(label => currencyData[label]?.ClearedCount);
                const pendingData = transactionOverview?.map(label => currencyData[label]?.PendingCount);

                const data = {
                    labels: transactionOverview,
                    datasets: [
                        {
                            label: 'Blocked',
                            data: blockedData,
                            backgroundColor: '#fdb458',
                            hoverBackgroundColor: ['#00d89e'],
                        },
                        {
                            label: 'Cleared',
                            data: clearedData,
                            // hoverBackgroundColor: ['#124A9969', '#e5e5e5',],
                        },
                        {
                            label: 'Pending',
                            data: pendingData,
                            backgroundColor: '#5394f0',
                            // hoverBackgroundColor: ['#00d89e'],
                        }
                    ]
                };

                const options = {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            // display: false,
                            position: "bottom",
                            labels: {
                                usePointStyle: true,
                            }
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        },
                        y: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        }
                    }
                };

                setTransactionOverviewData(data);
                setTransactionOverviewOption(options);

                if (chartRef.current) {
                    chartRef.current.destroy();
                }

                const ctx = document.getElementById('transactionOverviewChart');
                const newChartInstance = new Chart(ctx, {
                    type: chartType,
                    data: data,
                    // options: options
                });

                chartRef.current = newChartInstance;

            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            });

    }

    const TopCustomerTranaction = () => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/transactions/top_customers', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {

                const chartType = Object?.keys(response.data)[0];
                const mappedChartType = mapApiChartTypeToChartJsType(chartType);
                setTopCustomerTransactionChart(mappedChartType);

                const customerData = response.data[chartType];
                const topCustomerTransaction = Object?.keys(customerData);

                // const topCustomerTranaction = Object.keys(response?.data);
                const incomingCounts = topCustomerTransaction?.map(customer => customerData[customer]?.totalAmount);
                const outgoingCounts = topCustomerTransaction?.map(customer => customerData[customer]?.transactionCount);

                const data = {
                    labels: topCustomerTransaction,
                    datasets: [
                        {
                            label: 'Total Amount',
                            backgroundColor: '#9159e3',
                            // hoverBackgroundColor: ['#00d89e'],
                            data: incomingCounts
                        },
                        {
                            label: 'Transaction Count',
                            backgroundColor: '#02d89e',
                            // hoverBackgroundColor: ['#02d89e', '#e5e5e5',],
                            data: outgoingCounts
                        }
                    ]
                };

                const options = {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            // display: false,
                            position: "bottom",
                            labels: {
                                usePointStyle: true,
                            }
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        },
                        y: {
                            grid: {
                                display: false,
                                color: '#f0f0f1',
                                drawBorder: false,
                            },
                            display: true,
                        }
                    }
                };

                setTopCustomerTransactionData(data);
                setTopCustomerTransactionOption(options);

                if (chartRef.current) {
                    chartRef.current.destroy();
                }

                const ctx = document.getElementById('topCustomerTransactionChart');
                const newChartInstance = new Chart(ctx, {
                    type: chartType,
                    data: data,
                    // options: options
                });

                chartRef.current = newChartInstance;

            })
            .catch(error => {
                if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            });

    }

    const changeChart = () => {

        if (selectOptionsName === 'ACCOUNT OVERVIEW') {
            setAccountOverviewChart(selectOptionsType);
        } else if (selectOptionsName === 'CURRENCY TRANSACTION') {
            setCurrencyTransactionChart(selectOptionsType);
        } else if (selectOptionsName === 'STAFF OVERVIEW') {
            setStaffOverviewChart(selectOptionsType);
        } else if (selectOptionsName === 'LAST YEAR TRANSACTION') {
            setLastYearTransactionChart(selectOptionsType);
        } else if (selectOptionsName === 'TRANSACTION OVERVIEW') {
            setTransactionOverviewChart(selectOptionsType);
        } else if (selectOptionsName === 'TOP CUSTOMER OVERVIEW') {
            setTopCustomerTransactionChart(selectOptionsType);
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        axios.get(process.env.REACT_APP_BACKEND_HOST + `/api/dashboard/update-chart-type?heading=${selectOptionsName}&chartType=${selectOptionsType}`,

            { headers: headers })
            .then(response => {
                // console.log('Chart type updated Successfully');
            })
            .catch(error => {
                // console.error('Error updating chart type:', error);
            });

        setVisible(false);
    }

    const home = { icon: 'pi pi-home' }
    const items = [
        { label: 'Dashboard' },
    ];

    return (
        <>
            <div className="mainSec" style={{ background: '#f5f6fa', gap: '10px' }} >
                <Toast ref={toast} style={{ marginTop: '70px' }} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <BreadCrumb model={items} className='breadCrumb dash_BreadCrumb' home={window?.innerWidth <= 800 ? home : ''} style={{ display: window?.innerWidth <= 800 ? 'flex' : 'none' }} />

                        <div style={{ position: 'relative', top: window?.innerWidth <= 800 ? '50px' : '' }}>
                            <div className="dashboard_Main weB">
                                {boxMenu?.map(option => (
                                    <>
                                        <div className='box_s'>
                                            <div className='img_Sec' style={{ background: option?.color }}>
                                                {option?.icon}
                                            </div>
                                            <div className='value'>
                                                <span style={{ fontSize: '20px', fontWeight: '900' }}>{(option?.value) || 0}</span>
                                                <div style={{ color: 'rgb(185 187 197)', fontSize: '16px', fontWeight: '400' }}>{option?.name}</div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>

                            <div className="slide-container moB">
                                <Slide>
                                    {boxMenu?.map((option, index) => (
                                        <div key={index}>
                                            <div className='box_s'>
                                                <div className='img_Sec' style={{ background: option?.color }}>
                                                    {option?.icon}
                                                </div>
                                                <div className='value'>
                                                    <span style={{ fontSize: '20px', fontWeight: '900' }}>{option?.value || 0}</span>
                                                    <div style={{ color: 'rgb(185 187 197)', fontSize: '16px', fontWeight: '400' }}>{option?.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slide>
                            </div>

                            {['ProAdmin', 'Super admin'].includes(premiumPlan) && (
                                <div className="flex" style={{ gap: '20px', justifyContent: 'end' }}>
                                    <Button className="" style={{ background: '#124A99', border: '#124A99' }} onClick={() => setVisible(true) + setSelectOptionsType('')}>Custom </Button>
                                </div>
                            )}

                            <div className="flex" style={{ gap: '20px', justifyContent: 'space-between', flexWrap: window?.innerWidth <= 950 ? 'wrap' : '', padding: '20px 0' }}>
                                <div style={{ display: 'flex', width: (premiumPlan === 'FreeTrial' && '100%' || window?.innerWidth <= 600 ? '100%' : '' || window?.innerWidth <= 950 ? '100%' : '' || (window?.innerWidth <= 1486 && plan !== 'FreeTrial') ? '100%' : '' || window?.innerWidth <= 1235 ? '74%' : '74%'), height: '350px' }}>
                                    <div className="input chart_Al" style={{ boxShadow: 'unset', borderRadius: '8px' }}>
                                        <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px', justifyContent: 'space-between' }}>
                                            <span>Account Overview</span>
                                            {/* <span style={{ display: window?.innerWidth >= 1487 ? 'flex' : 'none', position: 'relative', right: '30px' }}>User Profile</span> */}
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <Chart type={accountOverviewChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={accountOverviewchartData} options={accountOverviewchartOptions} />

                                            {/* {window?.innerWidth >= 1487 &&
                                            <div className='cic'>
                                                <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                                <div className='pre'><div>Completed</div><span className='dot'></span></div>
                                            </div>
                                        } */}
                                        </div>
                                    </div>
                                </div>

                                {/* {window?.innerWidth <= 950 &&
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className="input chart_Al pre" style={{ width: '100%' }}>
                                        <div className='heading' style={{ width: '-webkit-fill-available', height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px' }}>User Profile</div>
                                        <div className='cic'>
                                            <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                            <div className='pre'><div>Completed</div><span className='dot'></span></div>
                                        </div>
                                    </div>
                                </div>
                            } */}

                                <div style={{ display: window?.innerWidth >= 1487 ? 'flex' : 'none', width: '25%' }}>
                                    <div className="input chart_Al">
                                        <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px' }}>Staff Overview</div>
                                        <Chart id="staffOverviewChart" type={staffOverviewChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={staffOverviewData} options={staffOverviewOptions} />
                                    </div>
                                </div>

                                {/* {!premiumPlan === 'FreeTrial' &&
                                <div style={{ display: !premiumPlan === 'FreeTrial' ? 'flex' : 'none' || window?.innerWidth <= 1486 ? 'flex' : 'none' || window?.innerWidth <= 1486 ? 'none' : 'none', width: '49%' }}>
                                    <div className="input chart_Al  _px">

                                        <div className='heading' style={{ width: '-webkit-fill-available', height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px' }}>User Profile</div>
                                        <div className='cic'>
                                            <CircularProgressbar value={percentage} text={`${percentage}%`} />
                                            <div className='pre'><div>Completed</div><span className='dot'></span></div>
                                        </div>
                                    </div>
                                </div>
                            } */}

                            </div>
                            {['ProAdmin', 'Super admin', 'BasicAdmin'].includes(premiumPlan) && (
                                <div className="flex f-D_C s-S" style={{ gap: '20px', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', width: (window?.innerWidth <= 600 ? '100%' : '100%'), height: '350px' }}>
                                        <div className="input chart_Al">
                                            <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px' }}>Currency Transaction</div>
                                            <Chart type={currencyTransactionChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={currencyTransactionData} options={currencyTransactionOption} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='chart_hide-visiable_one'>
                                <div className="flex flex_Warp_ s-S" style={{ gap: '20px', justifyContent: 'space-between', minWidth: '50%', padding: '20px 0' }}>
                                    {['ProAdmin', 'Super admin', 'BasicAdmin'].includes(premiumPlan) && (
                                        <div className='f-D_C s-S' style={{ width: '100%', display: 'flex', gap: '20px' }}>
                                            <div style={{ display: 'flex', width: window?.innerWidth <= 600 ? '100%' : '49.5%', height: '350px' }}>
                                                <div className="input chart_Al">
                                                    <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: window?.innerWidth <= 600 ? '0 20px' : '0 20px' }}>Last Year Transaction</div>
                                                    <Chart type={lastYearTransactionChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={lastYearTransactionData} options={lastYearTransactionOption} />
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', width: window?.innerWidth <= 600 ? '100%' : '49.5%', height: '350px' }}>
                                                <div className="input chart_Al">
                                                    <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: window?.innerWidth <= 600 ? '0 20px' : '0 20px' }}>Transaction Overview</div>
                                                    <Chart type={transactionOverviewChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={transactionOverviewData} options={transactionOverviewOption} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='fu' style={{ display: 'flex', width: (window?.innerWidth <= 600 ? '100%' : '100%'), height: '350px' }}>
                                        <div className="input chart_Al">
                                            <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px' }}>Top Customer Transaction</div>
                                            <Chart type={topCustomerTransactionChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={topCustomerTransactionData} options={topCustomerTransactionOption} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='chart_hide-visiable'>
                                <div className="flex flex_Warp_ s-S" style={{ gap: '20px', justifyContent: 'space-between', minWidth: '50%', padding: '20px 0' }}>
                                    {['ProAdmin', 'BasicAdmin'].includes(premiumPlan) && (
                                        <div className='f-D_C s-S' style={{ width: '100%', display: 'flex', gap: '20px' }}>
                                            <div style={{ display: 'flex', width: window?.innerWidth <= 600 ? '100%' : '49%', height: '350px' }}>
                                                <div className="input chart_Al">
                                                    <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: window?.innerWidth <= 600 ? '0 20px' : '0 20px' }}>Last Year Transaction</div>
                                                    <Chart type={lastYearTransactionChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={lastYearTransactionData} options={lastYearTransactionOption} />
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', width: window?.innerWidth <= 600 ? '100%' : '49%', height: '350px' }}>
                                                <div className="input chart_Al">
                                                    <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: window?.innerWidth <= 600 ? '0 20px' : '0 20px' }}>Transaction Overview</div>
                                                    <Chart type={transactionOverviewChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={transactionOverviewData} options={transactionOverviewOption} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='f-D_C s-S' style={{ width: '100%', display: 'flex', gap: '20px' }}>
                                        <div style={{ display: 'flex', width: window?.innerWidth <= 600 ? '100%' : '49%', height: '350px' }}>
                                            <div className="input chart_Al">
                                                <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px' }}>Top Customer Transaction</div>
                                                <Chart type={topCustomerTransactionChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={topCustomerTransactionData} options={topCustomerTransactionOption} />
                                            </div>
                                        </div>
                                        {/* {window?.innerWidth <= 950 && */}
                                        {/* <div className='fu' style={{ display: 'flex', width: (window?.innerWidth <= 600 ? '100%' : '' || window?.innerWidth <= 1600 ? '49%' : '480px'), height: '350px' }}> */}
                                        <div style={{ display: 'flex', width: window?.innerWidth <= 600 ? '100%' : '49%', height: '350px' }}>
                                            <div className="input chart_Al">
                                                <div className='heading' style={{ height: '50px', alignItems: 'center', color: '#000', borderBottom: '1px solid rgb(185, 187, 197)', padding: '0 20px' }}>Staff Overview</div>
                                                <Chart type={staffOverviewChart} style={{ width: '100%', height: '260px', padding: '0 20px' }} data={staffOverviewData} options={staffOverviewOptions} />
                                            </div>
                                        </div>
                                        {/* } */}
                                    </div>
                                </div>
                            </div>


                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>

                        <Dialog header="Change the Chart option" visible={visible} className="sing_up_main_Dialog" onHide={() => setVisible(false)}>
                            <div style={{ height: 'auto', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                                <div className="feild" style={{ padding: 'unset' }}>

                                    <div className="feild c feild-Sec" style={{ padding: 'unset' }}>
                                        {selectOptions.map((category) => {
                                            return (
                                                <div key={category?.value} className="flex align-items-center" style={{ width: '100%', margin: 'unset', flexDirection: 'row' }}>
                                                    <RadioButton className="radioBtn" inputId={category?.key} name="category" value={category} onChange={(e) => handledSelectOptionsName(e.value.value)} checked={selectedCategory === category?.value} />
                                                    <label htmlFor={category?.value}>{category?.label}</label>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className="input">
                                        <div className="label">Chart Type<span>*</span></div>
                                        <Dropdown value={selectOptionsType} onChange={e => handledSelectOptionsType(e.value)} options={selectChartType} className="w-full unique-drops dropdown" optionLabel="label" required />
                                    </div>

                                    {selectOptionsType &&
                                        <div className="input">
                                            <Chart type={selectOptionsType} style={{ width: '100%', height: '260px', padding: '20px', border: '1px solid #ced4da', borderRadius: '2px' }} data={selectOptionsData} options={selectOptionsTypeOptions} />
                                        </div>
                                    }

                                    <div className="flex_footer_btn otp_footer">
                                        <Button className="footer_Sub_Btn" onClick={e => changeChart(e.value)}>Submit</Button>
                                    </div>

                                </div>
                            </div>
                        </Dialog>

                    </>
                )}
            </div >
        </>
    )
}

export default DashboardMain;