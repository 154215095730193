import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

function UserActivity() {
  const location = useLocation();
  const [branchDetails, setBranchDetails] = useState(location.state?.branchDetails || []);
  const [expandedRows, setExpandedRows] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const history = useHistory()

  useEffect(() => {
    setBranchDetails(location.state?.branchDetails || []);
  }, [location.state]);

  const onRowToggle = (event) => {
    setExpandedRows(event.data);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const TypeTemplate = (rowData) => {
    const status = rowData.screeningStatus;
    let color = '';

    if (status === 'Pending') {
      color = '#f7af00';
    } else if (status === 'Cleared') {
      color = 'rgb(69 160 75)';
    }
    return (
      <div
        style={{
          color: color,
          fontWeight: '500',
        }}
      >
        {status}
      </div>
    );
  };

  const TypeTemplates = (rowData) => {
    const status = rowData.transferStatus;
    let color = '';

    if (status === 'Pending') {
      color = '#f7af00';
    } else if (status === 'completed') {
      color = 'rgb(69 160 75)';
    }
    return (
      <div
        style={{
          color: color,
          fontWeight: '500',
        }}
      >
        {status}
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="user-Activity-customer-list pt-2">
        <p className="mx-3"><strong>Customer Lists:</strong></p>
        <DataTable value={data.customerList} rowExpansionTemplate={rowExpansiontransactionTemplate}
          expandedRows={expandedRows}
          onRowToggle={onRowToggle}>
          <Column expander style={{ width: '3rem' }} />
          <Column field="clientID" header="Client ID" />
          <Column field="fullName" header="Full Name" />
          <Column field="createdTime" header="Created Time" body={formatDate} />
          <Column field="accountID" header="Account ID" />
          <Column field="accountType" header="Account Type" />
          <Column field="balance" header="Balance" />
          <Column field="screeningStatus" body={TypeTemplate} header="Screening Status" />
        </DataTable>
      </div>
    );
  };

  const rowExpansiontransactionTemplate = (data) => {
    return (
      <div className="user-Activity-customer-list pt-2">
        <p className="mx-3"><strong>Transaction Lists:</strong></p>
        <DataTable value={data.transactions}>
          <Column field="transactionId" header="Transaction ID" />
          <Column field="createdDate" header="Created Date" />
          <Column field="transferStatus" header="Transfer Status" body={TypeTemplates} />
          <Column field="amount" header="Amount" />
        </DataTable>
      </div>
    );
  };

  const formatDate = (rowData) => {
    const rawDate = rowData.createdTime;
    const formattedDate = new Date(rawDate).toLocaleDateString();
    return formattedDate;
  };

  const item = [
    {
      label: 'Branches', command: () =>
        history.push({
          pathname: '/admin/branches',
          state: {
            accessToken: location?.state?.accessToken,
            emailId: location?.state?.emailId,
            getUserData: location?.state?.getUserData,
          },
        }),
    },
    { label: 'Branch Report' },
  ];
  const home = {
    icon: 'pi pi-home', command: () =>
      history.push({
        pathname: '/admin/dashboard',
        state: {
          accessToken: location?.state?.accessToken,
          emailId: location?.state?.emailId,
          getUserData: location?.state?.getUserData,
        },
      })
  }
  const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

  const [visible, setVisible] = useState(false);
  let menu = null;

  const items = [
    {
      label: 'XLS',
      icon: () => <FaFileExcel />,
      command: () => {
        setVisible(false);
        exportToXLS();
      }
    },
    {
      label: 'XLSX',
      icon: () => <FaFileExcel />,
      command: () => {
        setVisible(false);
        exportToExcel();
      }
    },
    {
      label: 'CSV',
      icon: () => <FaFileCsv />,
      command: () => {
        setVisible(false);
        handleExportToCSV();
      }
    },
    {
      label: 'PDF',
      icon: () => <FaFilePdf />,
      command: () => {
        setVisible(false);
        handleExportToPDF();
      }
    }
  ];

  const toggleMenu = (event) => {
    setVisible(!visible);
    menu.toggle(event);
  };

  const cols = [
    { field: 'branchID', header: 'Branch Id' },
    { field: 'branchName', header: 'Name' },
    { field: 'totalCustomers', header: 'Total Customers' },
    { field: 'totalAccounts', header: 'Total Accounts' },
    { field: 'totalTransactions', header: 'Total Transactions' },
  ];

  const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

  const handleExportToPDF = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, branchDetails);
        doc.save('products.pdf');
      });
    });
  };

  const handleExportToCSV = () => {
    const csvContent = convertToCSV(branchDetails);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', 'data.csv');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (data) => {
    const csvRows = [];
    if (Array.isArray(data) && data.length > 0) {
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));
      for (const row of data) {
        const values = Object.values(row).join(',');
        csvRows.push(values);
      }
    }
    return csvRows.join('\n');
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(branchDetails);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  const exportToXLS = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(branchDetails);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xls');
  };
  return (
    <>
      <div className="mainSec" >
        <>
          <BreadCrumb model={item} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
          <div className="card card-Adhoc w-full scrole-height bg-white" style={{ marginBottom: '8%', position: 'relative', background: '#fff', border: '1px solid #e8e9eb' }} >
            <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
              <div className="tab-head-btns flex flex-wrap align-items-center justify-content-end" style={{ borderBottom: 'rgba(209, 209, 209, 1) 1px solid' }}>
                <div className='country-conatent flex justify-content-end  pt-2 pb-2 px-3 bg-gray-50'>
                  <div className="tab-head-btn flex justify-content-end flex-wrap w-full" >
                    <div className="flex align-items-center justify-content-center">
                      <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ border: 'none', backgroundColor: 'transparent' }} tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={toggleMenu} ><i className='pi pi-upload pl-1'></i></Button>
                      <Menu
                        className='unique-menu-exp'
                        model={items}
                        popup
                        ref={(el) => (menu = el)}
                        onHide={() => setVisible(false)}
                        appendTo={document.body}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='border-btm-datatble1'> */}
              <DataTable
                value={branchDetails}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 35, 50]}
                rowExpansionTemplate={rowExpansionTemplate}
                expandedRows={expandedRows}
                onRowToggle={onRowToggle}
              >
                {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                <Column expander style={{ width: '3rem' }} />
                <Column field="branchID" header="ID" />
                <Column field="branchName" header="Branch Name" />
                <Column field="totalCustomers" header="Total Customers" />
                <Column field="totalAccounts" header="Total Accounts" />
              </DataTable>
              {/* </div> */}
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default UserActivity;