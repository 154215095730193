import axios from 'axios';

class BranchesService {

    getProducts(accessToken) {
        // const companyid = id;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };
        let uri = "/getAllActiveBranches";

        return axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions).then(res => res.data);
    }

    createProduct(_product, accessToken) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let uri = "/branches";

        return axios.post(process.env.REACT_APP_BACKEND_HOST + uri, _product, {
            headers: headers
        }).then(res => res.data);
    }

    updateProduct(_product, accessToken) {
        // const email = emailId;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let uri = "/update/branches/" + _product.id;

        const payLoad = {
            phoneNumber: _product?.phoneNumber,
            status: _product?.status,
        };


        return axios.put(process.env.REACT_APP_BACKEND_HOST + uri, payLoad, {
            headers: headers
        }).then(res => res.data);
    }
}

export default BranchesService
