import React from 'react';
import { Switch, Route } from "react-router-dom";
import ForgetPass from "../views/auth/Loginpage/ForgetPassword/ForgetPass";
import ForgetChangePass from '../views/auth/Loginpage/ForgetPassword/ForgetChangePass';

function ForgetPas() {
    return (
        <>
            <Switch>

                <Route path="/forgetPaswordchange/idpage" exact component={ForgetPass} />
                <Route path="/forgetPaswordchange/changepass" exact component={ForgetChangePass} />

            </Switch>

        </>
    )
}

export default ForgetPas
