import React from 'react'
import './Footer.css'
import logoalthi from '../../Landing Page/media/logoalthi.png'
import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import fo_L from '../../Landing Page/media/fo_Logo.svg'

export default function Footer() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visible, setVisible] = useState(false);
  const [privacyPolicies, setprivacyPolicies] = useState(false);



  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <div className='fooTER_Sec'>
        <div className='max_W'>
          <div className='rrR'>
            <div className='p_BY'>
              <img src={fo_L} style={{ height: windowWidth <= 575 ? '80px' : '125px' }} alt='althi' />
            </div>

            <div className='foo_C'>
              <div className='foo_C_1'>Building on Trust.</div>
              <div className='foo_C_2'>Let’s keep in touch!</div>
              <div className='foo_C_3'>Telephone: +1 (959) 666 3350 | Email: support@acaia.us</div>
            </div>

            <div className='foo_SM'>
              <div>Follow Us</div>
              <div className='foo_SM_I'>
                <a href='https://www.facebook.com/AlthiSolutions' target='_blank' rel='noopener noreferrer'>
                  <FaFacebookSquare />
                </a>
                <a href='https://x.com/i/flow/login?redirect_after_login=%2FAlthiSolutions%2F' target='_blank' rel='noopener noreferrer'>
                  <FaSquareXTwitter />
                </a>
                <a href='https://www.instagram.com/althisolutions/' target='_blank' rel='noopener noreferrer'>
                  <FaSquareInstagram />
                </a>
                <a target='_blank' href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQE3S_D1QrDUGQAAAZEL-FIIk1NkUwpeITA2NM_tFBrSpcOGNjFxF7ffnwwUesn9_8LZn7oIOMy8Orizq43KHyW9FFn5yn7jjAcXoj2pZY_eohIPTzYy-QMo5tSS92Vm6uCMz4M=&original_referer=&sessionRedirect=https%3A%2F%2Fin.linkedin.com%2Fcompany%2Falthisolutions' rel='noopener noreferrer'>
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='brEE' style={{ borderTop: '2px solid #fff' }}>
          <div className='brEE max_W'>
            <div className='p_BY_C'>
              <div onClick={() => setprivacyPolicies(true)}>Privacy policies.</div>
              <div onClick={() => setVisible(true)}>Terms & conditions </div>
            </div>

            <div className='p_BY'>
              <div className='bY'>
                <div>Powered by</div>
                <a href='https://www.althisolutions.com/' target='_blank' rel='noopener noreferrer'>
                  <img src={logoalthi} alt='althi' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog header="Terms and Conditions" visible={visible} className="sing_up_main_Dialog_ foo_Dia" style={{ width: windowWidth <= 575 ? '90%' : '70%' }} onHide={() => setVisible(false)}>
        <div style={{ height: '50vh', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <h1 className="text-sm terms-head">Privacy Policy</h1>
          <h2 className="text-sm">Last Updated: November 14, 2023 </h2>
          <p className="text-sm">ACAIA IS COMMITTED TO PROTECTING YOUR PRIVACY. THIS POLICY OF PRIVACY EXPLAINS HOW WE COLLECT, USE, AND PROTECT YOUR INFORMATION. BY ACCEPTING THE TERMS OF USE OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU ARE ALSO ACCEPTING THIS PRIVACY POLICY, WHICH IS HEREBY INCORPORATED INTO AND MADE A PART OF THE TERMS OF USE BY THIS REFERENCE.  </p>
          <h2 className="text-sm terms-head">Information We Collect: </h2>
          <p className="text-sm">
            <li>To access and use the Service, you must register by entering your name, e-mail address, phone number, company name (if applicable), title at company (if applicable), and other Personal Information needed to use the Service (“User Data”). This User Data is necessary for us to provide you with access to and use of the Service to facilitate providing you with risk management analysis.  </li>
            <li>To facilitate processing payments, you need to provide a payment method and the following information: name, company name (if applicable), title at company (if applicable), billing address, credit card or banking information, and such other information as requested by the third party payment processor.  If your payment method information changes (e.g., card number, account number, routing number, expiration date, CVV), the third-party payment processor may require additional information. This User Data is necessary for the third-party payment processor to process payments for transactions through the Service. </li>
            <li>Additional information may be requested or collected to provide you with risk management analysis. This User Data is necessary for responding to additional information requests to assess risks through the Service. </li>
          </p>

          <h2 className="text-sm terms-head">Use of Information: </h2>
          <h2 className="text-sm terms-head">We use User Data to,</h2>
          <p className="text-sm">
            <li>Provide the Service. </li>
            <li>facilitate assessing risks and providing you with risk management analysis through the Service. </li>
            <li>process payments. </li>
            <li>interact with Users and provide customer service and technical support. </li>
            <li>provide Users with a tailored experience when using our Service. </li>
            <li>enhance the functionality of our Service.</li>
            <li>verify User identity and enable means to protect User Data and other information against unauthorized access, loss, destruction, or alteration. </li>
            <li>provide Users with critical updates, new releases, and training guides. </li>
            <li>identify usage trends and for data analytics. </li>
            <li>send notifications about User requests or reservations. </li>
            <li>a legitimate business interest, such as providing the Service and providing you with access to and use of the Service.  </li>
            <li>compliance with a legal obligation. </li>
          </p>

          <h2 className="text-sm terms-head">Storage and Security: </h2>
          <p className="text-sm">
            <p>Subject to this Privacy Policy, and unless you otherwise provide us with specific instructions, we will only store your User Data for as long as is necessary to fulfill our Terms of Use with you and provide you with the Service, or as otherwise required by law.  Acaia has implemented and routinely updates and maintains appropriate administrative, physical, and technical security measures to help protect your User Data from unauthorized access. Some of the safeguards we use to protect your User Data are firewalls and data encryption, information access controls, controlled access to our facilities, and 24/7 monitoring of our systems databases.   </p>
          </p>

          <h2 className="text-sm terms-head">Your Rights Regarding Your User Data:</h2>
          <p className="text-sm">
            <p>In some jurisdictions, you may have the right to request (i) what User Data we collect, store, use, share, or otherwise process, (ii) rectification of inaccurate User Data, (iii) erasure/deletion of your User Data, (iv) restriction on the sharing or other processing of certain User Data, and/or (v) that your User Data be transferred. According to the law of certain jurisdictions, you may also have the right to (i) object to our processing of your User Data, (ii) withdraw your consent to our processing of your User Data. However, due to the inherent necessity of User Data for the operation of the Service, you understand and agree that the erasure of, or withdrawal of your consent for us to process, your User Data may mean some features of our Service may not function properly or may not be available to you and that any such deficiency in the Service caused by such exercise of your privacy rights is not a result of discriminatory treatment for the exercise of your applicable privacy rights.  </p>
          </p>

          <h2 className="text-sm terms-head">Amendments to Privacy Policy: </h2>
          <p className="text-sm">
            <p>Acaia may update this Privacy Policy at any time and from time to time by posting such updates on this web page and either providing notice to the last e-mail address you provided to us or providing you with notice by other similar means. We reserve the right, at our discretion, to modify, add, or remove portions of this policy at any time. Please check this page periodically for changes. If an update changes how we use your Personal Information or applicable law otherwise requires your consent, we will also seek your consent before such update applies. Upon providing notice as noted above, updates which do not need your consent will be effective regardless of whether your consent is obtained. </p>
          </p>

          <h2 className="text-sm terms-head">Contact Us:  </h2>
          <p className="text-sm">
            <p>If you have any questions about this Privacy Policy or our data practices, please don't hesitate to contact us at support@acaia.us. </p>
          </p>

        </div>
      </Dialog>

      <Dialog header="Privacy Policies" visible={privacyPolicies} className="sing_up_main_Dialog_ foo_Dia" style={{ width: windowWidth <= 575 ? '90%' : '70%' }} onHide={() => setprivacyPolicies(false)}>
        <div style={{ height: '50vh', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <h1 className="text-sm terms-head">Terms of Use:</h1>
          <h2 className="text-sm">Last Updated: November 14, 2023</h2>
          <p className="text-sm">USER NOTICE: PLEASE READ THIS TERMS OF USE CAREFULLY BEFORE ACCESSING AND/OR USING THIS WEBSITE AND/OR THE ACAIA SERVICE (COLLECTIVELY, THE “SERVICE”) GOVERNED BY THIS TERMS OF USE. THIS TERMS OF USE IS A LEGALLY BINDING AGREEMENT BETWEEN ACAIA AND THE USER OF THE SERVICE. </p>
          <p className="text-sm">BY ACCESSING AND/OR USING THE SERVICE, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ AND UNDERSTAND THIS TERMS OF USE AND PRIVACY POLICY (WHICH IS HEREBY INCORPORATED INTO AND MADE A PART OF THIS TERMS OF USE BY THIS REFERENCE) AND THAT YOU ARE BOUND BY THIS TERMS OF USE.  IF YOU DO NOT AGREE TO THESE TERMS OF USE, THEN YOU ARE NOT AUTHORIZED TO ACCESS OR USE THE SERVICE, AND YOU SHALL IMMEDIATELY CEASE ACCESSING AND USING THE SERVICE. </p>

          <h2 className="text-sm terms-head">1. ACCESS AND USE OF THE SERVICE. </h2>
          <p className="text-sm">
            <li>The Service provides support for the integration activities and functions of corporate governance, risk management, internal control, and compliance. This integration helps facilitate improvements in business objectives through automation of the main corporate governance processes and is supported by best practices and international standards that help organizations comply with industry-standard frameworks. </li>
            <li>Subject to this Terms of Use and the Service Agreement by and between ACAIA and the contracted party, i.e., you or your employer (“Customer”), ACAIA hereby grants you, and you hereby accept, a non-exclusive, non-transferable, revocable, and limited right to electronically access and use the Service for the sole purpose of providing information to ACAIA through the ACAIA website (“ACAIA Website”), which (if purchased and applicable) includes the dedicated URL of your employer. </li>
            <li>You may electronically access and use the Service for the limited purpose set forth in this Terms of Service until the earlier of the termination of (i) the Service Agreement; and (ii) your authorized right to access and use the Service pursuant to this Terms of Use. Upon any such termination, you shall automatically and immediately cease accessing and using the Service. </li>
            <li>ACAIA, in its sole discretion and at any time, may suspend and/or terminate your right to access and use the Service for your (i) breaching this Terms of Use; (ii) violating any applicable law, rule, and/or regulation related, directly or indirectly, to the Service; or (iii) lack of use of the Service for prolonged periods of time. </li>
            <li>YOU ARE SOLELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY AND USE OF YOUR USERNAME AND PASSWORD FOR ACCESSING AND USING THE SERVICE. You shall immediately notify ACAIA of any actual or suspected unauthorized access to or use of your username, password, and/or the Service or any other actual or potential security breach involving the Service. </li>
            <li>ACAIA reserves the right, but not the obligation, to monitor access to and use of the Service and use technological safeguards to protect the Service and access to and use of the Service. </li>
          </p>

          <h2 className="text-sm terms-head">2. SUBSCRIPTION PRICING AND PAYMENT.</h2>
          <p className="text-sm">
            <p>The Service is offered by ACAIA via different plans and modalities. Descriptions of our various plans, models, and pricing are set forth on the PRICING PAGE of the ACAIA Website. Pricing on the Pricing Page does not include any taxes. If a tax applies to your subscription for the Service (“Subscription”), then such tax will be included on invoices provided to the Customer. Subscriptions may be paid by credit card or paid by any other means enabled by ACAIA for a Subscription plan or model. The total price, payment method, and other particular aspects of a Subscription will be outlined in the sales invoice issued by ACAIA. Each invoice is governed by this Term of Use and any other agreement by and between ACAIA and the Customer. The rates and Services are granted under an availability model and are not revocable. ACAIA will not provide any refunds or credits for early termination of a Subscription. After the initial payment is made, ACAIA will continue to invoice the Customer for the Subscription according to the period selected by the Customer, per the rates then in effect, until the termination of the Subscription. Unless and until the Customer cancels a Subscription (either through notice or virtual mechanism), the Subscription will be automatically renewed for periods equivalent to those initially contracted. ACAIA may, at ACAIA’s sole discretion, suspend or cancel a Subscription for late payments of more than fifteen (15) days. At any time and from time to time, ACAIA may modify the rates, plans, and modalities of the Service; provided, however, any such changes will not be applied retroactively to the current Service. </p>
          </p>

          <h2 className="text-sm terms-head">3. RESTRICTIONS: </h2>
          <p className="text-sm">
            <p>You shall not attempt to do or do any of the following, and shall not allow others to attempt to do or do any of the following: </p>
            <li>assign, sublicense, transfer, or otherwise provide another person with access to and/or use of the Service; </li>
            <li>allow another person to use your username and/or password to access and/or use the Service; </li>
            <li>impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity concerning accessing and/or using the Service; </li>
            <li>upload, post, e-mail, transmit, or otherwise make available through the Service any content, data, or other information (i) that is false, inaccurate, incomplete, untimely, misleading, unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, or hateful; (ii) for which you do not have a right to make available (publicly or privately); (iii) that infringes any intellectual property right or other proprietary right of any person or entity; or (iv) that contains malware, software viruses, or any other computer code, files, or programs designed to interrupt, destroy, interfere with, or otherwise limit the functionality of any computer software, hardware, network, telecommunications equipment, or to otherwise interact with the Service in a manner not permitted; </li>
            <li>download, copy, publish, modify, tamper with, adapt, translate, reverse engineer, disassemble, decompile, decode, or decrypt the Service; </li>
            <li>create derivative works from the Service; </li>
            <li>forge headers or otherwise manipulate identifiers related to the Service; </li>
            <li>use, remove, alter, obscure, translate, combine, supplement, or otherwise change any screen display, patent, copyright or trademark marking, disclaimer, version or release number, or serial number which is present in, displayed by, or provided in connection with, the Service; </li>
            <li>frame, link, or otherwise use a method to give the appearance that you are authorized to display the Service for others; </li>
            <li>probe, scan, or test the vulnerability of the Service; </li>
            <li>act as a Service bureau to third parties act as an outsource agent or outsource provider for third parties, or otherwise utilize the Service for the benefit of any third party; </li>
            <li>use any information from the Service for purposes of monitoring availability, performance, or functionality, or any other benchmarking or competitive purposes; </li>
            <li>enable high volume, automated, electronic processes through the Service; </li>
            <li>use any robot, spider, data miner, crawler, scraper, or other automated means to access or index the Service or content, data, or other information associated with the Service; </li>
            <li>interfere with or disrupt the Service or servers or networks connected to the Service, or violate any requirements, procedures, policies, or regulations of networks connected to the Service; </li>
            <li>interfere with the use of the Service by ACAIA or other users of the Service; </li>
            <li>bypass, breach, disable or defeat, or attempt to do any technological safeguard, restricted access, or other protection used by, or in connection with, the Service; </li>
            <li>stalk or otherwise harass any person or entity through the Service; </li>
            <li>facilitate gambling, gaming, lotteries, raffles, contests, sweepstakes, and/or any other activity featuring the award of a prize through the Service; </li>
            <li>collect, store, use, share, or otherwise process personal data, personal information, personally identifiable information, or any other information that is related to an identified or identifiable natural person (collectively, “Personal Information”) of other users of the Service, except as (i) allowed by applicable law, (ii) permitted under this Terms of Use, and (iii) to facilitate a transaction through the Service; </li>
            <li>disclose any information of or provided by ACAIA or another user of the Service, except as needed to use the Service by this Terms of Use; </li>
            <li>access or use the Service in a manner that violates this Terms of Use or any applicable law, rule, or regulation. </li>
          </p>

          <h2 className="text-sm terms-head">4. INTELLECTUAL PROPERTY RIGHTS: </h2>
          <p className="text-sm">
            <p>Other than the limited right to access and use the Service specifically outlined in this Terms of Use, no other rights are granted to you under this Terms of Use or otherwise. All other rights related to the Service are hereby reserved by ACAIA and shall remain owned solely and exclusively in their entirety by ACAIA. </p>
          </p>

          <h2 className="text-sm terms-head">5. Contact Us:</h2>
          <p className="text-sm">
            <p>If you have any questions about this Terms of Use or our data practices, please don't hesitate to contact us at support@acaia.us.  </p>
          </p>

        </div>
      </Dialog>
    </>
  )
}


