import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Admin from './layouts/Admin';
// import Sanction from './layouts/Sanction';
// import ProfileLayout from "./layouts/Profile.js";
// import TranSaction from './layouts/TransactionMonitoring';
import PrivateRoute from "./views/PrivateRoute";
import Login from "./views/auth/Loginpage/login.js";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom/cjs/react-router-dom';
import ForgetPas from './layouts/ForgetPas';
import Signup from './layouts/SignInForm/Signup.js';
import PaymentsPage from './layouts/PaymentPage/PaymentPage.js';
import AdditionalInfo from './layouts/Additional_Info/Additional_Info.js';
import BlogItem from './Landing Page/BlogItem/BlogItem.js';
import Contact from './Landing Page/Contact/Contact.js';
import Subscription from './Landing Page/Subscription/Subscription.js';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <PrivateRoute path='/admin' component={Admin} />
        {/* <PrivateRoute path='/profile' component={ProfileLayout} /> */}
        {/* <PrivateRoute path='/sanction' component={Sanction} /> */}
        {/* <PrivateRoute path='/transaction' component={TranSaction} /> */}
        <Route path='/forgetPaswordchange' component={ForgetPas} />
        <Route path='/login' component={Login} />
        <Route path='/signup' component={Signup} />
        <Route path='/plan' component={Subscription} />
        <Route path='/blog' component={BlogItem} />
        <Route path='/contactUs' component={Contact} />
        <Route path='/additionaldetails' component={AdditionalInfo} />
        <Route path="/payment" component={PaymentsPage} />
        <Route path='/' component={App} />
        <Redirect from='*' to='/' />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();