import React, { useState, useRef, useEffect } from 'react';
import "./Contact.css";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Triangle } from 'react-loader-spinner';
import axios from 'axios';
import { Toast } from "primereact/toast";

export default function Contact() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const toast = useRef(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        company: '',
        emailId: '',
        jobTitle: '',
        country: '',
        message: '',
    });

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        history.push({
            state: {
                path: ''
            },
        });

        if (!email) {
            setEmailError("");
        } else {
            if (!email || !/\S+@\S+\.\S+/.test(email)) {
                setEmailError("Error");
            } else {
                setEmailError("");
            }
        }
    }, [email]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {

        setLoading(true);
        event.preventDefault();
        const obj2 = { emailId: email };
        const payLoad = { ...formData, ...obj2 };

        axios.post(process.env.REACT_APP_BACKEND_HOST + '/submitForm', payLoad, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setFormData({
                    firstName: '',
                    lastName: '',
                    company: '',
                    emailId: '',
                    jobTitle: '',
                    country: '',
                    message: '',
                });
                setEmail('');
                setLoading(false);
                toast.current.show({ severity: 'success', summary: 'Completed Successfully', life: 2000 });
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Something went to error', life: 2000 });
            });
    };


    return (
        <>
            <Toast ref={toast} />
            <>
                <Header />
                <div className='maIn_Con'>

                    <div className='sadsad'>
                        <div className='ssaaaaaa_L'>
                            <div className='conT_T'></div>
                            <div className='conT_B'></div>
                        </div>
                        <div className='ssaaaaaa_R'>
                            <div className='conT_T'></div>
                            <div className='conT_B'></div>
                        </div>
                    </div>

                    <div className='maIn_Con_  max_W'>
                        {loading &&
                            <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                <Triangle
                                    height="80"
                                    width="80"
                                    color="#124A99"
                                    ariaLabel="triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        }
                        <br />
                        <div>
                            <p className="contact-heading" style={{ color: '#4E4E4E' }}>
                                Con<span className="underliners">tact Us</span>
                            </p>
                        </div>

                        <br />
                        <div className="bLOG_H_Su"> We’d love to hear from you. Please complete and submit the form below, and we <br style={{ display: windowWidth <= 800 ? 'none' : '' }} /> will respond as soon as possible,                    </div>
                        <br />

                        <div className="unique-form-inputs">
                            <div className="contact-main">
                                <div className="" style={{ width: '50%' }}>
                                    <p className="p-float-label">
                                        <InputText
                                            style={{ width: '100%' }}
                                            className="custom-background"
                                            name='firstName'
                                            value={formData?.firstName} onChange={handleChange}
                                        />
                                        <label htmlFor="firstname">First Name</label>
                                    </p>
                                </div>
                                <div className="" style={{ width: '50%' }}>
                                    <p className="p-float-label">
                                        <InputText
                                            style={{ width: '100%' }}
                                            className="custom-background"
                                            name='lastName'
                                            value={formData?.lastName} onChange={handleChange}
                                        />
                                        <label htmlFor="lastname">Last Name</label>
                                    </p>
                                </div>
                            </div>

                            <div className="" style={{ width: '100%' }}>
                                <p className="p-float-label co_Form">
                                    <InputText
                                        name='company'
                                        value={formData?.company} onChange={handleChange}
                                        className="custom-background w-full"
                                    />
                                    <label htmlFor="phone">Company</label>
                                </p>
                            </div>

                            <div className="" style={{ width: '100%' }}>
                                <p className="p-float-label co_Form">
                                    <InputText
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="custom-background w-full"
                                        style={{
                                            border: emailError ? "2px solid #cc0033" : "",
                                            boxShadow: emailError ? "0px 2px 8px 0px #fce4e4" : "",
                                            backgroundColor: emailError ? "#fce4e4" : "",
                                        }}
                                    />
                                    <label htmlFor="email">Business Email</label>
                                </p>
                            </div>

                            <div className="" style={{ width: '100%' }}>
                                <p className="p-float-label">
                                    <InputText
                                        name='jobTitle'
                                        value={formData?.jobTitle} onChange={handleChange}
                                        className="custom-background w-full"
                                    />
                                    <label htmlFor="phone">Job Title</label>
                                </p>
                            </div>

                            <div className="" style={{ width: '100%' }}>
                                <p className="p-float-label">
                                    <InputText
                                        name='country'
                                        value={formData?.country} onChange={handleChange}
                                        className="custom-background w-full"
                                    />
                                    <label htmlFor="phone">Country</label>
                                </p>
                            </div>

                            <div className="" style={{ width: '100%' }}>
                                <p className="p-float-label">
                                    <InputTextarea
                                        name='message'
                                        value={formData?.message} onChange={handleChange}
                                        rows={5}
                                        cols={30}
                                        className="custom-background w-full"
                                    />
                                    <label htmlFor="message">Enter your message...</label>
                                </p>
                            </div>
                            <div className="flex justify-content-center align-items-center mt-3 mb-3">
                                <Button className="contact-button"
                                    disabled={!formData?.firstName || !formData?.lastName || !formData?.company || !formData?.jobTitle || !formData?.country || !formData?.message || !email}
                                    onClick={handleSubmit}
                                    label='Submit'
                                />
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        </>
    );
}
