import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from "primereact/toast";
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from 'react-router-dom';
// import classNames from 'classnames';
// import { RiDownload2Line } from "react-icons/ri";
import { Card } from 'primereact/card';
import { useLocation } from 'react-router-dom';

function TransationPendingData(props) {

    const [transdata, setTransdata] = useState('');
    const [visible, setVisible] = useState(false);
    const [statusoption, setSelectedOption] = useState('');
    const history = useHistory();
    const toast = useRef(null);
    const [loading, setLoading] = useState(true);
    // const [submitted, setSubmitted] = useState(false);
    // const [notForData, setNotForData] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const transidfordata = location?.state?.transactionId;
    const accessToken = location?.state?.accessToken;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setLoading(false);
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/transactions/' + transidfordata , {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                setTransdata(response.data); // Extract the data array from the response
                setLoading(false)
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            });
    }, [transidfordata,accessToken]);

    const hideDialog = () => {
        setVisible(false);
    };

    const statusoptions = [
        { name: 'On-going', value: 'On-going' },
        { name: 'Cleared', value: 'Cleared' },
        { name: 'Blocked', value: 'Blocked' }

    ];

    const handleStatusChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
    };

    const showDialog = () => {
        setVisible(true);
    };

    // const email = location?.state?.emailId;

    const updatetrans = () => {
        setLoading(true);

        const data = {
            status: statusoption
        }

        const url = `${process.env.REACT_APP_BACKEND_HOST}/transactions/${transidfordata}`;
        axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                if (response.status === 200) {
                    hideDialog();
                    history.goBack();
                } else if (response.status === 403) {
                    history.push('/loginpage');
                }
            })
            .catch(error => {
                setLoading(false);
                setVisible(false);
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: '400 Bad Request', life: 5000 });
                }
            });
    };

    const formatDateString = (dateString) => {
        const parts = dateString.split('T');
        if (parts.length === 2) {
            const datePart = parts[0];
            const [month, day, year] = datePart.split('/');
            const formattedDate = `${month}/${day}/${year}`;
            return formattedDate;
        } else {
            return dateString; // If the format is not as expected, return the original string
        }
    };



    const items = [
        {
            label: 'Risk Check', command: () =>
                history.push({
                    pathname: '/admin/account/pending',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })
        },
        {
            label: 'Pending List', command: () =>
                history.push({
                    pathname: '/admin/account/pending',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })
        },
        { label: 'Transaction Details' },
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    return (
        <>

            <div className="mainSec" >
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                <Toast ref={toast} style={{ marginTop: '50px' }} />
                {loading ? (
                    <div className="spin-wrapper">
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <Card className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
                            {transdata && (
                                <div className="footer_sec_btn" style={{ padding: 'unset', height: '60px' }}>
                                    {props.roleName !== "StartupAdmin" &&
                                        <Button style={{ background: '#124A99' }} onClick={showDialog}>Update</Button>}
                                    <Button style={{ background: '#124A99' }} onClick={() =>
                                        history.push({
                                            pathname: '/admin/account/pending/mail',
                                            state: {
                                                emailId: location?.state?.emailId,
                                                accPemding: location?.state?.accPemding,
                                                benPending: location?.state?.benPending,
                                                traPending: location?.state?.traPending,
                                                transactionId: location?.state?.transactionId,
                                                accountId: location?.state?.accountId,
                                                accessToken: accessToken,
                                                getUserData: location?.state?.getUserData
                                            },
                                        })

                                    }>Mail</Button>
                                </div>
                            )}

                            {!transdata && (
                                <div className='head' style={{ width: 'fit-content', cursor: 'pointer' }} onClick={() => window.history.back()}>
                                    Don't have data's
                                </div>
                            )}

                            {transdata && (
                                <div className='h_ing'>Transaction Details</div>
                            )}

                            {transdata?.accountId && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Account Id <span>:</span></div>
                                    <div className='value'>{transdata?.accountId || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.amount && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Amount <span>:</span></div>
                                    <div className='value'>{transdata?.amount || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.beneficiaryId && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Beneficiary Id <span>:</span></div>
                                    <div className='value'>{transdata?.beneficiaryId || 'N/A'}</div>
                                </div>
                            )}


                            {transdata?.category && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Category <span>:</span></div>
                                    <div className='value' style={{
                                        color:
                                            transdata.category === 'Medium Risk'
                                                ? '#e97100'
                                                : transdata.category === 'Low Risk'
                                                    ? '#256029'
                                                    : transdata.category === 'High Risk'
                                                        ? '#c63737'
                                                        : 'black',
                                    }}>{transdata?.category || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.correspondentId && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Correspondent Id <span>:</span></div>
                                    <div className='value'>{transdata?.correspondentId || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.createdDate && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Created Date <span>:</span></div>
                                    <div className='value'>{transdata?.createdDate || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.createdTime && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Created Time <span>:</span></div>
                                    <div className='value'>{transdata?.createdTime || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.createdUser && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Created User <span>:</span></div>
                                    <div className='value' style={{ overflow: 'hidden' }}>{transdata?.createdUser || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.currency && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Currency <span>:</span></div>
                                    <div className='value'>{transdata?.currency || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.description1 && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Description-I <span>:</span></div>
                                    <div className='value'>{transdata?.description1 || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.description2 && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Description-II <span>:</span></div>
                                    <div className='value'>{transdata?.description2 || 'N/A'}</div>
                                </div>
                            )}

                            {/* asdasdasd */}

                            {transdata?.modeOfPayment && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Mode Of Payment <span>:</span></div>
                                    <div className='value'>{transdata?.modeOfPayment || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.marked && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Marked <span>:</span></div>
                                    <div className='value'>{transdata?.marked || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.modifiedDate && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Modified Date <span>:</span></div>
                                    <div className='value'>{formatDateString(transdata?.modifiedDate) || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.purpose && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Purpose <span>:</span></div>
                                    <div className='value'>{transdata?.purpose || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.status && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Currency <span>:</span></div>
                                    <div className='value' style={{
                                        color:
                                            transdata.status === 'Pending'
                                                ? '#f7af00'
                                                : transdata.status === 'Cleared'
                                                    ? 'rgb(69 160 75)'
                                                    : transdata.status === 'Blocked'
                                                        ? '#f42616'
                                                        : 'black',
                                    }}>{transdata?.status || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.transactionId && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Transaction Id <span>:</span></div>
                                    <div className='value'>{transdata?.transactionId || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.transferStatus && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Transfer Status <span>:</span></div>
                                    <div className='value'>{transdata?.transferStatus || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.type && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Type <span>:</span></div>
                                    <div className='value'>{transdata?.type || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.updateReason && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Update Reason <span>:</span></div>
                                    <div className='value'>{transdata?.updateReason || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.reason && (
                                <div className='info_D' style={{ height: (windowWidth <= 420 && transdata?.reason.length > 20 ? 'auto' : '50px'), justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Reason <span>:</span></div>
                                    <div className='value'>{transdata?.reason || 'N/A'}</div>
                                </div>
                            )}

                            {transdata?.modifiedBy && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '140px', display: 'flex', justifyContent: 'space-between' }}>Modified <span>:</span></div>
                                    <div className='value' style={{ overflow: 'hidden' }}>{transdata?.modifiedBy || 'N/A'}</div>
                                </div>
                            )}

                        </Card>
                    </>

                )}
            </div >

            <Dialog header="Update Transaction Pending" className="sing_up_main_Dialog" style={{ width: '30rem' }} visible={visible} onHide={hideDialog}>
                <div style={{ height: 'auto', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                    <div className="feild" style={{ padding: 'unset' }}>
                        <div className="flex" style={{ width: '100%' }}>
                            <div className="input">
                                <div className="label">Status</div>
                                <Dropdown value={statusoption} onChange={handleStatusChange} options={statusoptions} optionLabel="name" className='p-0 unique-drops w-full' style={{ width: '100%' }} />
                                {!statusoption && <small className="error-message">Status is required.</small>}
                            </div>
                        </div>

                        <div className="flex_footer_btn otp_footer">
                            <Button className='footer_Sub_Btn' onClick={updatetrans} disabled={props.roleName !== 'ProAdmin' && !statusoption } label="Update" type='submit' style={{ border: "none" }} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default TransationPendingData
