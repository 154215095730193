import React, { useState, useEffect } from "react";
import "./ChooseUs.css";
import { IoShieldCheckmark } from "react-icons/io5";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { Button } from 'primereact/button';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Customer from '../media/comprehensive/customer screening(black).png';
import Payment from '../media/comprehensive/payment screening(black).png';
import Enhanced from '../media/comprehensive/enhanced due diligence(black).png';
import Datas from '../media/comprehensive/data preparation(black).png';
import Risk from '../media/comprehensive/risk scoring(black).png';
import Document from '../media/comprehensive/ID document verification(black).png';
import Management from '../media/comprehensive/alert managment(black).png';
import CustomerW from '../media/comprehensive/customer screening(white).png';
import PaymentW from '../media/comprehensive/payment screening(white).png';
import EnhancedW from '../media/comprehensive/enhanced due diligence(white).png';
import DatasW from '../media/comprehensive/data preparation(white).png';
import RiskW from '../media/comprehensive/risk scoring(white).png';
import DocumentW from '../media/comprehensive/ID document verification(white).png';
import ManagementW from '../media/comprehensive/alert managment(white).png';

export default function ChooseUs({
    data,
    label,
    heading,
    subheading,
    slideNum,
    autoplay,
    autoplaySpeed
}) {

    const [count, setCount] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [show, setShow] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const blogItens = [
        {
            id: 1,
            iconBK: Customer,
            iconW: CustomerW,
            name: 'Customer Screening',
            value: 'Acaia assists organizations in screening customers against sanctions lists, PEP, and adverse media databases, enabling a risk-based approach to due diligence.',
        },
        {
            id: 2,
            iconBK: Payment,
            iconW: PaymentW,
            name: 'Payment Screening',
            value: 'Screen payments against the latest sanctions, PEP, and dual-use goods lists to receive instant alerts for risky transactions. Acaia ensures your payment operations are efficient and compliant with KYC and AML regulations. ',
        },
        {
            id: 3,
            iconBK: Enhanced,
            iconW: EnhancedW,
            name: 'Enhanced Due Diligence',
            value: 'Performs detailed and well-documented AML investigations, ensuring you always have access to the latest information.' + " Acaia's " + 'custom reports offer comprehensive insights into risky customers, allowing your team to make informed decisions quickly. ',
        },
        {
            id: 4,
            iconBK: Datas,
            iconW: DatasW,
            name: 'Data Preparation',
            value: 'Enhance your screening accuracy and efficiency by correcting data anomalies.' + " Acaia's" + ' advanced algorithms detect even minute discrepancies. Utilize detailed risk scoring and profiling features to assess and manage potential risks. ',
        },
        {
            id: 5,
            iconBK: Risk,
            iconW: RiskW,
            name: 'Risk Scoring',
            value: 'Evaluate a customer' + "'s" + ' potential risk based on products, activities, geography, and other relevant factors. Acaia enables your business to consistently measure AML risk by dynamically analyzing multiple elements to assign accurate risk ratings. ',
        },
        {
            id: 6,
            iconBK: Document,
            iconW: DocumentW,
            name: 'ID Document Verification',
            value: 'Efficiently and accurately verify your customers' + "'s" + 'identity documents. Our solution ensures rapid and precise validation of ID documents. FinScan ID Validation removes the burden of manual document verification, reliably identifying fraudulent IDs while offering seamless service to your customers. ',
        },
        {
            id: 8,
            iconBK: Management,
            iconW: ManagementW,
            name: 'Alert Management',
            value: 'Manage and investigate system-generated alerts to identify genuine risks. Acaia regularly reassesses customer profiles and transaction patterns to ensure effective risk management using machine learning algorithms. ',
        },
    ];

    useEffect(() => {
        if (count === blogItens.length) {
            setCount(0);
        } else {
            const interval = setInterval(() => {
                setCount(prevCount => (prevCount < 2 ? prevCount + 1 : 0));
            }, 4000);
            return () => clearInterval(interval);
        }

    }, [count]);

    const handleDotClick = () => {
        setCount(count);
    }

    return (
        <>
            <div className='chooseUs_Main'>
                <div className="max_W">
                    <p className="contact-heading" style={{ color: '#093EA4' }}>A comprehensive KYC and AML compliance suite</p >

                    <div className="">
                        <div className="bLOG"><div></div></div>
                    </div >
                    <br />
                    <br />
                    <p className="chooseUs_Sec_N" style={{ textAlign: 'center' }}>Unlike others, our software is not just about compliance; it's about making your life easier. With our intuitive interface and robust features, we outshine the competition, giving you peace of mind and saving you time</p>
                    <br />
                    <br />

                    <div className="cho_US_Sec_Main">
                        {blogItens?.map((item, index) => (
                            <div className="cho_US_Sec">
                                <div className="cho_US_Sec_B">
                                    <img src={item?.iconBK} className="comprehensive_B" />
                                    <img src={item?.iconW} className="comprehensive_W" />
                                </div>

                                <h2 className="h2_Cho">{item?.name}</h2>
                                <p className="p_Cho">{item?.value} </p>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    );
}