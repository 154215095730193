import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Toast } from "primereact/toast";
import './AccountProfiling.css';
import { useLocation } from 'react-router-dom';

const Historyv = () => {
    const [historyData, setHistoryData] = useState([]);
    const toast = useRef(null);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;
    const refreshToken = location?.state?.refreshToken;

    const displayWarningToast = (message) => {
        toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
    };
    const displayErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    };

    const accountIValue = location?.state?.rowID || sessionStorage?.rowID;

    useEffect(() => {
        const fetchData = async () => {

            if (accountIValue) {
                try {
                    const requestOptions = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    };

                    const uri = `/accountHistory?accountId=${accountIValue}`;
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_HOST}${uri}`,
                        requestOptions
                    );

                    const data = response.data;
                    if (response.data.length === 0) {
                        displayWarningToast('No History found.');
                    } else {
                        setHistoryData(data);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                        sessionStorage.clear();
                    }
                    else if (error?.response && error?.response?.data && error?.response?.data?.message) {
                        const errorMessage = error?.response?.data?.message;
                        displayErrorToast(errorMessage);
                    }
                }
            }
        };

        fetchData();

    }, []);

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="w-full flex text-justify Acount-profile-expander-txt" style={{ fontSize: '14px', padding: '20px 40px' }}>
                <div className='f-Dd'>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Transaction ID</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.transactionId ? rowData.transactionId : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Account ID</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.accountId ? rowData.accountId : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Transaction Amount</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.transactionAmount ? rowData.transactionAmount : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Status</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.status ? rowData.status : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-9rem font-semibold'>Beneficiary ID</div> <div className='w-1rem'>:</div> <div className='w-10rem'>{rowData.beneficiaryId ? rowData.beneficiaryId : '-'}</div></div>
                </div>
                <div className='f-Dd'>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Created User</div> <div className='w-3rem'>:</div> <div className='w-10rem'>{rowData.createdUser ? rowData.createdUser : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Update User</div> <div className='w-3rem'>:</div> <div className='w-15rem'>{rowData.updateUser ? rowData.updateUser : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Update Date</div> <div className='w-3rem'>:</div> <div className='w-15rem'>{rowData.updatedDate ? rowData.updatedDate : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Action</div> <div className='w-3rem'>:</div> <div className='w-21rem'>{rowData.action ? rowData.action : '-'}</div></div>
                    <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Created Date</div> <div className='w-3rem'>:</div> <div className='w-15rem'>{rowData.createdDate ? rowData.createdDate : '-'}</div></div>

                </div>
            </div>
        );
    };

    return (
        <div style={{ padding: '20px 0' }} >
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
            <DataTable value={historyData} className="his_tory" style={{ whiteSpace: 'nowrap' }} stripedRows paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                scrollable='true'>
                <Column field="id" header="Id" />
                <Column field="action" header="Action" />
                <Column field="createdUser" header="Created User" />
                <Column field="createdDate" header="Created Date" />
            </DataTable>
        </div>
    );
};

export default Historyv;
