import React, { useState, useRef, useEffect } from 'react';
import './Header.css';
import { Menu } from 'primereact/menu';
import { Avatar } from 'primereact/avatar';
import { Sidebar } from 'primereact/sidebar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { TieredMenu } from "primereact/tieredmenu";
import { InputText } from "primereact/inputtext";
import { useHistory, useLocation } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { Triangle } from 'react-loader-spinner';
import axios from "axios";
import moment from 'moment';

function Header(props) {
    const menu = useRef(null);
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const history = useHistory()
    const [clearAnimationVisible, setClearAnimationVisible] = useState(false);
    const [clearButtonVisible, setClearButtonVisible] = useState(true);
    const [notificationCount, setNotificationCount] = useState(0);
    const location = useLocation();
    const name = location?.state?.getUser?.firstName || location?.state?.getUserData?.firstName || 'You';
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const accessToken = location?.state?.accessToken;
    const refreshToken = location?.state?.refreshToken;
    const [loading, setLoading] = useState(false);
    const navigate = useHistory();

    useEffect(() => {
        const updateSessionStorageData = () => {
            const newNotificationCount = sessionStorage.getItem('notificationCount');
            if (newNotificationCount) {
                setNotificationCount(parseInt(newNotificationCount, 10));
            }

            const notificationData = sessionStorage.getItem('messages');
            if (notificationData) {
                const parsedNotifications = JSON.parse(notificationData);
                setNotifications(parsedNotifications);

                // Reset clear animation and clear button visibility
                setClearAnimationVisible(false);
                setClearButtonVisible(true);
            }
        };

        updateSessionStorageData();

        const intervalId = setInterval(updateSessionStorageData, 5000);
        console.clear();
        return () => clearInterval(intervalId);
    }, []);

    const allOptions = [
        'Adhoc Search',
        'Pending',
        'Account Registeration',
        'Account Search',
        'sanction update',
        'Dashboard',
        'Risk Clarification',
        'Alert Preference',
        // 'Account Profiling',
        'Transaction',
        'Beneficiary',
        'Alert Creteria',
        'Company Profile',
        'Branches',
        'Users',
        'Roles',
        'Designation',
        'Country',
        'User Profile',
        'General Information',
        'History'
    ];

    const handleInputChange = (e) => {
        const inputText = e.target.value;
        setSearchText(inputText);
        const filteredOptions = allOptions?.filter((option) =>
            option.toLowerCase()?.includes(inputText?.toLowerCase())
        );
        setDropdownOptions(filteredOptions);
    };

    const handleOptionSelect = (option) => {
        setSearchText(option);
        setDropdownOptions([]);
        setSearchText("")

        if (option === 'Adhoc Search') {
            history.push({
                pathname: '/admin/adhoc/search',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Pending') {
            history.push({
                pathname: '/admin/account/pending',
                state: { traPending: location?.state?.traPending, benPending: location?.state?.benPending, accPemding: location?.state?.accPemding, getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Account Registeration') {
            history.push({
                pathname: '/admin/account/registration',
                state: {
                    emailId: location?.state?.getUserData?.emailId,
                    designationData: location?.state?.designationData || [],
                    nationalitydata: location?.state?.nationalitydata || [],
                    branchdata: location?.state?.branchdata || [],
                    accessToken: location?.state?.accessToken || [],
                    getUserData: location?.state?.getUserData,
                    refreshToken: location?.state?.refreshToken
                },
            });
            setVisible(false)
        } else if (option === 'Account Search') {
            history.push({
                pathname: '/admin/account/search',
                state: { nationalitydata: location?.state?.nationalitydata, Role: location.state.Role, getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        }
        else if (option === 'sanction update') {
            history.push({
                pathname: '/admin/account/Updates',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Dashboard') {
            history.push({
                pathname: '/admin/dashboard',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Risk Clarification') {
            history.push({
                pathname: '/admin/account/classification',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Alert Preference') {
            history.push({
                pathname: '/admin/account/preference',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Account Profiling') {
            history.push({
                pathname: '/admin/account/profiling',
                state: { nationalitydata: location?.state?.nationalitydata, Role: location.state.Role, getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId }
            });
            setVisible(false)
        }
        else if (option === 'Transaction') {
            history.push({
                pathname: '/admin/transaction/monitoring',
                state: { nationalitydata: location?.state?.nationalitydata, Role: location.state.Role, getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId }
            });
            setVisible(false)
        }
        else if (option === 'Beneficiary') {
            history.push({
                pathname: '/admin/transaction/beneficiary',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Alert Creteria') {
            history.push({
                pathname: '/admin/transaction/threshold',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Company Profile') {
            history.push({
                pathname: '/admin/company',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Branches') {
            history.push({
                pathname: '/admin/branches',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Users') {
            history.push({
                pathname: '/admin/users',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Roles') {
            history.push({
                pathname: '/admin/roles',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Designation') {
            history.push({
                pathname: '/admin/designation',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        } else if (option === 'Country') {
            history.push({
                pathname: '/admin/country',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
            setVisible(false)
        }
    };


    const item = [
        {
            label: "My Profile",
            icon: "pi pi-fw pi-user",
        },
        {
            label: "Setting",
            icon: "pi pi-fw pi-cog",
        },
        {
            label: "History",
            icon: "pi pi-fw pi-info-circle",
        },

        {
            label: "Logout",
            icon: "pi pi-fw pi-power-off",

        },
    ];


    const handledropdown = (e) => {
        if (e.value === "My Profile") {
            history.push({
                pathname: '/admin/userprofile',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
        } else if (e.value === "Setting") {

        } else if (e.value === "History") {
            history.push({
                pathname: '/admin/history',
                state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
            });
        }
        else if (e.value === "Logout") {
            handleSignOut(e);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            const username = location?.state?.getUserData?.emailId;
            if (username) {
                fetch(process.env.REACT_APP_IDM_BACKEND_HOST + "/sign_out/" + accessToken + "/" + username, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
            }
        }, 57 * 60 * 1000);
    }, [accessToken, location?.state?.getUserData?.emailId]);


    const handleSignOut = (e) => {
        setLoading(true);
        if (e) {
            e.preventDefault();
        }
        sessionStorage.clear();
        const username = location?.state?.getUserData?.emailId;
        fetch(process.env.REACT_APP_IDM_BACKEND_HOST + "/sign_out/" + accessToken + "/" + username, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 403) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                } else if (response.status === 200) {
                    window.location.assign(process.env.REACT_APP_AUTH_URL);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log("Logout error:", error);
            });
    }

    let options = [
        { label: <div><i className="pi pi-fw pi-user"></i>&nbsp;&nbsp; My Profile</div>, value: 'My Profile' },
        { label: <div><i className="pi pi-fw pi-info-circle"></i>&nbsp;&nbsp; History</div>, value: 'History' },
        { label: <div><i className="pi pi-fw pi-power-off"></i>&nbsp;&nbsp; Logout</div>, value: 'Logout' },

    ];
    const [headerLabel, setHeaderLabel] = useState('');

    useEffect(() => {

        if (location.pathname === '/admin/adhoc/search' || location.pathname === '/admin/adhoc/advancedsearch' || location.pathname === '/admin/adhoc/Advancemainpage') {
            setHeaderLabel('Adhoc Search');
        } else if (location.pathname === '/admin/adhoc/Corporateadvancedsearch' || location.pathname === '/admin/adhoc/corporateadhocdatatable' || location.pathname === '/admin/adhoc/corporateadhocmoreinfo' || location.pathname === '/admin/adhoc/corporateadvancetable' || location.pathname === '/admin/adhoc/corporateadvancemoreinfo') {
            setHeaderLabel('Adhoc Search');
        } else if (location.pathname === '/admin/adhoc/individualdata' || location.pathname === '/admin/adhoc/individualdatamoreinfo' || location.pathname === '/admin/adhoc/Individualadvancedatatable' || location.pathname === '/admin/adhoc/IndividualadvancedaMoreinfo') {
            setHeaderLabel('Adhoc Search');
        } else if (location.pathname === '/admin/account/registration') {
            setHeaderLabel('Account Registration');
        } else if (location.pathname === '/admin/account/search' || location.pathname === '/admin/account/individualaccountprofilingmoreinfo' || location.pathname === '/admin/account/corporateaccountprofilingmoreinfo') {
            setHeaderLabel('Account Search');
        } else if (location.pathname === '/admin/account/pending' || location.pathname === '/admin/account/benificary' || location.pathname === '/admin/account/benificarymoreinfo' || location.pathname === '/admin/account/transationdata' || location.pathname === '/admin/account/individualpending' || location.pathname === '/admin/account/corporatependingdata' || location.pathname === '/admin/account/pending/mail') {
            setHeaderLabel('Pending Verification');
        } else if (location.pathname === '/admin/account/Updates') {
            setHeaderLabel('Sanction Updates');
        } else if (location.pathname === '/admin/account/classification') {
            setHeaderLabel('Risk Classification');
        } else if (location.pathname === '/admin/account/preference') {
            setHeaderLabel('Alert Preference');
        } else if (location.pathname === '/admin/account/profiling' || location.pathname === '/admin/account/profilings' || location.pathname === '/admin/accounts' || location.pathname === '/admin/transaction' || location.pathname === '/admin/historyv' || location.pathname === '/admin/account/profiling/corporateinformation') {
            setHeaderLabel('Account Profiling');
        } else if (location.pathname === '/admin/account/corporateprofiling' || location.pathname === '/admin/corporate/beneficiary' || location.pathname === '/admin/corporate/history' || location.pathname === '/admin/corporate/transaction') {
            setHeaderLabel('Corporate Profiling');
        } else if (location.pathname === '/admin/individual/accounts' || location.pathname === '/admin/individual/history' || location.pathname === '/admin/individual/transaction') {
            setHeaderLabel('Individual Profiling');
        } else if (location.pathname === '/admin/transaction/monitoring') {
            setHeaderLabel('Transaction');
        } else if (location.pathname === '/admin/transaction/beneficiary') {
            setHeaderLabel('Beneficiary');
        } else if (location.pathname === '/admin/transaction/threshold') {
            setHeaderLabel('Alert Criteria');
        } else if (location.pathname === '/admin/company') {
            setHeaderLabel('Company Profile');
        } else if (location.pathname === '/admin/branches') {
            setHeaderLabel('Branches');
        } else if (location.pathname === '/admin/users') {
            setHeaderLabel('Users');
        } else if (location.pathname === '/admin/roles') {
            setHeaderLabel('Roles');
        } else if (location.pathname === '/admin/designation') {
            setHeaderLabel('Designation');
        } else if (location.pathname === '/admin/userprofile') {
            setHeaderLabel('User Profile');
        } else if (location.pathname === '/admin/generalinformation') {
            setHeaderLabel('General Information');
        } else if (location.pathname === '/admin/country') {
            setHeaderLabel('Country');
        } else if (location.pathname === '/admin/history') {
            setHeaderLabel('History');
        } else if (location.pathname === '/admin/dashboard') {
            setHeaderLabel('Dashboard');
        }

    }, [location.pathname]);

    const firstName = location?.state?.getUser?.firstName || location?.state?.getUserData?.firstName;
    const lastName = location?.state?.getUser?.lastName || location?.state?.getUserData?.lastName;

    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '' || firstName ? firstName.charAt(1) : '';

    const initials = `${firstInitial}${lastInitial}`.toUpperCase() || 'Y';

    const handleClearAll = () => {
        sessionStorage.removeItem('messages');
        sessionStorage.removeItem('notificationCount');

        setNotifications([]);
        setNotificationCount(0);

        setClearAnimationVisible(true);
        setClearButtonVisible(false);

        setVisible1(false);
    };

    const notificationMappings = {
        Branch: ['New branch has been created: ', 'branch has been Updated: '],
        Transaction: ['New individual transaction created: ', 'transaction updated: ', 'New Corporate transaction created: '],
        Country: ['New country has been created: ', 'Country has been updated: '],
        CompanyProfile: ["Company has been updated: ", "Company image has been updated: ", "Company image has been uploaded: "],
        Designation: ["New designation has been created: ", "New designation has been deleted: ", "Designation has been updated: "],
        beneficiary: ["Corporate beneficiary has been updated: ", "New Individual beneficiary has been created: ", "New Corporate beneficiary has been created: ", "Individual beneficiary has been updated: "],
        Alertcriteria: ['Monitoring threshold has been Updated:', "Monitoring threshold corporate limit has been updated",],
        AccountRegistration: ["New corporate account has been created: ", "Individual Account has been updated: "],
        SanctionUpdates: ["Sanction corporate has been updated: ", "Sanction corporate has been created: ", "New sanction corporate has been created: ", "corporate account has been updated: ", "Individual account has been updated: ", "Individual account has been created: ", "Individual account has been uploaded (sanction upload) "],
        Accountprofiling: ["corporate account has been updated: ", "account has been updated: "],
        AlertPreference: ["Notification page has been created : ", "Notification preference updated for Id No.: "]
    };


    const getCategory = (notificationData) => {
        for (const [category, keywords] of Object.entries(notificationMappings)) {
            if (keywords.some(keyword => notificationData.includes(keyword))) {
                return category;
            }
        }
        return null;
    };

    const handleNotificationClick = (notificationData) => {
        const category = getCategory(notificationData);

        switch (category) {
            case 'Branch':
                history.push({
                    pathname: '/admin/branches',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'Transaction':
                history.push({
                    pathname: '/admin/transaction/monitoring',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'Country':
                history.push({
                    pathname: '/admin/country',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'Designation':
                history.push({
                    pathname: '/admin/designation',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'AlertPreference':
                history.push({
                    pathname: '/admin/account/preference',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'CompanyProfile':
                history.push({
                    pathname: '/admin/company',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'Alertcriteria':
                history.push({
                    pathname: '/admin/transaction/threshold',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'beneficiary':
                history.push({
                    pathname: '/admin/transaction/beneficiary',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'SanctionUpdates':
                history.push({
                    pathname: '/admin/account/Updates',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'Accountprofiling':
                history.push({
                    pathname: '/admin/account/profiling',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            case 'AccountRegistration':
                history.push({
                    pathname: '/admin/account/registration',
                    state: { getUserData: location?.state?.getUserData, accessToken: accessToken, refreshToken: location?.state?.refreshToken, emailId: location?.state?.getUserData?.emailId },
                });
                break;
            default:
        }


        const notificationIndex = notifications?.indexOf(notificationData);

        if (notificationIndex !== -1) {
            const updatedNotifications = [...notifications];
            updatedNotifications?.splice(notificationIndex, 1);

            setNotificationCount(notificationCount - 1);
            setNotifications(updatedNotifications);

            sessionStorage.setItem('messages', JSON?.stringify(updatedNotifications));
            sessionStorage.setItem('notificationCount', (notificationCount - 1).toString());
        }
        setVisible1(false);
    };

    useEffect(() => {
        if (location?.state?.type === 'sign_In' || location?.state?.getUserData?.data || sessionStorage?.hinT_D) {
            axios.get(process.env.REACT_APP_BACKEND_HOST + `/get/image`, {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    const base64Image = btoa(String.fromCharCode(...new Uint8Array(response.data)));
                    const dataUrl = `data:image/png;base64,${base64Image}`;
                    setUploadedImageUrl(dataUrl);
                    navigate.push({
                        state: { profileDp: dataUrl, getUserData: location?.state?.getUserData, accessToken: accessToken, emailId: location?.state?.getUserData?.emailId },
                    });
                    setTimeout(() => {
                        sessionStorage.removeItem('hinT_D');
                    }, 3000);
                })
                .catch(error => {
                    if (error?.response && error?.response?.status === 403) {
                        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    }
                });
        }
    }, []);

    let items = [];

    items.push({
        icon: 'pi pi-bell',

        template: (
            <div className="p-menuitem ml-3 mr-3" id='nn1'>
                <div className="notification-container" style={{ height: '20px' }}>
                    <i className="pi pi-bell" id='icon-a' onClick={() => setVisible1(true)} style={{ color: '#2a3759', fontWeight: '800', cursor: 'pointer' }}></i>
                    <Badge value={notificationCount} className="custom-badge" />
                </div>
                <Sidebar visible={visible1} position="right" className='relative' onHide={() => setVisible1(false)}>
                    <div className='flex align-items-center justify-content-between' style={{ display: 'block' }}>
                        <h2 className='bell-notification'>Notifications</h2>
                        <div><i className='pi pi-times font-bold text-lg' onClick={() => setVisible1(false)} style={{ cursor: 'pointer' }}></i></div>
                    </div>
                    <div className={`notification-slide-in ${clearAnimationVisible ? "notification-clear-animation" : ""}`}>
                        <div className='notification-list'>
                            {notifications?.map((notificationData, index) => (
                                <div
                                    key={index}
                                    className="notification-item"
                                    onClick={() => handleNotificationClick(notificationData)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {notificationData}
                                </div>
                            ))}
                        </div>
                    </div>
                    {clearButtonVisible && (
                        <button className="clear-all-button my-4 mx-3 font-bold text-gray-600" style={{ background: 'none', border: 'none', position: 'absolute', bottom: '0', left: '0', cursor: 'pointer' }} onClick={handleClearAll}>
                            Clear All
                        </button>
                    )}
                </Sidebar>
            </div>
        )
    });

    items.push({
        icon: 'pi pi-cog',
        template: (
            <div className="p-menuitem ml-3" id='nn' >
                <i className="pi pi-search" id='icon-a' onClick={() => setVisible(true)} style={{ color: '#124A99', fontWeight: '800', cursor: 'pointer' }}></i>
                <Dialog header="Search Documents" visible={visible} className="sing_up_main_Dialog search-header" style={{ width: '24rem', }} onHide={() => setVisible(false)}>
                    <div className="feild c pt-3 ">
                        <div className="flex">
                            <div className="input mnmnmn">
                                <div className="see_Pass">
                                    <i className='pi pi-search' ></i>
                                    <InputText
                                        type="text"
                                        value={searchText}
                                        onChange={handleInputChange}
                                        placeholder="Search..."
                                        className="w-full"
                                    />
                                </div>

                                {searchText && dropdownOptions?.length > 0 && (
                                    <div className="dropdown-contentr shadow-2">
                                        {dropdownOptions?.map((option, index) => (
                                            <div
                                                key={index}
                                                onClick={() => handleOptionSelect(option)}
                                                className="dropdown-item"
                                            >
                                                {option}
                                            </div>
                                        ))}
                                    </div>
                                )}</div>
                        </div>
                    </div>
                    <br />
                    <br />
                </Dialog>
            </div>
        )
    });

    items.push({
        icon: 'pi pi-user',

        template: (
            <div className="p-menuitem" id='nn-3' style={{ minWidth: '181px', display: 'flex', alignItems: 'center' }}>
                <TieredMenu model={item} popup ref={menu} id="overlay_tmenu" />
                <Avatar
                    shape="circle"
                    size="large"
                    className="p-avatar"
                    style={{
                        backgroundColor: '#ffffff',
                        color: '#124A99',
                        border: '1px solid rgb(192 192 192 / 26%)',
                        cursor: 'pointer',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        width: '48px',
                        height: '48px',
                        lineHeight: '48px',
                    }}
                >
                    {uploadedImageUrl ? < img src={uploadedImageUrl} alt="Profile" width={200} /> : initials}
                </Avatar>
                <Dropdown
                    id="dropdown"
                    options={options}
                    onChange={(e) => handledropdown(e)}
                    optionLabel="label"
                    placeholder={name}
                    style={{ border: 'none', maxHeight: '200px' }}
                    className='header-drop-username'
                />
            </div >
        )
    });

    return (
        <div className="header-main">
            {loading &&
                <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            }
            <div className="header-title">{headerLabel ? headerLabel : 'DASHBOARD'}</div>
            <Menu model={items} className="border-none w-full relative" />
        </div>
    )
}
export default Header;