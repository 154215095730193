import React, { useEffect, useState } from 'react';
import './Header.css'
import acaialogo from '../../Landing Page/media/transparent acaia logo 2.png'
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import { Button } from 'primereact/button';

export default function Header({ onSubscriptionButtonClick, onbannerButtonClick, onsolutionsButtonClick, onDemoButtonClick }) {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const scrollPath = location.state?.path;
  const [blogData, setBlogData] = useState(location.state?.blogData);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 750);
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuItem = [
    { name: 'Home' },
    { name: 'Solutions' },
    { name: 'Plan' },
    { name: 'Contact Us' },
  ]

  useEffect(() => {
    if (scrollPath === 'Home') {
      handleThirdsectionButtonClick()
    } else if (scrollPath === 'Solutions') {
      handlesolutionsButtonClick();
    } else if (scrollPath === 'Plan') {
      history.push({
        pathname: '/plan',
        state: {
          path: 'Plan',
        },
      })
    } else if (scrollPath === 'Contact Us') {
      history.push({
        pathname: '/contactUs',
        state: {
          path: 'Contact Us',
        },
      })
    } else if (scrollPath === 'Logo') {
      handleThirdsectionButtonClick();
    }
  }, []);

  const handleSubscriptionButtonClick = (path) => {
    closeMenu();
    if (onSubscriptionButtonClick) {
      onSubscriptionButtonClick();
    } else if (path) {
      history.push({
        pathname: '/',
        state: {
          path: path,
        },
      });
    }
  };

  const handleThirdsectionButtonClick = (path) => {
    closeMenu();
    if (onbannerButtonClick) {
      onbannerButtonClick();
    } else if (path) {
      history.push({
        pathname: '/',
        state: {
          path: path,
        },
      });
    }
  };
  const handlesolutionsButtonClick = (path) => {
    closeMenu();
    if (onsolutionsButtonClick) {
      onsolutionsButtonClick();
    } else if (path) {
      history.push({
        pathname: '/',
        state: {
          path: path,
        },
      });
    }
  };
  const handlecontactButtonClick = (path) => {
    closeMenu();
    if (onDemoButtonClick) {
      onDemoButtonClick();
    } else if (path) {
      history.push({
        pathname: '/',
        state: {
          path: path,
        },
      });
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className='header-maintain' style={{ background: '#f9f8ff' }}>
      <div className='max_W header_F'>
        <img src={acaialogo} onClick={() => handleThirdsectionButtonClick('Logo')} alt='logoimage' style={{ height: '30px', cursor: 'pointer' }} />
        <div className='main-hed'>
          <div className='main-hed-btn'>
            <div className='link-button-header-maintain' >

              <button className='acaia-header-but' onClick={() => handleThirdsectionButtonClick('Home')} style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>
                Home
              </button >
              <button className='solutions-header-but' onClick={() => handlesolutionsButtonClick('Solutions')} style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>
                Solutions
              </button>
              <button className='plan-header-but' onClick={() => history.push({
                pathname: '/plan',
                state: {
                  path: 'Plan',
                },
              })} style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>
                Plan
              </button>
              <button className='contact-header-but' onClick={() => history.push({
                pathname: '/contactUs',
                state: {
                  path: 'Contact Us',
                },
              })} style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>
                Contact Us
              </button>
            </div>
            <button className='header-buts' onClick={() => history.push('/login')} >
              Login
            </button>
          </div>
        </div>
        {isMobile && (
          <button className="header-but hamburger-icon" onClick={() => setMenuOpen(!menuOpen)}>
            ☰
          </button>
        )}
        {menuOpen && isMobile && (
          <div className="mobile-menu">
            <button className='btn-align-center' onClick={() => handleThirdsectionButtonClick('Home')} style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>Home</button>
            <button className='btn-align-center' onClick={() => handlesolutionsButtonClick('Solutions')} style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>Solutions</button>
            <button className='btn-align-center' onClick={() =>
              history.push({
                pathname: '/plan',
                state: {
                  path: 'Plan',
                },
              })
            } style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>Plan</button>
            <button className='btn-align-center' onClick={() => history.push({
              pathname: '/contactUs',
              state: {
                path: 'Contact Us',
              },
            })} style={{ cursor: 'pointer', color: '#124A99', fontWeight: 600, border: 'none', background: 'none', fontSize: '17px' }}>Contact Us</button>
            <button className='header-but' onClick={() => history.push('/login')} >
              Login
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
