import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
// import { useLocation } from 'react-router-dom';

const AccountDropdown = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(props.selectedAccountId || '');
  // const location = useLocation();

  useEffect(() => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + props?.data?.accessToken
      },
    };

    axios.get(process.env.REACT_APP_BACKEND_HOST + '/accounts/all', requestOptions)
      .then(response => {
        setAccounts(response.data);
      })
      
  }, [ props?.data?.accessToken]);

  const handleAccountChange = (event) => {
    const selectedAccountId = event.target.value;
    // const accountId = selectedAccount ? selectedAccount.accountId : ''; // Extract accountId from the selected account object
    setSelectedAccount(selectedAccountId); // Store the accountId in the state
    // props.onChange(accountId); // Pass the selected accountId back to the parent
    props.onChange(selectedAccountId);
  };


  return (
    <Dropdown
      value={selectedAccount}
      options={accounts}
      onChange={handleAccountChange}
      optionLabel="accountId" 
      style={{ width: '100%', height: '40px', border: '1px solid #ced4da',borderRadius:'6px' }}
    />
  );
};

export default AccountDropdown;
