import React, { useRef, useEffect, useState } from 'react';
import "./About.css";
import { useLocation } from 'react-router-dom';
import { BsDot } from "react-icons/bs";
import image from '../../assets/Rectangle 220.png';


export default function About() {

    const location = useLocation();


    useEffect(() => {

    }, []);


    const items = [
        { name: 'Learn how Acaia reduces risk ' },
        { name: 'Get pricing information' },
        { name: 'Learn about white-label solutions' },
        { name: 'Training and support. ' },
    ];


    return (
        <>
            <div className='about_Sec'>
                {/* <p className="contact-heading  he_Ali" style={{ color: '#093EA4', height: 'unset', padding: '0 15px' }}> Ready to learn more about Acaia? </p >

                <p className='third-para' style={{ padding: '0 20px', textAlign: 'center' }}>Share your requirements, and we'll be happy to build a customized product for your needs.  </p>

                <div className='third_C_SEC'>
                    <div className='third_C_L'>
                        <div className='third_C_'>
                            <BsDot className='third_C_I' />
                            <span className='third_C__N'>{items[0]?.name}</span>
                        </div>
                        <div className='third_C_'>
                            <BsDot className='third_C_I' />
                            <span className='third_C__N'>{items[1]?.name}</span>
                        </div>
                    </div>
                    <div className='third_C_R'>
                        <div className='third_C_'>
                            <BsDot className='third_C_I' />
                            <span className='third_C__N'>{items[2]?.name}</span>
                        </div>
                        <div className='third_C_'>
                            <BsDot className='third_C_I' />
                            <span className='third_C__N'>{items[3]?.name}</span>
                        </div>
                    </div>
                </div> */}

                <div className='max_W fLE_X'>

                    <div className='le_FT'>
                        <p className="contact-heading tex_A_Left he_Ali" style={{ color: '#093EA4', height: 'unset', textAlign: 'left', justifyContent: 'left', padding: '0 15px' }}> Ready to learn more about Acaia? </p >

                        <p className='third-para tex_A_Left' style={{ padding: '0 20px' }}>Share your requirements, and we'll be happy to build a customized product for your needs.  </p>

                        <div className='third_C_SEC'>
                            <div className='third_C_L'>
                                <div className='third_C_'>
                                    <BsDot className='third_C_I' />
                                    <span className='third_C__N'>{items[0]?.name}</span>
                                </div>
                                <div className='third_C_'>
                                    <BsDot className='third_C_I' />
                                    <span className='third_C__N'>{items[1]?.name}</span>
                                </div>
                            </div>
                            <div className='third_C_R'>
                                <div className='third_C_'>
                                    <BsDot className='third_C_I' />
                                    <span className='third_C__N'>{items[2]?.name}</span>
                                </div>
                                <div className='third_C_'>
                                    <BsDot className='third_C_I' />
                                    <span className='third_C__N'>{items[3]?.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ri_GHT'>
                        <img src={image} />
                    </div>
                </div>

            </div >
        </>
    );
}
