import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useState, useEffect } from 'react';
import '../BeneficiaryPeding/BendingDataPage.css';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';

function IndividualPending(props) {

    const [tableData, setTableData] = useState(
        // [
        //     {
        //         "clientId": "4779",
        //         "name": "pavi",
        //         "status": "Pending",
        //         "profileType": "High Risk",
        //         "accountType": "savings account",
        //         "branchName": "Nagercoil",
        //         "reason": "account holder name match into sanction name"
        //     },
        //     {
        //         "clientId": "4778",
        //         "name": "chin shek lau",
        //         "status": "Pending",
        //         "profileType": "High Risk",
        //         "accountType": "savings account",
        //         "branchName": "Nagercoil"
        //     },
        //     {
        //         "clientId": "2150",
        //         "name": "Karthik Kumar",
        //         "status": "Pending",
        //         "profileType": "High Risk",
        //         "accountType": "savings account",
        //         "branchName": "Nagercoil"
        //     }
        // ]
    );
    const history = useHistory();
    const [indipencount, ] = useState(props?.data?.accPemding)
    const toast = React.useRef(null);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;

    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    // const displayWarningToast = (message) => {
    //     if (toast.current) {
    //         toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
    //     }
    // };

    // const email = props?.data?.emailId;

    useEffect(() => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/Corporates', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                if (response.data.length === 0) {
                    // displayWarningToast('No individual pending accounts found.');
                } else {
                    setTableData(response.data);
                }
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                }
                else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
            });
    }, [accessToken]);

    const actionbody = (rowData) => {
        const { clientId } = rowData;
        const handleButtonClick = () => {
            history.push({
                pathname: '/admin/account/individualpending',
                state: {
                    clientId: clientId,
                    emailId: location?.state?.emailId,
                    accPemding: location?.state?.accPemding,
                    benPending: location?.state?.benPending,
                    traPending: location?.state?.traPending,
                    accessToken: accessToken,
                    getUserData: location?.state?.getUserData
                },
            });
        };

        return (
            <React.Fragment>
                <Button icon="pi pi-info-circle" style={{ border: 'none' }} className="p-button-rounded p-button-secondary p-mr-2" onClick={handleButtonClick} id='MI-1' />
            </React.Fragment>
        );
    };

    const profileTemplate = (rowData) => {
        const status = rowData.status;
        let color = '';

        if (status === 'Pending') {
            color = '#f7af00';
        } else if (status === 'Blocked') {
            color = '#f42616';
        } else if (status === 'Cleared') {
            color = 'rgb(69 160 75)';
        } else if (status === 'On-going') {
            color = '#3746c6';
        }

        return (
                <div
                    style={{
                        color: color,
                        fontWeight: '500',
                    }}
                >
                    {status}
                </div>
        );
    };

    // const datalength = tableData ? tableData.length : 0;
    // sessionStorage.setItem("individualpending", datalength);

    // let indipencounts = sessionStorage.getItem('individuallistcount');

    return (
        <>
            <Toast ref={toast} className='transaction-toast' />
            <div className="input">
                {/* <div className='w-full'>
                    <h2 className='tab-txt ' style={{ color: '#124A99', fontSize: '18px' }}>Account Pending<span className='pend-count-styl'>&nbsp; {indipencount} &nbsp; </span></h2>
                </div> */}
                <div className='head head_T'>Account Pending   :<span className='pend-count-styl'>{indipencount || '0'}</span></div>
                <DataTable
                    value={tableData}
                    stripedRows
                    showGridlines
                    paginator
                    rows={5}
                    scrollable
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    style={{ whiteSpace: 'nowrap' }}
                    scrollHeight="80vh"
                    rowClassName={(rowData) => {
                        if (rowData.reason === 'account holder name match into sanction name') {
                            return 'green-background blue-border ';
                        }
                        return '';
                    }}
                    footer={
                        tableData && tableData.length > 0 && tableData.some(row => row.reason === 'account holder name match into sanction name') ? (
                            <span className="account match">Highlighted cause the account match with updated sanction list<span className='red-star'>*</span></span>
                        ) : null
                    }
                >
                    <Column field="clientId" header="Client Id"></Column>
                    <Column field="name" header="Full Name"></Column>
                    <Column field="status" header="Status" body={profileTemplate}></Column>
                    <Column field="moreinfo" style={{ width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }} header="More Info" body={actionbody}></Column>
                </DataTable>
            </div>
        </>
    )
}

export default IndividualPending
