import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export default function PrivateRoute({ children, ...rest }) {
    const location = useLocation();

    let auth = false;
    if (location?.state?.accessToken !== null) {
        auth = true;
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    auth ? (
                        children
                    ) : (
                        <Redirect
                            to={process.env.REACT_APP_AUTH_URL}/*{{
                            pathname: "/login",
                            state: { from: location }
                        }}*/
                        />
                    )
                }
            />
        );
    } else {
        window.location.assign(process.env.REACT_APP_AUTH_URL);
    }
    return (<></>);
}
