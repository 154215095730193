import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import './CorporateadhocMoreInfo.css'
import { useHistory, useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function CorporateadhocMoreInfo() {


    const toast = useRef(null);
    const [accountdetials, setAccountDetials] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // const [adhocSearchMenu, setAdhocSearchMenu] = useState();
    const [posit, setPosit] = useState('');
    // const [additionalInfo, setAdditionalInfo] = useState('');
    const location = useLocation();
    const storageData = location.state;
    const moreinfovalue = storageData?.pageCount;
    const accessToken = location?.state?.accessToken;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/sanction?id=' + moreinfovalue, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                setAccountDetials(response.data);
                setLoading(false);
                const capitalizedAliasData = response?.data?.positions?.map((pos) => {
                    return pos;
                });
                setPosit(capitalizedAliasData)
                // const additionalDetails = response?.data?.additionalDetails?.map((addi) => {
                //     return addi;
                // });
                // setAdditionalInfo(additionalDetails)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    toast.error({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', autoClose: 3000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                }
                setLoading(false); // Make sure to stop loading state on error as well
            });
        // setAdhocSearchMenu('Personal Details')
    }, [moreinfovalue, accessToken]);

    const items = [
        {
            label: 'Adhoc Search', command: () =>
                history.push({
                    pathname: '/admin/adhoc/search',
                    state: {
                        accessToken: location?.state?.accessToken,
                        emailId: location?.state?.emailId,
                        getUserData: location?.state?.getUserData,
                        refreshToken: location?.state?.refreshToken,
                    },
                })
        },
        {
            label: 'Adhoc Search Data', command: () => history.push({
                pathname: '/admin/adhoc/individualdata',
                state: {
                    individualname: location?.state?.storageData?.individualname,
                    exactvalue: location?.state?.storageData?.exactvalue,
                    partialvalue: location?.state?.storageData?.partialvalue,
                    eightyvalue: location?.state?.storageData?.eightyvalue,
                    fullName: location?.state?.individualname,
                    exact: location?.state?.exactvalue,
                    partial: location?.state?.partialvalue,
                    wrinklerDistance: location?.state?.eightyvalue,
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                    refreshToken: location?.state?.refreshToken,
                },
            })
        },
        { label: 'Adhoc Search MoreInfo' }
    ];
    const home = location?.state?.getUserData?.premiumPlan === "FreeTrial" ? null : {
        icon: 'pi pi-home',
        command: () => history.push({
            pathname: '/admin/dashboard',
            state: {
                accessToken: location?.state?.accessToken,
                emailId: location?.state?.emailId,
                getUserData: location?.state?.getUserData,
                refreshToken: location?.state?.refreshToken,
            },
        })
    };
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    const handleNavigate = (url) => {
        history.push(url);

    };

    const addFontColorAndSpaces = (text, matchType) => {
        const englishLetterRegex = /[a-zA-Z]/;
        const parts = [];
        let currentPart = ' ';

        const className = matchType === "bg-orange-500" ? "font-orange-500" : "";

        for (let i = 0; i < text.length; i++) {
            const char = text.charAt(i);
            const capitalizedChar = char.toUpperCase();

            if (englishLetterRegex.test(char)) {
                currentPart += `<span class="${className}">${capitalizedChar}</span>`;
            } else if (char === ' ') {
                currentPart += '&nbsp;';
            } else {
                currentPart += char;
            }
        }

        parts.push(currentPart);
        return parts.join('');
    };

    const addFontColorAndSpaces1 = (text, matchType) => {
        const englishLetterRegex = /[a-zA-Z]/;
        const parts = [];
        let currentPart = ' ';

        const className = matchType === "bg-orange-500" ? "font-orange-500" : "";

        for (let i = 0; i < text.length; i++) {
            const char = text.charAt(i);
            const capitalizedChar = char.toLowerCase();

            if (englishLetterRegex.test(char)) {
                currentPart += `<span class="${className}">${capitalizedChar}</span>`;
            } else if (char === ' ') {
                currentPart += '&nbsp;';
            } else {
                currentPart += char;
            }
        }

        parts.push(currentPart);
        return parts.join('');
    };

    const exactNames = accountdetials?.exactName?.map((nameObj, index) => {
        const name = nameObj.name;
        const matchType = nameObj.matchType;

        const formattedName = addFontColorAndSpaces(name, matchType);
        return (
            <React.Fragment key={name}>
                {index > 0 && <span>&nbsp;</span>}
                <span
                    style={{ fontSize: (windowWidth <= 500 ? '12px' : '14px'), fontWeight: 'bold' }}
                    dangerouslySetInnerHTML={{ __html: formattedName }}
                />
            </React.Fragment>
        );
    });

    const exactNames1 = accountdetials?.exactName?.map((nameObj, index) => {
        const name = nameObj.name;
        const matchType = nameObj.matchType;

        const formattedName = addFontColorAndSpaces1(name, matchType);
        return (
            <React.Fragment key={name}>
                {index > 0 && <span>&nbsp;</span>}
                <span
                    style={{ fontSize: (windowWidth <= 500 ? '12px' : '14px'), fontWeight: 'bold' }}
                    dangerouslySetInnerHTML={{ __html: formattedName }}
                />
            </React.Fragment>
        );
    });

    // const aliasData = accountdetials?.alias || [];

    // Capitalize the first letter of each alias name
    // const capitalizedAliasData = aliasData.map((aliasGroup) => {
    //     const capitalizedAliasGroup = {
    //         ...aliasGroup,
    //         aliasName: aliasGroup.aliasName.map((alias) => ({
    //             ...alias,
    //             name: alias.name.charAt(0).toUpperCase() + alias.name.slice(1)
    //         }))
    //     };
    //     return capitalizedAliasGroup;
    // });

    // let activestatus = accountdetials.activeStatus;
    // let aliasData = accountdetials.alias;
    // let countriesAssociatedWith = accountdetials.countriesAssociatedWith;
    // let dates = accountdetials.dates;
    // let ids = accountdetials.ids;

    // const menuItem = (event) => {
    //     setAdhocSearchMenu(event.target.innerText);
    // };

    // const careerGoalsRes = accountdetials?.countriesAssociatedWith?.map(countryObj =>
    //     <div className=''>
    //         <div><b>{countryObj || 'N/A'}</b></div>
    //     </div>
    // );

    return (
        <>

            <div className="mainSec max-height-adhoc" >
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                <Toast ref={(el) => (toast.current = el)} />
                {loading ? (
                    <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <Card title={accountdetials?.fullName} className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
                            <div style={{ padding: '10px 0' }}>
                                {accountdetials && (
                                    <div className='h_ing'>
                                        Personal Details
                                    </div>
                                )}

                                {accountdetials?.id && (
                                    <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Id <span>:</span></div>
                                        <div className='value'>{accountdetials?.id || 'N/A'}</div>
                                    </div>
                                )}

                                {accountdetials?.fullName && (
                                    <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Full Name <span>:</span></div>
                                        <div className='value' style={{ overflow: 'hidden', height: (windowWidth < 380 ? '50px' : '') }}>{exactNames1 || 'N/A'}</div>
                                    </div>
                                )}

                                {accountdetials?.gender && (
                                    <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Gender <span>:</span></div>
                                        <div className='value'>{accountdetials?.gender || 'N/A'}</div>
                                    </div>
                                )}

                                {accountdetials?.addresses?.length > 0 && (
                                    <>
                                        {accountdetials?.addresses.length <= 1 && (
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Address <span>:</span></div>
                                                {accountdetials?.addresses?.map((add) => (
                                                    <div className='value'>{add?.address || 'N/A'}</div>
                                                ))}
                                            </div>
                                        )}

                                        {accountdetials?.addresses.length > 1 && (
                                            <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Address <span>:</span></div>
                                                </div>
                                                <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                    {accountdetials?.addresses?.map((add) => (
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.address || 'N/A'}</div>
                                                    ))}
                                                </div><br />
                                            </div>
                                        )}
                                    </>
                                )}

                                {accountdetials?.addresses?.length > 0 && (
                                    <>
                                        {accountdetials?.addresses.length <= 1 && (
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>City <span>:</span></div>
                                                {accountdetials?.addresses?.map((add) => (
                                                    <div className='value'>{add?.city || 'N/A'}</div>
                                                ))}
                                            </div>
                                        )}

                                        {accountdetials?.addresses.length > 1 && (
                                            <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>City <span>:</span></div>
                                                </div>
                                                <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                    {accountdetials?.addresses?.map((add) => (
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.city || 'N/A'}</div>
                                                    ))}
                                                </div><br />
                                            </div>
                                        )}

                                    </>

                                )}
                                {accountdetials?.addresses?.length > 0 && (

                                    <>
                                        {accountdetials?.addresses.length <= 1 && (
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Country <span>:</span></div>
                                                {accountdetials?.addresses?.map((add) => (
                                                    <div className='value'>{add?.country || 'N/A'}</div>
                                                ))}
                                            </div>
                                        )}

                                        {accountdetials?.addresses.length > 1 && (
                                            <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Country <span>:</span></div>
                                                </div>
                                                <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                    {accountdetials?.addresses?.map((add) => (
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.country || 'N/A'}</div>
                                                    ))}
                                                </div><br />
                                            </div>
                                        )}

                                    </>
                                )}

                                {accountdetials?.modifiedDate && (
                                    <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Modified Date <span>:</span></div>
                                        <div className='value'>{accountdetials?.modifiedDate || 'N/A'}</div>
                                    </div>
                                )}
                                {accountdetials?.deceased && (
                                    <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Deceased <span>:</span></div>
                                        <div className='value'>{accountdetials?.deceased || 'N/A'}</div>
                                    </div>
                                )}

                                {accountdetials?.description && (
                                    <div className='info_D' style={{ flexWrap: (windowWidth < 380 ? 'wrap' : ''), height: (windowWidth < 380 ? 'auto' : '50px'), justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Description <span>:</span></div>
                                        <div className='value'>{accountdetials?.description || 'N/A'}</div>
                                    </div>
                                )}

                                {exactNames && (
                                    <div className='info_D' style={{ padding: (windowWidth < 380 ? '10px 0px 0px 20px' : ''), flexWrap: (windowWidth < 380 ? 'wrap' : ''), height: (windowWidth < 600 ? 'auto' : '50px'), justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>All Type Name's <span>:</span></div>
                                        <div className='value'>{exactNames || 'N/A'}</div>
                                    </div>
                                )}

                            </div>
                            {accountdetials?.countriesAssociatedWith?.length >= 1 && (
                                <>
                                    <div className='h_ing'>
                                        Countries Associated With
                                    </div>

                                    <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', padding: (windowWidth <= 500 ? '20px 0px' : '30px 20px') }}>
                                        <DataTable
                                            value={accountdetials?.countriesAssociatedWith}
                                            rows={1000}
                                            stripedRows
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            resizableColumns
                                            scrollable
                                            style={{ width: '100%' }}
                                        >
                                            <Column field="associationType" style={{ width: '300px' }} header="Association Type"></Column>
                                            <Column field="country" header="Country" ></Column>
                                        </DataTable>
                                    </div>
                                </>
                            )}

                            {accountdetials?.roles?.length >= 1 && (
                                <>
                                    <div className='h_ing'>
                                        Roles
                                    </div>

                                    <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', padding: (windowWidth <= 500 ? '20px 0px' : '30px 20px') }}>
                                        <DataTable
                                            value={accountdetials?.roles}
                                            rows={1000}
                                            stripedRows
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            resizableColumns
                                            scrollable
                                            style={{ width: '100%' }}
                                        >
                                            <Column field="type" header="Role Type"></Column>
                                            <Column field="title" header="Title" ></Column>
                                            <Column field="from" header="From"></Column>
                                            <Column field="to" header="To" ></Column>
                                        </DataTable>
                                    </div>
                                </>
                            )}

                            {accountdetials?.alias?.length >= 1 && accountdetials?.roles?.length >= 1 && (
                                <div className='div' />
                            )}

                            {accountdetials?.alias?.length > 0 && (
                                <>
                                    <div className='h_ing'>
                                        Alias
                                    </div>

                                    <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <div className='info_D'>
                                            <div className='leable'>Alias Type :</div>
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', padding: '0 20px' }}>
                                            {accountdetials?.alias?.map((item, index) => (
                                                <div className='value'>{item?.aliasType || 'N/A'}</div>
                                            ))}
                                        </div><br />
                                    </div>

                                    <div className='div' />

                                    <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                        <div className='info_D'>
                                            <div className='leable'>Alias Name :</div>
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', padding: '0 20px' }}>
                                            {accountdetials?.alias?.map((item, index) => (
                                                <>
                                                    {item?.aliasName?.map((i, index) => (
                                                        <div className='info_D' style={{ height: 'auto', justifyContent: 'flex-start', padding: 'unset', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                            <div className='value'>{i?.name + ' ,' || 'N/A'}</div>
                                                        </div>
                                                    ))}
                                                </>
                                            ))}
                                        </div><br />
                                    </div>
                                </>
                            )}

                            {accountdetials?.ids?.length > 0 && (
                                <div className='h_ing'>
                                    Ids
                                </div>
                            )}

                            {accountdetials?.ids?.length > 0 && (

                                <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', padding: (windowWidth <= 500 ? '20px 0px' : '30px 20px') }}>
                                    <DataTable
                                        value={accountdetials?.ids}
                                        rows={1000}
                                        stripedRows
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        resizableColumns
                                        scrollable
                                        style={{ width: '100%' }}
                                        scrollHeight={windowWidth <= 1540 ? '35vh' : '40vh'}
                                    >
                                        <Column field="idNumber" header="Id Number"></Column>
                                        <Column field="type" header="Id Type" ></Column>
                                        {/* <Column field="matchType" header="Match Type"></Column> */}
                                        <Column field="country" header="Country"></Column>
                                    </DataTable>
                                </div>

                            )}

                            {accountdetials?.positions?.length > 0 && (
                                <div className='h_ing'>
                                    Positions
                                </div>
                            )}

                            {accountdetials?.positions?.length > 0 && (
                                <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', padding: (windowWidth <= 500 ? '20px 0px' : '30px 20px') }}>
                                    <DataTable
                                        value={posit}
                                        rows={1000}
                                        stripedRows
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                        resizableColumns
                                        scrollable
                                        style={{ width: '100%' }}
                                        scrollHeight={windowWidth <= 1540 ? '35vh' : '40vh'}
                                    >
                                        <Column field="position" header="Positions"></Column>
                                        <Column field="startDate" header="Start Date" ></Column>
                                        <Column field="country" header="Country"></Column>
                                    </DataTable>
                                </div>
                            )}

                            {accountdetials?.additionalDetails?.length > 0 && (
                                <div className='h_ing'>
                                    Additional Details
                                </div>
                            )}

                            {accountdetials?.additionalDetails?.length > 0 && (
                                <>
                                    {accountdetials?.additionalDetails.length >= 1 && (
                                        <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Name <span>:</span></div>
                                            </div>
                                            <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                {/* {accountdetials?.additionalDetails?.map((add) => (
                                                    <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.name + ' , ' || 'N/A'}</div>
                                                ))} */}

                                                {accountdetials?.additionalDetails[0]?.name[0] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[0] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}

                                                {accountdetials?.additionalDetails[0]?.name[1] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[1] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}

                                                {accountdetials?.additionalDetails[0]?.name[2] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[2] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[3] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[3] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[4] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[4] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[5] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[5] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[6] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[6] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[7] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[7] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[8] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[8] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[9] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[10] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[10] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[10] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.name[11] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.name[11] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {accountdetials?.additionalDetails.length >= 1 && (
                                        <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Position <span>:</span></div>
                                            </div>
                                            <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                {/* {accountdetials?.additionalDetails?.map((add) => (
                                                    <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.position + ' , ' || 'N/A'}</div>
                                                ))} */}

                                                <div>
                                                    {accountdetials?.additionalDetails[0]?.position[0] && (
                                                        <>
                                                            <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[0] + ' ,' || 'N/A'}</div>
                                                        </>
                                                    )}

                                                    {accountdetials?.additionalDetails[0]?.position[1] && (
                                                        <>
                                                            <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[1] + ' ,' || 'N/A'}</div>
                                                        </>
                                                    )}

                                                    {accountdetials?.additionalDetails[0]?.position[2] && (
                                                        <>
                                                            <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[2] + ' ,' || 'N/A'}</div>
                                                        </>
                                                    )}
                                                </div>
                                                {accountdetials?.additionalDetails[0]?.position[3] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[3] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[4] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[4] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[5] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[5] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[6] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[6] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[7] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[7] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[8] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[8] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[9] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[10] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[10] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[10] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                                {accountdetials?.additionalDetails[0]?.position[11] && (
                                                    <>
                                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.additionalDetails[0]?.position[11] + ' ,' || 'N/A'}</div>
                                                    </>
                                                )}
                                            </div>


                                        </div>
                                    )}

                                    {accountdetials?.additionalDetails.length >= 1 && (
                                        <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Nationality <span>:</span></div>
                                            </div>
                                            <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                {accountdetials?.additionalDetails?.map((add) => (
                                                    <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.nationality || 'N/A'}</div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {accountdetials?.additionalDetails.length >= 1 && (
                                        <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Keywords <span>:</span></div>
                                            </div>
                                            <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                {accountdetials?.additionalDetails?.map((add) => (
                                                    <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.keywords || 'N/A'}</div>
                                                ))}
                                            </div>
                                        </div>
                                    )}


                                    {accountdetials?.additionalDetails.length >= 1 && (
                                        <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Education <span>:</span></div>
                                            </div>
                                            <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                {accountdetials?.additionalDetails?.map((add) => (
                                                    <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{add?.education || 'N/A'}</div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {accountdetials?.additionalDetails.length >= 1 && (
                                        <div className='flex' style={{ flexWrap: 'nowrap', justifyContent: 'space-between', margin: 'unset', flexDirection: 'column' }}>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Website <span>:</span></div>
                                            </div>
                                            <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                <>
                                                    {accountdetials?.additionalDetails?.map((add, index) => (
                                                        <div className='leable value'><a style={{ cursor: 'pointer', color: 'blue', fontWeight: '500' }} href={add?.website[0]}>{add?.website[0] || 'N/A'}</a></div>
                                                    ))}

                                                    {accountdetials?.additionalDetails[0]?.website[1] && (
                                                        <>
                                                            {accountdetials?.additionalDetails?.map((add, index) => (
                                                                <div className='leable value'><a style={{ cursor: 'pointer', color: 'blue', fontWeight: '500' }} href={add?.website[1]}>{add?.website[1] || 'N/A'}</a></div>
                                                            ))}
                                                        </>
                                                    )}

                                                    {accountdetials?.additionalDetails[0]?.website[2] && (
                                                        <>
                                                            {accountdetials?.additionalDetails?.map((add, index) => (
                                                                <div className='leable value'><a style={{ cursor: 'pointer', color: 'blue', fontWeight: '500' }} href={add?.website[2]}>{add?.website[2] || 'N/A'}</a></div>
                                                            ))}
                                                        </>
                                                    )}
                                                    {accountdetials?.additionalDetails[0]?.website[3] && (
                                                        <>
                                                            {accountdetials?.additionalDetails?.map((add, index) => (
                                                                <div className='leable value'><a style={{ cursor: 'pointer', color: 'blue', fontWeight: '500' }} href={add?.website[3]}>{add?.website[3] || 'N/A'}</a></div>
                                                            ))}
                                                        </>
                                                    )}

                                                </>
                                            </div>
                                        </div>
                                    )}

                                    {/* {accountdetials?.additionalDetails.length >= 1 && (
                                        <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                            <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                                <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Dataset <span>:</span></div>
                                            </div>
                                            <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                                <>
                                                    {accountdetials?.additionalDetails?.map((add, index) => (
                                                        <div className='leable value'>{add?.dataset[0] || 'N/A'}</div>
                                                    ))}

                                                    {accountdetials?.additionalDetails[0]?.dataset[1] && (
                                                        <>
                                                            {accountdetials?.additionalDetails?.map((add, index) => (
                                                                <div className='leable value'>{add?.dataset[1] || 'N/A'}</div>
                                                            ))}
                                                        </>
                                                    )}

                                                    {accountdetials?.additionalDetails[0]?.dataset[2] && (
                                                        <>
                                                            {accountdetials?.additionalDetails?.map((add, index) => (
                                                                <div className='leable value'>{add?.dataset[2] || 'N/A'}</div>
                                                            ))}
                                                        </>
                                                    )}
                                                    {accountdetials?.additionalDetails[0]?.dataset[3] && (
                                                        <>
                                                            {accountdetials?.additionalDetails?.map((add, index) => (
                                                                <div className='leable value'>{add?.dataset[3] || 'N/A'}</div>
                                                            ))}
                                                        </>
                                                    )}

                                                </>
                                            </div>
                                        </div>
                                    )} */}
                                </>
                            )}<br />

                            {accountdetials?.notes?.length > 0 && (
                                <div className='h_ing'>
                                    Notes
                                </div>
                            )}

                            {accountdetials?.notes && (
                                <div className='flex' style={{ justifyContent: 'space-between', margin: 'unset', flexWrap: 'wrap', flexDirection: 'column' }}>
                                    <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Id Type <span>:</span></div>
                                    </div>
                                    <div className='info_D' style={{ height: 'auto', overflow: 'auto', display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', padding: '0 20px' }}>
                                        <div className='leable value' style={{ color: 'rgb(143, 143, 143)', fontWeight: '500' }}>{accountdetials?.notes || 'N/A'}</div>
                                    </div><br />
                                </div>
                            )}

                            {!accountdetials && (
                                <div style={{ fontWeight: '600', cursor: 'pointer' }} onClick={() => handleNavigate('/admin/adhoc/individualdata')}>
                                    Go back
                                </div>
                            )}

                        </Card>

                    </>

                )}
            </div >
            <Toast ref={(el) => (toast.current = el)} />
        </>
    )
}

export default CorporateadhocMoreInfo