import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Toast } from "primereact/toast";
import './AccountProfiling.css';
import { useLocation } from 'react-router-dom';

const Account = (props) => {
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [dialogVisible, setDialogVisible] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const location = useLocation();
  // const history = useHistory();
  const toast = useRef(null);
  const accessToken = location?.state?.accessToken;
  const refreshToken = location?.state?.refreshToken;

  useEffect(() => {
    const getBeneficiary = async () => {

      const accountIValue = location?.state?.rowID || sessionStorage?.rowID;
      const uri = `/accounts/${accountIValue}/beneficiaries`;

      axios.get(process.env.REACT_APP_BACKEND_HOST + uri, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then(response => {
          setBeneficiaryData(response?.data);
        })
        .catch(error => {
          if (error?.response && error?.response?.status === 403) {
            window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
            sessionStorage.clear();
          } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
            toast?.current?.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message, life: 5000 });
          }
        });
    };

    getBeneficiary();
  }, [location?.state?.accessToken, location?.state?.accountprofilingid]);

  const statusTemplate = (rowData) => {
    const status = rowData.screeningStatus;
    // let backgroundColor = '';
    let color = '';

    if (status === 'Pending') {
      // backgroundColor = '#f9d9bb';
      color = '#f7af00';
    } else if (status === 'Blocked') {
      // backgroundColor = '#ffcdd2';
      color = '#c63737';
    } else if (status === 'Cleared') {
      // backgroundColor = '#c8e6c9';
      color = 'rgb(69 160 75)';
    } else if (status === 'On-going') {
      // backgroundColor = '#cdd0ff';
      color = '#3746c6';
    }

    return (
      <div
        style={{
          color: color,
          fontWeight: '5006',
        }}
      >
        {status}
      </div>
    );
  };

  const TypeTemplate = (rowData) => {
    const status = rowData.profileType;
    // let backgroundColor = '';
    let color = '';

    if (status === 'Medium Risk') {
      // backgroundColor = '#f9d9bb';
      color = '#f7af00';
    } else if (status === 'High Risk') {
      // backgroundColor = '#ffcdd2';
      color = '#f42616';
    } else if (status === 'Low Risk') {
      // backgroundColor = '#c8e6c9';
      color = 'rgb(69 160 75)';
    }

    return (
      <div
        style={{
          color: color,
          fontWeight: '500',
        }}
      >
        {status}
      </div>
    );
  };

  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="w-full flex text-justify Acount-profile-expander-txt" style={{ fontSize: '14px', padding: '20px 40px' }}>
        <div className="f-Dd">
          <div className='w-full flex m-2'><div className='w-11rem font-semibold'>Account&nbsp;ID</div>  <div className='w-5rem'>:</div> <div className='w-10rem'>{rowData.accountId}</div></div>
          <div className='w-full flex m-2'><div className='w-11rem font-semibold'>Bank&nbsp;Account&nbsp;Number</div> <div className='w-5rem'>:</div> <div className='w-10rem'>{rowData.bankAccountNumber}</div></div>
          <div className='w-full flex m-2'><div className='w-11rem font-semibold'>Name</div> <div className='w-5rem'>:</div> <div className='w-10rem'>{rowData.name}</div></div>
          <div className='w-full flex m-2'><div className='w-11rem font-semibold'>Screening&nbsp;Status</div> <div className='w-5rem'>:</div> <div className='w-10rem'>{rowData.screeningStatus}</div></div>
          <div className='w-full flex m-2'><div className='w-11rem font-semibold'>Branch&nbsp;Name</div> <div className='w-5rem'>:</div> <div className='w-10rem'>{rowData.branchName}</div></div>
          <div className='w-full flex m-2'><div className='w-11rem font-semibold'>Beneficiary&nbsp;Id</div><div className='w-5rem'>:</div> <div className='w-10rem'>{rowData.beneficiaryId}</div></div>
        </div>
        <div className='f-Dd'>
          <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Bank&nbsp;Name</div><div className='w-3rem'>:</div> <div className='w-10rem'>{rowData.bankName}</div></div>
          <div className='w-full flex m-2'><div className='w-8rem font-semibold'>country</div><div className='w-3rem'>:</div> <div className='w-10rem'>{rowData.country}</div></div>
          <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Category</div> <div className='w-3rem'>:</div> <div className='w-10rem'>{rowData.category}</div></div>
          <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Created&nbsp;User</div><div className='w-3rem'>:</div> <div className='w-10rem'>{rowData.createdUser}</div></div>
          <div className='w-full flex m-2'><div className='w-8rem font-semibold'>Created&nbsp;Date</div> <div className='w-3rem'>:</div> <div className='w-10rem'>{rowData.createdTime}</div></div>
        </div>
      </div>
    );
  };
  const onRowToggle = (event) => {
    setExpandedRows(event.data);
  };


  return (
    <div style={{ padding: '20px 0' }} >
      <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
      <DataTable value={beneficiaryData} paginator style={{ whiteSpace: 'nowrap' }} rows={5} rowsPerPageOptions={[5, 10, 15, 20]}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={onRowToggle}
        expandedRows={expandedRows}
        scrollable='true'>
        <Column expander style={{ width: '3rem' }} />
        <Column field='bankAccountNumber' header="Bank Account Number" />
        <Column field='beneficiaryId' header="Beneficiary Id" />
        <Column field="nickName" header="Name" />
        <Column field="branchName" header="Branch Name" />
        <Column field='profileType' header="Profile Type" body={TypeTemplate} />
        <Column field='screeningStatus' header="Screening Status" body={statusTemplate}></Column>
      </DataTable>
    </div>
  );

}
export default Account;