import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './TransationPending.css'
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';

function TransationPending(props) {

    const [tableData, setTableData] = useState();
    const [, setTransationvalue] = useState('');
    const history = useHistory();
    const toast = React.useRef(null);
    const location = useLocation();
    const accessToken = location?.state?.accessToken;

    const displayErrorToast = (message) => {
        if (toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
        }
    };

    // const displayWarningToast = (message) => {
    //     if (toast.current) {
    //         toast.current.show({ severity: 'warn', summary: 'Warning', detail: message, life: 5000 });
    //     }
    // };

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_HOST + '/transactions?status=Pending', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                if (response.data.length === 0) {
                    // displayWarningToast('No Transaction pending accounts found.');
                } else {
                    setTableData(response.data); // Extract the data array from the response
                }
            })
            .catch(error => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    displayErrorToast(errorMessage);
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                }
            });
    }, [accessToken]);

    const moreinfo = (rowData) => {
        const { transactionId, accountId } = rowData;

        const nextpage = () => {
            setTransationvalue(transactionId);
            history.push({
                pathname: '/admin/account/transationdata',
                state: {
                    emailId: location?.state?.emailId,
                    accPemding: location?.state?.accPemding,
                    benPending: location?.state?.benPending,
                    traPending: location?.state?.traPending,
                    transactionId: transactionId,
                    accountId: accountId,
                    accessToken:accessToken,
                    getUserData: location?.state?.getUserData
                },
            })
        };

        return (
            <React.Fragment>
                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-secondary p-mr-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={nextpage} />
            </React.Fragment>
        );
    };
    const profileTemplate = (rowData) => {
        const status = rowData.status;

        let color = '';

        if (status === 'Pending') {
            color = '#f7af00';
        } else if (status === 'Blocked') {
            color = '#f42616';
        } else if (status === 'Cleared') {
            color = 'rgb(69 160 75)';
        } else if (status === 'On-going') {
            color = '#f7af00';
        }

        return (
            <div
                style={{

                    color: color,
                    fontWeight: '500',
                }}
            >
                {status}
            </div>
        );
    };

    const transvalue = props?.data?.traPending;

    return (
        <>

            <Toast ref={toast} className='transaction-toast' />
            <div className="feild" style={{ padding: 'unset' }}>
                <div className="input">
                    <div className='head head_T'>Transaction Pending   :<span className='pend-count-styl'>{transvalue || '0'}</span></div>
                    <DataTable
                        value={tableData}
                        scrollable
                        stripedRows
                        showGridlines
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        style={{ whiteSpace: 'nowrap' }}
                        scrollHeight="80vh"
                    >
                        <Column field="transactionId" header="Transaction Id"></Column>
                        <Column field="accountId" header="Account Id"></Column>
                        <Column field="status" header="Status" body={profileTemplate}></Column>
                        <Column body={moreinfo} style={{ width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }} header="More Info"></Column>
                    </DataTable>
                </div>
            </div>

        </>
    )
}

export default TransationPending