import React, { useState, useRef, useEffect } from "react";
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import Logo_web from '../../assets/acaia_logo/acaia_dark_logo.svg';
import Logo_mob from '../../assets/acaia_logo/acaia_white_logo.svg';
import { InputText } from 'primereact/inputtext';
import 'react-phone-input-2/lib/style.css';
import { Toast } from "primereact/toast";
import { Checkbox } from 'primereact/checkbox';
import signupimage from '../../Landing Page/media/banner-image.png';
import { Dialog } from 'primereact/dialog';
import OTPInput from "react-otp-input";
import { Triangle } from 'react-loader-spinner';
import axios from 'axios';
import './Signup.css';

function SignUp() {

    const history = useHistory();
    const toast = useRef(null);
    const [checked, setChecked] = useState(false);
    // const [value, setValue] = useState();
    const [visible, setVisible] = useState(false);
    const [openPop, setOpenPop] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    // const [isCheck, setIsCheck] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordEr, setPasswordEr] = useState('');
    const [confirmpasswordEr, setConfirmPasswordEr] = useState('');
    const [mailEr, setMailEr] = useState(false);
    const [verify, setVerify] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [countdown, setCountdown] = useState(600);
    const intervalRef = useRef();
    // const [phOTP, setPhOTP] = useState();
    const [eOTP, setEOTP] = useState();
    let emailRef = useRef('');
    let passRef = useRef('');
    let conpassRef = useRef('');

    useEffect(() => {
        console.clear();
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        intervalRef.current = setInterval(() => {
            setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(intervalRef.current);
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // useEffect(() => {
    //     document.addEventListener('contextmenu', (event) => {
    //         event.preventDefault();
    //     });

    //     document.addEventListener('keydown', (event) => {
    //         if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I') || (event.ctrlKey && event.shiftKey && event.key === 'C')) {
    //             event.preventDefault();
    //         }
    //     });
    // }, []);
    // const displayErrorToast = (message) => {
    //     toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
    // };

    // const displaySuccessToast = (message) => {
    //     toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 5000 });
    // };


    // const isValidEmailDomain = (email) => {
    //     const emailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    //     return emailRegex.test(email);
    // };


    const isValidEmail = (email) => {
        const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7f]|\\[\x21-\x7f])*")@(?:(?!gmail\.com)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7f]|\\[\x21-\x7f])+)\])$/;
        // const emailFormatRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(email)) {
            return false;
        }

        const domain = email.split('@')[1].toLowerCase();
        const hasDotCom = domain.endsWith('.com');
        const isDomainLengthValid = domain.length >= 8 && domain.length <= 254;
        return hasDotCom && isDomainLengthValid && domain !== 'gmail.com';
    };


    const handleEmailChange = (event) => {
        const emailValue = event.target.value;
        setUserEmail(emailValue);

        if (!emailValue.trim()) {
            setMailEr('Email is required.');
        } else if (!isValidEmail(emailValue)) {
            setMailEr('Domain, length - Invalid email');
        } else {
            setMailEr('');
        }
    };

    const handlePasswordChange = (event) => {
        const passwordValue = event.target.value;
        setPassword(passwordValue);

        if (!passwordValue.trim()) {
            setPasswordEr('Password is required.');
        } else {
            let errors = [];

            if (!/[A-Z]/.test(passwordValue)) {
                errors.push('Must have at least one uppercase.');
            }
            else if (!/[a-z]/.test(passwordValue)) {
                errors.push('Must have at least one lowercase.');
            }

            else if (!/\d/.test(passwordValue)) {
                errors.push('Must have at least one number.');
            }

            else if (!/[!@#$%^&*()?]/.test(passwordValue)) {
                errors.push('Must have at least one special character');
            }

            else if (passwordValue.length < 8 || passwordValue.length > 20) {
                errors.push('Must have at least 5 to 20 characters.');
            }

            setPasswordEr(errors.join(' '));

            if (errors.length === 0) {
                setPasswordEr('');
            }
        }
    };

    const handleConfirmPassword = (event) => {

        const passwordValue = event.target.value;

        if (!passwordValue.trim()) {
            setConfirmPasswordEr('Confirm Password is required.');
        }

        if (password === event.target.value) {
            setConfirmPasswordEr('');
        } else {
            setConfirmPasswordEr('Password mismatch.');
        }
        setConfirmPassword(passwordValue);

    }

    const handleClosePop = () => {
        setOtp('')
        setOpenPop(false);
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const emailVerify = () => {
        setLoading(true);
        if (userEmail) {
            setCountdown(600);
            const payload = {
                emailId: userEmail,
            };
            axios.post(process.env.REACT_APP_BACKEND_HOST + `/send-email-otp`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    setLoading(false);
                    toast.current.show({ severity: 'success', summary: response?.data?.message || "Success" });
                    setTimeout(() => {
                        setOpenPop(true);
                    }, 1000);
                })
                .catch(error => {
                    setLoading(false);
                    toast.current.show({ severity: 'error', summary: error?.response?.data?.message || "Error Occured" });
                });
        } else {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: "Email was not recognized" });
        }
    }

    const resendOTP = async () => {
        setOtp('');
        setLoading(true);
        // setCountdown(600);
        emailVerify();
    };

    const otpVerify = (data) => {
        setLoading(true);
        const emailPayload = {
            email: userEmail,
            enteredOTP: otp
        };

        setEOTP(otp);
        const emailUrl = `/verify-email-otp`;

        axios.post(process.env.REACT_APP_BACKEND_HOST + emailUrl, emailPayload, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                toast.current.show({ severity: 'success', summary: response?.data?.message || "Success" });
                setOtp('');
                setOpenPop(false);
                setLoading(false);
                setVerify(true);
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: error?.response?.data?.message || "Error Occured." });
                setOtp('');
                setLoading(false);
                setVerify(false);
            });

    }

    const email_PhonenumberVerify = () => {
        setLoading(true);

        const payload = {
            emailId: userEmail,
            password: password,
            confirmPassword: confirmPassword,
            systemName: 'ACAIA',
        };

        axios.post(process.env.REACT_APP_BACKEND_HOST + `/signup?enteredOTP=${eOTP}`, payload, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                setLoading(false);
                toast.current.show({ severity: 'success', summary: response?.data?.message || 'Success' });
                setTimeout(() => {
                    history.push({
                        pathname: '/payment',
                        state: {
                            emailId: payload.emailId,
                            password: payload.password,
                            type: 'sign_Up'
                        },
                    });
                }, 1000);
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message || "Error Occured." });
            });

    }

    return (
        <>


            {loading &&

                <div className="spin-wrapper">
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            }


            <div className="sing_up_main">
                <div style={{ width: '-webkit-fill-available', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <div className="sing_up_main_c">
                        <div className="l_side">
                            <div className='logo_f' style={{ padding: ' 0 10px', position: 'relative', top: '-125px' }}>
                                <img src={Logo_web} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                            </div>
                            <div className="cen">
                                <img src={signupimage} className="l_side_D" style={{ width: '20rem' }} alt="signup-content" />
                            </div>
                        </div>
                        <div className="r_side">

                            <div className="feild" style={{ width: (windowWidth < 1000 ? '100%' : '90%'), margin: 'auto', gap: (windowWidth < 1230 ? '10px' : '20px') }}>
                                <div className='logo_f web_hide' style={{ padding: ' 0 10px' }}>
                                    <img src={Logo_mob} onClick={() => history.push('/')} style={{ width: '130px', height: 'max-content', cursor: 'pointer' }} alt="Acaia-content" />
                                </div>
                                <div className="flex" style={{ flexDirection: 'column', gap: '20px' }}>
                                    <div className="main_heading">Welcome to Acaia!</div>
                                    <div className="sub_heading" style={{ fontWeight: 'unset', display: 'block' }}> An AML compliance solutions with improved data quality for more accurate results. </div>
                                </div>

                                <div className="flex">
                                    <div className="input">
                                        <div className="label">Business Email<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                        <div className="send_OTP">
                                            <InputText defaultValue={emailRef.current} onChange={handleEmailChange} style={{ border: '1px solid rgb(211, 215, 225)', borderRadius: '3px 0px 0px 3px' }} className={`w-full ${mailEr ? "p-invalid" : ""}`} />
                                            <Button className='unique-email-button' onClick={emailVerify} disabled={mailEr || !userEmail} label="OTP" style={{ height: windowWidth <= 575 ? '35px' : '40px' }} />
                                        </div>
                                        {mailEr && <small className="p-error">{mailEr}</small>}
                                        {userEmail && password && !verify && <small className="errors-messages text-red-500" style={{ position: 'relative', float: 'right', right: '0' }}>verify {verify}</small>}
                                    </div>
                                </div>

                                <div className="flex" style={{ flexDirection: (windowWidth < 1230 ? 'column' : 'row'), gap: (windowWidth < 1230 ? '10px' : '20px') }}>
                                    <div className="input">
                                        <div className="label">Password<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                        <div className={`w-full see_Pass ${passwordEr ? "unique_see_Pass" : ""}`} style={{ borderRadius: '3px' }}>
                                            <InputText disabled={!verify} type={showPassword ? 'text' : 'password'} defaultValue={passRef.current} onChange={handlePasswordChange} style={{ border: '1px solid rgb(211, 215, 225)', borderRadius: '3px 0px 0px 3px', borderRight: passwordEr ? '1px solid red' : '1px solid #CACACA' }} className='w-full' />
                                            <i onClick={(e) => setShowPassword(!showPassword)} class={`pi ${showPassword ? 'pi-eye-slash' : `pi-eye`}`} ></i>
                                        </div>
                                        {passwordEr && <small className="p-error a">{passwordEr}</small>}
                                    </div>
                                    <div className="input">
                                        <div className="label">Confirm Password<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                        <div className={`w-full see_Pass ${passwordEr ? "unique_see_Pass" : ""}`} style={{ borderRadius: '3px' }}>
                                            <InputText disabled={!verify} type={showConfirmPassword ? 'text' : 'password'} defaultValue={conpassRef.current} onChange={(e) => handleConfirmPassword(e)} style={{ border: '1px solid rgb(211, 215, 225)', borderRadius: '3px 0px 0px 3px', borderRight: confirmpasswordEr ? '1px solid red' : '1px solid #CACACA' }} className='w-full' />
                                            <i onClick={(e) => setShowConfirmPassword(!showConfirmPassword)} class={`pi ${showConfirmPassword ? 'pi-eye-slash' : `pi-eye`}`} style={{ border: confirmpasswordEr ? '1px solid red' : '1px solid #CACACA' }}></i>
                                        </div>
                                        {confirmpasswordEr && <small className="p-error a">{confirmpasswordEr}</small>}
                                    </div>
                                </div>

                                <div className="flex_footer_btn" style={{ width: '100%', flexDirection: 'unset' }}>

                                    <div className="flex_footer_btn_sub">
                                        <div className="f_L_Btn">
                                            <Checkbox onChange={e => setChecked(e.checked)} checked={checked}
                                                disabled={!password || !confirmPassword || !userEmail}
                                            />
                                            <div className="terms-condition" style={{ cursor: 'pointer' }} onClick={() => setVisible(true)}> Terms & Conditions<span style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>*</span></div>
                                        </div>
                                        <div className="f_L_Btn" style={{ display: (windowWidth < 1000 ? 'none' : 'block') }}>
                                            <div className="terms-condition" style={{ width: 'fit-content', cursor: 'pointer', gap: '5px', flexWrap: 'wrap' }} onClick={() => history.push('/login')}> Already Have An Account?<span style={{ color: '#4580c2', fontWeight: 'bold', fontSize: '14px' }}>Login</span></div>
                                        </div>
                                    </div>

                                    <Button disabled={
                                        !checked || !userEmail || !password || !confirmPassword
                                        || mailEr || passwordEr || confirmpasswordEr || !verify}
                                        className="footer_Sub_Btn" onClick={email_PhonenumberVerify}>Signup</Button>
                                </div>

                                <div className="f_L_Btn web_hide">
                                    <div className="terms-condition" style={{ width: 'fit-content', cursor: 'pointer', gap: '5px', flexWrap: 'wrap' }} onClick={() => history.push('/login')}> Already Have An Account?<span style={{ color: '#4580c2', fontWeight: 'bold', fontSize: '14px' }}>Login</span></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Dialog header="Term of services" visible={visible} className="sing_up_main_Dialog" style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                    <div style={{ height: '45vh', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                        <p className="text-sm terms-head">Please read this terms of service carefully. By accessing or using our products or services linked to these terms, you agree to be bound by these terms and all terms incorporated by reference. </p>
                        <p className="text-sm">
                            <p>Users participating in the ACAIA project must strictly adhere to all relevant anti-money laundering laws and regulations governing their jurisdiction. The ACAIA project requires users to undergo comprehensive KYC procedures, providing accurate and up-to-date information to verify their identity and prevent illicit activities. The ACAIA project maintains a strict zero-tolerance policy towards any involvement in money laundering activities, and violators will face legal consequences and immediate termination of participation.</p>
                            <p>Users a report any transactions or behaviors that raise suspicions of money laundering to the designated authorities and the ACAIA project administrators. The ACAIA project reserves the right to conduct risk assessments on users with factors such as transaction volume, frequency, and patterns. Users are prohibited from engaging in transactions that violate international sanctions or involve individuals/entities on sanctioned lists. The ACAIA project may implement EDD measures for certain high-risk transactions or users, requiring additional scrutiny and verification.</p>
                            <p>The ACAIA project reserves the right to update or modify these terms and conditions to reflect changes in AML regulations or project requirements. User must maintain the confidentiality of any sensitive information obtained during the course of the ACAIA project, in accordance with data protection laws. Participants are required to maintain accurate and complete records of transactions and relevant documentation for a specified period, as stipulated by the ACAIA project.</p>
                            <p>Acaia Software is not responsible for third-party services. Use caution and review the terms of use. Elements of the services are protected by intellectual property laws so, no joint venture or assignment without approval. Acaia is not liable for indirect damages. Aggregate liability is capped. Consult full terms for details. </p>
                        </p>
                    </div>
                </Dialog>

                <Dialog header="OTP Verification" visible={openPop} className="sing_up_main_Dialog" onHide={handleClosePop}>
                    <div style={{ height: 'auto', overflow: 'auto', padding: '25px', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                        <div className="feild" style={{ padding: 'unset' }}>
                            <div className="verificationOTP" style={{ fontSize: (windowWidth < 575 ? '16px' : '18px'), }}>Enter the OTP sent to your email.</div>
                            <div style={{ fontSize: (windowWidth < 575 ? '12px' : '14px'), textAlign: 'center', height: 'auto', color: '#bdbdbd' }}>OTP expires in <span style={{ color: '#0072b4' }}>{formatTime(countdown)}</span> minutes</div>

                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                isInputNum={true}
                                separator={<span className="p-2"></span>}
                                containerStyle="otp-container"
                                inputStyle="otp-input"
                                inputType='number'
                                renderInput={(props, index) => (
                                    <input {...props} className="otp-input-field text-center no_select" key={index} />
                                )}
                            />

                            <div className="flex_footer_btn otp_footer">
                                <Button className="footer_Sub_Btn" style={{ width: (windowWidth < 500 ? '-webkit-fill-available' : 'fit-content'), background: 'none', border: 'none', color: '#124A99', fontWeight: 'bold' }} onClick={resendOTP}><u>Resend OTP</u></Button>
                                <Button disabled={!otp || otp.length !== 6} className="footer_Sub_Btn" onClick={otpVerify}>Verify</Button>
                            </div>

                            {loading &&
                                <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                                    <Triangle
                                        height="80"
                                        width="80"
                                        color="#124A99"
                                        ariaLabel="triangle-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                </Dialog>

                <Toast ref={(el) => (toast.current = el)} />

            </div >
        </>
    );
}

export default SignUp;