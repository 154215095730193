import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Toast } from 'primereact/toast';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Triangle } from 'react-loader-spinner';
import { Card } from 'primereact/card';
import { useLocation } from 'react-router-dom';

function BendingDataMoreInfo(props) {

    const toast = React.useRef(null);
    const history = useHistory()
    const [notForData, setNotForData] = useState(false);
    const [accountdetials, setAccountDetials] = useState('');
    const [loading, setLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();
    const nextClientId = location?.state?.sanctionRelationshipId;
    const nextClientId1 = location?.state?.account;
    const accessToken = location?.state?.accessToken;

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/get/sanction/moreInfo?sanctionId=' + nextClientId + '&account=' + nextClientId1 , {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then(response => {
                const accountDetailsData = response.data;
                setAccountDetials(accountDetailsData);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                setNotForData(true);
                setAccountDetials('');
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    const errorMessage = error?.response?.data?.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage, life: 5000 });
                } else if (error?.response && error?.response?.status === 403) {
                    window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
                    sessionStorage.clear();
                } else if (error?.response?.status === 401) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
                } else if (error?.response?.status === 404) {
                    toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'Not Found.', life: 3000 });
                }
            });
    }, [nextClientId,nextClientId1,accessToken]);

    // const isEnglishName = (name) => {
    //     const englishCharRegex = /^[A-Za-z\s]+$/;
    //     return englishCharRegex.test(name);
    // };
    const items = [
        {
            label: 'RISK CHECK', command: () =>
                history.push({
                    pathname: '/admin/account/pending',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })
        },
        {
            label: 'Pending List', command: () =>
                history.push({
                    pathname: '/admin/account/pending',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })
        },
        {
            label: 'Beneficiary Details', command: () =>
                history.push({
                    pathname: '/admin/account/benificary',
                    state: {
                        emailId: location?.state?.emailId,
                        accPemding: location?.state?.accPemding,
                        benPending: location?.state?.benPending,
                        traPending: location?.state?.traPending,
                        clientId: location?.state?.clientId,
                        accessToken: accessToken,
                        getUserData: location?.state?.getUserData
                    },
                })
        },
        { label: 'Beneficiary More Info' },
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }
    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    return (
        <>

            <div className="mainSec" >
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                <Toast ref={toast} style={{ marginTop: '50px' }} />

                {loading ? (
                    <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <Card className='carD' style={{ height: (windowWidth < 500 ? 'auto' : '') }}>
                            {notForData && !accountdetials && (
                                <div className='head' style={{ width: 'fit-content', cursor: 'pointer' }} onClick={() => window.history.back()}>
                                    Don't have data's
                                </div>
                            )}

                            {accountdetials && (
                                <div className='h_ing'>
                                    Account Details
                                </div>
                            )}

                            {accountdetials?.sanctionIndividual?.fullName && (
                                <div className='info_D' style={{ height: (windowWidth <= 500 && accountdetials?.sanctionIndividual?.fullName.length > 20 ? 'auto' : '50px'), justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Full Name <span>:</span></div>
                                    <div className='value'>{accountdetials?.sanctionIndividual?.fullName || 'N/A'}</div>
                                </div>
                            )}

                            {accountdetials?.sanctionIndividual?.id && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>ID <span>:</span></div>
                                    <div className='value'>{accountdetials?.sanctionIndividual?.id || 'N/A'}</div>
                                </div>
                            )}

                            {accountdetials?.sanctionIndividual?.matchPoints && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Match Points <span>:</span></div>
                                    <div className='value'>{accountdetials?.sanctionIndividual?.matchPoints || 'N/A'}</div>
                                </div>
                            )}

                            {accountdetials?.screeningStatus && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Screening Status <span>:</span></div>
                                    <div className='value' style={{
                                        color: '#7f7878'||
                                            accountdetials.screeningStatus === 'Pending'
                                                ? '#f7af00'
                                                : accountdetials.screeningStatus === 'Cleared'
                                                    ? 'rgb(69 160 75)'
                                                    : accountdetials.screeningStatus === 'Blocked'
                                                        ? '#f42616'
                                                        : '#f7af00', fontWeight: 'bold'
                                    }}>{accountdetials?.screeningStatus || 'N/A'}</div>
                                </div>
                            )}

                            {accountdetials?.sanctionRelationshipId && (
                                <div className='info_D' style={{ height: '50px', justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Relationship Id <span>:</span></div>
                                    <div className='value'>{accountdetials?.sanctionRelationshipId || 'N/A'}</div>
                                </div>
                            )}

                            {accountdetials?.comments && (
                                <div className='info_D' style={{ height: (windowWidth <= 500 && accountdetials?.comments.length > 20 ? 'auto' : '50px'), justifyContent: 'flex-start', gap: '20px', alignItems: 'center', flexDirection: 'row' }}>
                                    <div className='leable' style={{ minWidth: '130px', display: 'flex', justifyContent: 'space-between' }}>Comments <span>:</span></div>
                                    <div className='value'>{accountdetials?.comments || 'N/A'}</div>
                                </div>
                            )}

                            <br />
                            <br />
                            <br />
                        </Card>
                        <br />
                        <br />
                        <br />
                    </>
                )}
            </div>
        </>
    )
}

export default BendingDataMoreInfo