import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Chatbot.css'
import { IoMdSend } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { TfiHeadphoneAlt } from "react-icons/tfi";

const ChatBot = () => {
  const [messages, setMessages] = useState([
    { text: 'Hello! How can I help you?', isUser: false },
  ]);
  const [inputValue, setInputValue] = useState('');
  const [isPopup, setPopup] = useState(false);
  const chatAreaRef = useRef(null);

  const [suggestedQuestions] = useState(['Why AML?', 'Watchfulness', 'AML software?']);


  const handleUserMessage = (text) => {
    const userMessage = { text, isUser: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setTimeout(() => {
      let botResponse;
      switch (text.toLowerCase()) {
        case 'how are you?':
          botResponse = { text: 'I am just a bot, but thanks for asking!', isUser: false };
          break;
        case 'what is your purpose?':
          botResponse = { text: 'I am here to assist you with any questions you have.', isUser: false };
          break;
        case 'tell me a joke':
          botResponse = { text: 'Why did the computer go to therapy? Because it had too many bytes of emotional baggage!', isUser: false };
          break;
        case 'why aml?':
          botResponse = { text: 'To identifying and managing the risks associated with money laundering and financial crimes.', isUser: false };
          break;
        case 'watchfulness':
          botResponse = { text: 'To detect patterns indicative of suspicious activity', isUser: false };
          break;
        case 'aml software?':
          botResponse = { text: 'AML software is a tool that helps financial institutions detect and prevent money laundering activities by monitoring transactions', isUser: false };
          break;
        default:
          botResponse = { text: 'I am a simple bot. Ask me anything!', isUser: false };
          break;
      }

      setMessages((prevMessages) => [...prevMessages, botResponse]);
    }, 500);
  };

  const handleSuggestedQuestion = (question) => {
    setTimeout(() => {
      const lowercaseQuestion = question.toLowerCase();
      handleUserMessage(lowercaseQuestion);
    }, 500);
  };

  const handleSendMessage = () => {
    setTimeout(() => {
      if (inputValue.trim() !== '') {
        handleUserMessage(inputValue);
        setInputValue('');
      }
    }, 500);
  };

  useEffect(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [messages]);



  return (
    <div
      className={isPopup ? 'chatbot-1' : 'chatbot shadow-1'}
      id='chatbot-shadow'
    // className="chatbot shadow-1"
    >
      <div className='relative flex align-items-center justify-content-center px-3 chatbot-head'>
        <div className='flex  align-items-center justify-content-between chatbot-head-a1 w-full'>
          <p className='ml-2 font-bold'>Help Center</p>
          <div className='chatbot-close'><i className='pi pi-times font-bold cursor-pointer' sty onClick={() => setPopup(!isPopup)} /></div>
        </div>
      </div>
      <div className="chat-are px-3 py-2" ref={chatAreaRef}>
        <div className="chatbot-messages">
          {messages.map((message, index) => (
            <div key={index} >
              {message.isUser ? (
                <div className='flex'>
                  <p className={message.isUser ? 'user-message' : 'bot-message'}>{message.text}</p>
                  <div className='text-right mx-2 h-max user-pb'><FaUserAlt size={13} className='user-pb-icon' /></div>
                </div>
              ) : (
                <div className='flex'>
                  <div className='text-right mx-2 h-max user-pb1'><FaUserAlt size={13} className='user-pb-icon1' /></div>
                  <p className={message.isUser ? 'user-message' : 'bot-message'}>{message.text}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="suggested-questions">
        {suggestedQuestions.map((question, index) => (
          <button key={index} onClick={() => handleSuggestedQuestion(question)} className="suggested-question-btn mx-2 py-2 px-2 cursor-pointer">
            {question}
          </button>
        ))}
      </div>


      <div className="chatbot-input">
        <input
          type="text"
          placeholder="Type a message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
        />
        <button
          className='chatbot-btn'
          onClick={handleSendMessage}
        >
          <IoMdSend size={22} className='i-con' />
        </button>
      </div>
    </div>
  );
};
const ChatBotPopup = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const chatbotContainerRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (isPopupVisible && chatbotContainerRef.current && !chatbotContainerRef.current.contains(event.target)) {
      setPopupVisibility(false);
    }
  }, [isPopupVisible]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div>
      <div style={{ position: 'fixed', bottom: '12%', right: '3%' }} ref={chatbotContainerRef}>
        {isPopupVisible && <ChatBot />}
      </div>
      <div>
        <button onClick={() => setPopupVisibility(!isPopupVisible)} className='m-2 border-transparent shadow-1 headeset-btn'><TfiHeadphoneAlt size={26} /></button>
      </div>
    </div>
  );
};

export default ChatBotPopup;