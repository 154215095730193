import axios from 'axios';

class ProductService {

    getDropdownValue( accessToken) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let apiUri = `/country/get`;

        return axios.get(process.env.REACT_APP_BACKEND_HOST + apiUri, requestOptions).then(res => res.data);
    }

    getProducts( accessToken) {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let uri = `/country/company`;

        return axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions).then(res => res.data);
    }

    // updateProduct(_product, accessToken, emailId) {
    //     const email = emailId;

    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + accessToken
    //     };

    //     let uri = "/countries/" + _product.id + `?emailId=${email}`;

    //     return axios.put(process.env.REACT_APP_BACKEND_HOST + uri, _product, {
    //         headers: headers
    //     }).then(res => res.data);
    // }

    // getPassword(accessToken) {

    //     const requestOptions = {
    //         method: 'GET',
    //         headers: { 'Content-Type': 'application/json' ,
    //             'Authorization': 'Bearer '+accessToken
    //     };

    //     let uri = "users/reset_password/";

    //     return  axios.get(process.env.REACT_APP_IDM_BACKEND_HOST+uri, requestOptions).then(res=>res.data);
    // }
}

export default ProductService