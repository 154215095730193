import React, { useEffect, useState } from 'react';
import './PendingverificationForm.css';
import BeneficiaryPending from '../BeneficiaryPeding/BeneficiaryPending';
import IndividualPending from '../IndividualPending/IndividualPending';
import TransationPending from '../TransationPending/TransationPending';
// import axios from 'axios';
import { Triangle } from 'react-loader-spinner';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function PendingList({roleName}) {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const handleNavigate = (url) => {
    //     history.push(url);
    // };

    useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    // const email = location?.state?.emailId;

    useEffect(() => {
        // fetchData();
        // const intervalId = setInterval(fetchData, 50000);
        // return () => clearInterval(intervalId);
    }, []);

    const items = [
        { label: 'Risk Check' },
        { label: 'Pending List' }
    ];

    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    return (
        <>
            <div className='mainSec'>
                <BreadCrumb model={items} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />

                {loading ? (
                    <div class="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                        <Triangle
                            height="80"
                            width="80"
                            color="#124A99"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <>
                        <div className="pending_List height-pending-vh">
                            <BeneficiaryPending data={location?.state} />
                            <div className='sec' style={{ width: '100%', display: 'flex', gap: '20px' }}>
                                <div style={{ width: windowWidth <= 1251 ? '100%' : '50%' }}><IndividualPending data={location?.state} roleName={roleName}/></div>
                                <div style={{ width: windowWidth <= 1251 ? '100%' : '50%' }}><TransationPending data={location?.state} roleName={roleName} /></div>
                            </div>
                        </div>
                    </>
                )}
            </div>

        </>
    )
}

export default PendingList
