import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import Sidebars from '../Components/Sidebar/Sidebar';
import DashboardMain from '../Components/Cards/DashboardMain/DashboardMain';
import Branches from '../Components/Cards/Branches/Branches';
import CompanyProfile from '../Components/Cards/CompanyProfile/CompanyProfile';
import Role from '../Components/Cards/Roles/Roles';
import Users from '../Components/Cards/Users/Users';
import Designation from '../Components/Cards/Designation/DesignationTable';
import UserProfile from '../Components/Navbar/UserProfile/Userprofile';
import GenaralInformation from '../Components/Navbar/UserProfile/GenaralInformation';
import Country from '../Components/Cards/Country/Country';
import Header from '../Components/Header/Header'
import HistoryAudit from '../Components/Cards/History/HistoryAudit';
import AdhocAdvancedsearch from '../Components/Forms/sanction/AdhocSearch/AdhocAdvancedsearch/AdhocAdvancedsearch';
import Registeration from '../Components/Forms/sanction/Account resistration/Registeration/Registeration';
import Accountsearch from '../Components/Forms/sanction/AccountSearch/Accountsearch/Accountsearch';
import PendingList from '../Components/Cards/PendingList/PendingList';
import BendingDataPage from '../Components/Cards/BeneficiaryPeding/BendingDataPage';
import BendingDataMoreInfo from '../Components/Cards/BeneficiaryPeding/BendingDataMoreInfo';
import TransationPendingData from '../Components/Cards/TransationPending/TransationPendingData';
import IndividualDataPending from '../Components/Cards/IndividualPending/IndividualDataPending';
import IndividualPendingMoreInfo from '../Components/Cards/IndividualPending/IndividualPendingMoreInfo';
import IndividualAccountProfilingMoreInfo from '../views/Accountprofiling/IndividualProfiling/IndividualAccountProfilingMoreInfo';
import CorporateAccountProfilingMoreInfo from '../views/Accountprofiling/CorporateProfiling/CorporateAccountProfilingMoreInfo';
import Individualsearchdata from '../Components/Forms/sanction/AdhocSearch/Individualsearchdata/Individualsearchdata';
import AdhocSearchMoreinfo from '../Components/Forms/sanction/AdhocSearch/AdhocSearchMoreinfo';
import CorporateadhocMoreInfo from '../Components/Forms/sanction/AdhocSearch/CorporateadhocMoreInfo/CorporateadhocMoreInfo';
import AdvanceIndividualSearch from '../Components/Forms/sanction/AdhocSearch/AdvanceIndividualSerach/AdvanceIndividualSearch';
import AdvanceIndividualMoreinfo from '../Components/Forms/sanction/AdhocSearch/AdvanceIndividualSerach/AdvanceIndividualMoreinfo';
import CorporateAdvanceTable from '../Components/Forms/sanction/AdhocSearch/AdvanceCorporateSearch/CorporateAdvanceTable';
import CorporateAdvanceMoreInfo from '../Components/Forms/sanction/AdhocSearch/AdvanceCorporateSearch/CorporateAdvanceMoreInfo';
import SansactionMainpage from '../Components/Cards/Sansaction/SansactionMainpage';
import MailPage from '../Components/Cards/TransationPending/Suspicious';
import RiskClarification from '../views/RiskClarification/RiskClarification';
import NotificationPreferences from '../views/NotificationPreferences/NotificationPreferences';
import AccountProfiling from '../views/Accountprofiling/Accountprofiling';
import AccountProfilings from '../views/Accountprofiling/IndividualProfiling/Accountprofilings';
import Account from '../views/Accountprofiling/Beneficiary';
import Accounts from '../views/Accountprofiling/IndividualProfiling/Beneficiary';
import Beneficiarye from '../views/Accountprofiling/Beneficiary';
import Beneficiaryes from '../views/Accountprofiling/IndividualProfiling/Beneficiary';
import Transactionv from '../views/Accountprofiling/Transaction';
import Transactionvs from '../views/Accountprofiling/IndividualProfiling/Transaction';
import Historyvs from '../views/Accountprofiling/IndividualProfiling/History';
import Historyv from '../views/Accountprofiling/History';
import CorporateProfiling from '../views/Accountprofiling/CorporateProfiling/CorporateProfile';
import ViewBeneficiary from '../views/Accountprofiling/CorporateProfiling/ViewBeneficiary';
import ViewHistory from '../views/Accountprofiling/CorporateProfiling/viewHistory';
import ViewTransaction from '../views/Accountprofiling/CorporateProfiling/viewTransaction';
import Transaction from '../Components/Cards/Transaction/Transaction';
import MonitoringThresholds from '../views/transaction/MonitoringThresholdsListTable';
import Beneficiary from '../Components/Cards/Beneficiary/Beneficiary';
import IndividualForm from '../Components/Cards/Sansaction/IndividualFile';
import CorporateForm from '../Components/Cards/Sansaction/CorporateFile';
import CorporateInformation from '../views/Accountprofiling/CorporateInformation';
import ChatBotPopup from '../Chatbot/chatbot';
import UserActivity from '../Components/Cards/Branches/UserActivity';
import UserHistoryActivity from '../Components/Cards/History/UserHistoryActivity';
import IndividualRiskreason from '../views/Accountprofiling/IndividualProfiling/RiskReason';
import CorpoRiskreason from '../views/Accountprofiling/CorporateProfiling/RiskReason';
import WebSocketComponent from '../WebSocketComponent';
import idmaxios from '../axios/idmaxios';
import './Admin.css';
import UserRegisteration from '../Components/Cards/Users/UserRegisteration';
import Remainingtime from '../Remaining/Remaining';

const Admin = (props) => {
    const location = useLocation();
    const accessToken = location?.state?.accessToken;

    // useEffect(() => {
    //     document.addEventListener('contextmenu', (event) => {
    //         event.preventDefault();
    //     });

    //     document.addEventListener('keydown', (event) => {
    //         if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I') || (event.ctrlKey && event.shiftKey && event.key === 'C')) {
    //             event.preventDefault();
    //         }
    //     });
    // }, []);
    const [roleName, setRoleName] = useState("");
    const [companyid, setCompanyid] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await idmaxios.get(`token_info/` + accessToken);
                setRoleName(response?.data?.roles[0]?.roleName);
                setCompanyid(response?.data?.apiKeyId);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        setInterval(clearConsoleLog, 1000);
    }, [accessToken]);

    function clearConsoleLog() {
        console.log = (args) => {
            if (args?.startsWith('Warning: Invalid DOM property `maxlength`. Did you mean `maxLength`?')) {
                return;
            }
        };

        console.error = (message) => {
            if (message?.startsWith('Warning: Invalid DOM property `maxlength`. Did you mean `maxLength`?' || '')) {
                return;
            }
        };
    }

    return (
        <>
            <div className='w-max chat-bot' style={{ position: 'fixed', bottom: '3%', right: '-1.2%', zIndex: '99999' }}>
                <ChatBotPopup />
            </div>
            <div className='acaia-body' >
                <Router>
                    <WebSocketComponent />
                    <Sidebars />
                    <Header />

                    <div className='menuItem'>
                        {['BasicAdmin', 'ProAdmin', 'Super admin', 'FreeTrial'].includes(roleName) && (
                            <div className='main-header-remaining'>
                                <Remainingtime />
                            </div>
                        )}

                        <Switch>
                            <Route path="/admin/dashboard" exact render={(props) => <DashboardMain {...props} roleName={roleName} />} />
                            <Route path="/admin/company" exact render={(props) => <CompanyProfile {...props} roleName={roleName} />} />
                            <Route path="/admin/branches" exact render={(props) => <Branches {...props} roleName={roleName} />} />
                            <Route path="/admin/users" exact render={(props) => <Users {...props} roleName={roleName} />} />
                            <Route path="/admin/userregistration" exact render={(props) => <UserRegisteration {...props} roleName={roleName} />} />
                            <Route path="/admin/roles" exact render={(props) => <Role {...props} roleName={roleName} />} />
                            <Route path="/admin/designation" exact render={(props) => <Designation {...props} roleName={roleName} />} />
                            <Route path="/admin/userprofile" exact render={(props) => <UserProfile {...props} roleName={roleName} />} />
                            <Route path="/admin/generalinformation" exact render={(props) => <GenaralInformation {...props} roleName={roleName} />} />
                            <Route path="/admin/country" exact render={(props) => <Country {...props} roleName={roleName} />} />
                            <Route path="/admin/history" exact render={(props) => <HistoryAudit {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/search' exact render={(props) => <AdhocAdvancedsearch {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/individualdata' exact render={(props) => <Individualsearchdata {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/individualdatamoreinfo' exact render={(props) => <AdhocSearchMoreinfo {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/corporateadhocmoreinfo' exact render={(props) => <CorporateadhocMoreInfo {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/Individualadvancedatatable' exact render={(props) => <AdvanceIndividualSearch {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/IndividualadvancedaMoreinfo' exact render={(props) => <AdvanceIndividualMoreinfo {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/corporateadvancetable' exact render={(props) => <CorporateAdvanceTable {...props} roleName={roleName} />} />
                            <Route path='/admin/adhoc/corporateadvancemoreinfo' exact render={(props) => <CorporateAdvanceMoreInfo {...props} roleName={roleName} />} />
                            <Route path="/admin/account/pending/mail" exact  render={(props) => <MailPage {...props} roleName={roleName} />} />
                            <Route path="/admin/account/registration" exact render={(props) => <Registeration {...props} roleName={roleName} />} />
                            <Route path="/admin/account/search" exact render={(props) => <Accountsearch {...props} roleName={roleName} />} />
                            <Route path="/admin/account/pending" exact render={(props) => <PendingList {...props} roleName={roleName}/> } />
                            <Route path="/admin/account/Updates" exact render={(props) => <SansactionMainpage {...props} roleName={roleName} />} />
                            <Route path="/admin/account/benificary" exact render={(props) => <BendingDataPage {...props} roleName={roleName} />} />
                            <Route path="/admin/account/benificarymoreinfo" exact render={(props) => <BendingDataMoreInfo {...props} roleName={roleName} />} />
                            <Route path="/admin/account/transationdata" exact render={(props) => <TransationPendingData {...props} roleName={roleName} />} />
                            <Route path="/admin/account/individualpending" exact render={(props) => <IndividualDataPending {...props} roleName={roleName} />} />
                            <Route path="/admin/account/individualpendingmoreinfo" exact render={(props) => <IndividualPendingMoreInfo {...props} roleName={roleName} />} />
                            <Route path="/admin/account/individualaccountprofilingmoreinfo" exact render={(props) => <IndividualAccountProfilingMoreInfo {...props} roleName={roleName} />} />
                            <Route path="/admin/account/corporateaccountprofilingmoreinfo" exact render={(props) => <CorporateAccountProfilingMoreInfo {...props} roleName={roleName} />} />
                            <Route path="/admin/account/classification" exact render={(props) => <RiskClarification {...props} roleName={roleName} />} />
                            <Route path="/admin/account/preference" exact render={(props) => <NotificationPreferences {...props} roleName={roleName}/> } />
                            <Route path='/admin/account/profiling' exact render={(props) => <AccountProfiling {...props} roleName={roleName} />} />
                            <Route path='/admin/account/profilings' exact render={(props) => <AccountProfilings {...props} roleName={roleName} />} />
                            <Route path="/admin/account/profiling/beneficiary" exact render={(props) => <Beneficiarye {...props} roleName={roleName} />} />
                            <Route path="/admin/account/profiling/individual/beneficiary" exact render={(props) => <Beneficiaryes {...props} roleName={roleName} />} />
                            <Route path="/admin/accounts" exact render={(props) => <Account {...props} roleName={roleName} />} />
                            <Route path="/admin/individual/accounts" exact  render={(props) => <Accounts {...props} roleName={roleName} />} />
                            <Route path="/admin/transaction" exact render={(props) => <Transactionv {...props} roleName={roleName} />} />
                            <Route path="/admin/individual/transaction" exact render={(props) => <Transactionvs {...props} roleName={roleName} />} />
                            <Route path="/admin/historyv" exact render={(props) => <Historyv {...props} roleName={roleName} />} />
                            <Route path="/admin/individual/historyv" exact render={(props) => <Historyvs {...props} roleName={roleName} />} />
                            <Route path="/admin/account/corporateprofiling" exact render={(props) => <CorporateProfiling {...props} roleName={roleName} />} />
                            <Route path="/admin/corporate/beneficiary" exact render={(props) => <ViewBeneficiary {...props} roleName={roleName} />} />
                            <Route path="/admin/corporate/history" exact render={(props) => <ViewHistory {...props} roleName={roleName} />} />
                            <Route path="/admin/corporate/transaction" exact render={(props) => <ViewTransaction {...props} roleName={roleName} />} />
                            <Route path="/admin/transaction/monitoring" exact render={(props) => <Transaction {...props} roleName={roleName} />} />
                            <Route path="/admin/transaction/threshold" exact render={(props) =><MonitoringThresholds {...props} roleName={roleName}/> } />
                            <Route path="/admin/transaction/beneficiary" exact render={(props) => <Beneficiary {...props} roleName={roleName} />} />
                            <Route path="/admin/account/individualform" exact render={(props) => <IndividualForm {...props} roleName={roleName} />} />
                            <Route path="/admin/account/corporateform" exact render={(props) => <CorporateForm {...props} roleName={roleName} />} />
                            <Route path="/admin/account/profiling/corporateinformation" exact render={(props) => <CorporateInformation {...props} roleName={roleName} />} />
                            <Route path="/admin/branches/activity" exact render={(props) => <UserActivity {...props} roleName={roleName} />} />
                            <Route path="/admin/user/history/activity" exact render={(props) => <UserHistoryActivity {...props} roleName={roleName} />} />
                            <Route path="/admin/account/profiling/riskreason" exact render={(props) => <IndividualRiskreason {...props} roleName={roleName} />} />
                            <Route path="/admin/account/corpoprofiling/riskreason" exact render={(props) => <CorpoRiskreason {...props} roleName={roleName} />} />
                        </Switch>
                    </div>
                </Router >
            </div >
        </>
    );
}

export default Admin;
