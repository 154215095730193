import React ,{ useState, useEffect }from "react";
import { Chart } from "primereact/chart";
import './Companyprofile.css';

const CompanyChart = ({ data }) => {
    const [labelPosition, setLabelPosition] = useState("right");
    
    useEffect(() => {
        const handleResize = () => {
            const newLabelPosition = window.innerWidth <= 1000 ? "bottom" : "right";
            setLabelPosition(newLabelPosition);
        };

        handleResize(); 

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const hasData = Object.values(data).some(branch => (
        branch.clearedAccounts > 0 ||
        branch.blockedAccounts > 0 ||
        branch.ongoingAccounts > 0 ||
        branch.pendingAccounts > 0
    ));

    if (!hasData) {
        const dummyChartData = {
            labels: ["Cleared Accounts", "Blocked Accounts", "Ongoing Accounts", "Pending Accounts"],
            datasets: [
                {
                    data: [0, 0, 0, 0],
                    backgroundColor: ["#FFECD9", "#DBF2F2", "#EBE0FF", "#D7ECFB"],
                    hoverBackgroundColor: ["#75E6DA", "#189AB4", "#7286D3", "#FD8A8A"],
                    borderColor: ["#FE9707", "#9A69F2", "#6E986E", "#2196F3"]
                }
            ]
        };
        const chartOptionss = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        color: "#555555",
                        usePointStyle: true,                      
                    },
                    position: labelPosition,
                    align: "center",   
                    padding: 8
                }
            }
        };

        return (
            <div className="flex justify-content-center">
                <Chart type="polarArea" data={dummyChartData} options={chartOptionss} className="w-full comapny-chart-pie" style={{ height: "15rem" }} />
            </div>
        );
    }
    const chartData = {
        labels: ["Cleared Accounts", "Blocked Accounts", "Ongoing Accounts", "Pending Accounts"],
        datasets: [
            {
                data: [
                    Object.values(data).reduce((sum, branch) => sum + branch.clearedAccounts, 0),
                    Object.values(data).reduce((sum, branch) => sum + branch.blockedAccounts, 0),
                    Object.values(data).reduce((sum, branch) => sum + branch.ongoingAccounts, 0),
                    Object.values(data).reduce((sum, branch) => sum + branch.pendingAccounts, 0)
                ],
                backgroundColor: ["#FFECD9", "#DBF2F2", "#EBE0FF", "#D7ECFB"],
                hoverBackgroundColor: ["#75E6DA", "#189AB4", "#7286D3", "#FD8A8A"],
                borderColor: ["#FE9707", "#9A69F2", "#6E986E", "#2196F3"]
            }
        ]
    };

    const chartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: "#555555",
                    usePointStyle: true,
                },
                position: labelPosition, 
                align: "center",  
                padding: 8
            }
        }
    };

    return (
        <div>           
            <div className="flex justify-content-center">
                <Chart type="pie" data={chartData} options={chartOptions} className="w-full comapny-chart-pie" style={{ height: "15rem" }} />
            </div>
        </div>
    );
};

export default CompanyChart;
