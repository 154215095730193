import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Toast } from 'primereact/toast';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import './PaymentPage.css';
import { Steps } from 'primereact/steps';
import { useLocation } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
const style = { "layout": "vertical" };

const encrypt = (data, type) => {
    const key = CryptoJS.enc.Utf8.parse('acaiaalthi0001uu');
    const iv = CryptoJS.enc.Utf8.parse('qwertyuioplkjhgf');
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });

    if (type === 'hex') {
        return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
    } else {
        return encrypted.toString();
    }
};

const PaypalView = ({ amount }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [authorizationId, setAuthorizationId] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const history = useHistory()
    const toast = useRef(null);
    const location = useLocation();
    const emailId = location.state?.emailId;
    const password = location.state?.password;
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [branchdata, setBranchdata] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [data, setData] = useState([]);
    const items = [
        { label: 'Payment Method' },
        { label: 'Approve' },
        { label: 'Capture' },
    ];
    const [premiumPlan, setPremiumPlan] = useState("");
    const [accessTicket, setAccessTicket] = useState("");
    const [refreshTicket, setRefreshTicket] = useState("");
    // useEffect(() => {
    //     document.addEventListener('contextmenu', (event) => {
    //         event.preventDefault();
    //     });

    //     document.addEventListener('keydown', (event) => {
    //         if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I') || (event.ctrlKey && event.shiftKey && event.key === 'C')) {
    //             event.preventDefault();
    //         }
    //     });
    // }, []);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, []);

    const encryptedEmailId = encrypt(emailId, 'hex');

    const authorizePayment = async (orderId, encryptedEmailId) => {

        const requestOptions = {
            method: 'GET'
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/${orderId}/authorize?emailId=${encryptedEmailId}`, requestOptions);
            if (response.ok) {
                setCurrentStep(1);
            }

            if (!response.ok) {
                throw new Error('Failed to authorize payment');
            }
            const responseData = await response.json();
            const authorization = responseData.body.purchase_units[0].payments.authorizations.find(auth => auth.status === 'CREATED');
            const authorizationID = authorization.id;
            return authorizationID;
        } catch (error) {
            throw error;
        }
    };

    const branchda = useCallback(async (accessToken) => {
        try {
            const headers = {
                'Authorization': 'Bearer ' + accessToken
            };
            const response1 = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/get_all/Repurchase`, {
                headers: headers
            });

            setData(response1.data);


        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'error', detail: error?.message || 'Error' });
            }
        }
    }, []);

    const branchda1 = useCallback(async (accessToken) => {
        try {
            const headers = {
                'Authorization': 'Bearer ' + accessToken
            };

            let uri = `/getAllActiveBranches`;

            const response = await axios.get(process.env.REACT_APP_BACKEND_HOST + uri, {
                headers: headers
            });

            setBranchdata(response.data);

        } catch (error) {
            if (error?.response?.status === 401) {
                toast.current.show({ severity: 'error', summary: 'Access Denied', detail: 'You do not have permission to access this resource.', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'error', detail: error?.message || 'Error' });
            }
        }
    }, []);

    const capturePayment = useCallback(async (encryptedEmailId) => {

        const requestOptions = {
            method: 'POST'
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/authorizations/${authorizationId}/capture?emailId=${encryptedEmailId}`, requestOptions);
            const responseData = await response.json();
            const purchasePlanCount = responseData.body.user.purchasePlanCount;
            const accessToken = responseData.body.signInResponse.access_token;
            const premiumPlan = responseData.body.user.premiumPlan;
            setPremiumPlan(premiumPlan)
            setAccessTicket(accessToken)
            const emailIds = responseData.body.user.emailId;
            const refreshToken = responseData.body.signInResponse.refresh_token;
            setRefreshTicket(refreshToken)
            sessionStorage.setItem('roleName', responseData.body.user.premiumPlan);
            toast.current.show({ severity: 'success', summary: 'Payment Success', detail: `Payment of ${amount} USD captured Successfully`, life: 3000 });

            if (purchasePlanCount > 1) {
                setConfirmationMessage("User or branch already has an active purchase plan. Do you want to continue?");
                setShowConfirmation(true);
                setTimeout(() => {
                    branchda(accessToken);
                }, 3000);
                setTimeout(() => {
                    branchda1(accessToken);
                }, 2000);
            } else {
                setTimeout(() => {
                    history.push({
                        pathname: '/additionaldetails',
                        state: {
                            captureMail: emailIds,
                            captureAccessToken: accessToken,
                            captureRefreshToken: refreshToken,
                            password: password
                        },
                    });
                }, 1000);
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error capturing payment.', life: 3000 });
        }
    }, [authorizationId, branchda, branchda1, amount, history, password]);

    const hideDialogHandler = () => {
        setShowDialog(false);
        setTimeout(() => {
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    emailId: emailId,
                    accessToken: accessTicket,
                    refreshToken: refreshTicket,
                    roleName: premiumPlan,
                },
            });
        }, 3000);
    };
    const handleApplyFilter = async () => {
        try {
            await Promise.all([fetchData(accessTicket), fetchData2(accessTicket)]);
            hideDialogHandler();
            setTimeout(() => {
                history.push({
                    pathname: '/admin/dashboard',
                    state: {
                        emailId: emailId,
                        accessToken: accessTicket,
                        refreshToken: refreshTicket,
                        roleName: premiumPlan,

                    },
                });
            }, 3000);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const fetchData = async () => {

        const selectedBranchIds = selectedBranches.map(branch => ({ id: branch.id }));
        try {
            const response1 = await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/branches/updateStatus?email=${emailId}`, selectedBranchIds,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessTicket
                    }
                }
            );

            if (response1.status === 200) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: "Branches statuses updated to Active Successfully",
                    life: 3000
                });
            }

            hideDialogHandler();
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const fetchData2 = async (accessTicket) => {

        const payload = selectedUsers.map(user => ({ emailId: user }));
        try {

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/users/updateStatus?email=${emailId}`, payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessTicket
                    }
                }
            );

            if (response.status === 200) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: "Users status updated to Active Successfully",
                    life: 3000
                });
            }
            hideDialogHandler();
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };


    useEffect(() => {
        const captureAfterAuthorization = async () => {
            if (authorizationId) {
                await capturePayment(encryptedEmailId);
                setCurrentStep(2);
            }
        };

        captureAfterAuthorization();
    }, [authorizationId, capturePayment, encryptedEmailId]);

    return (
        <>
            <Toast ref={toast} position="top-right" style={{ top: '10%' }} />
            {loading ? (
                <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
                    <Triangle
                        height="80"
                        width="80"
                        color="#124A99"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <div>
                    <div className='card-payment mt-4'>
                        <div className='paypal-pagee m-6'>
                            <div className='unique-step m-5'>
                                <Steps model={items} activeIndex={currentStep} />
                            </div>
                            <div className='card-paypal m-8 mt-3'>
                                <div className='paypal-head p-5 flex justify-content-start'>Payment Method</div>
                                <div className='unique-paypals p-5'>
                                    <div className='provider'>
                                        <div className='provider-topic flex justify-content-start' style={{ marginBottom: '25px' }}>Choose a payment method</div>
                                        <PayPalScriptProvider options={{ 'client-id': `${process.env.REACT_APP_CLIENT_ID}`, intent: 'authorize', components: "buttons", currency: "USD" }}>
                                            <PayPalButtons
                                                style={style}
                                                disabled={false}
                                                fundingSource={undefined}
                                                createOrder={(data, actions) => {
                                                    return actions.order.create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: `${amount}`,
                                                                },
                                                            },
                                                        ],
                                                    });
                                                }}
                                                onApprove={async (data, actions) => {
                                                    const orderId = data.orderID;
                                                    try {
                                                        const authorizationID = await authorizePayment(orderId, encryptedEmailId);
                                                        setAuthorizationId(authorizationID);
                                                        const captureResult = await actions.order.capture();
                                                        if (captureResult && captureResult.status === 'COMPLETED') {

                                                        } else {
                                                            throw new Error('Failed to capture payment');
                                                        }
                                                    } catch (error) {
                                                        console.error('Error processing payment');
                                                    }
                                                }}
                                                onError={(err) => {
                                                    toast.current.show({ severity: 'error', summary: 'PayPal Error', detail: 'PayPal error occurred', life: 5000 });
                                                    console.error('PayPal error:', err);
                                                }}
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
            <ConfirmDialog header="Confirmation" visible={showConfirmation} onHide={() => setShowConfirmation(false)}
                message={confirmationMessage} icon="pi pi-exclamation-triangle"
                className="sing_up_main_Dialogs"

                accept={() => {
                    setShowConfirmation(false);
                    setTimeout(async () => {

                        history.push({
                            pathname: '/admin/dashboard',
                            state: {
                                emailId: emailId,
                                accessToken: accessTicket,
                                refreshToken: refreshTicket,
                                roleName: premiumPlan,
                            },
                        });
                    }, 3000);
                }}
                reject={() => {
                    setShowConfirmation(false);
                    setShowDialog(true);
                }}
            />
            <Dialog header="Select a branch and user" className="sing_up_main_Dialog payment" visible={showDialog} onHide={() => setShowDialog(false)}>
                <div className="main-flex-p-2 p-4 feild feild-m c pt-3 ">
                    <div className="flex">
                        <div className="input">
                            <div className="label">Branches</div>
                            <MultiSelect value={selectedBranches} options={branchdata.map((item) => ({ label: item.name, value: item.name }))} onChange={(e) => setSelectedBranches(e.value)} className="w-full unique-drops dropdown" optionLabel="label" required />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="input">
                            <div className="label">Users</div>
                            <MultiSelect value={selectedUsers} options={data.map((item) => ({ label: item.emailId, value: item.emailId }))} onChange={(e) => setSelectedUsers(e.value)} className="w-full unique-drops dropdown" optionLabel="label" required />
                        </div>
                    </div>
                    <div className="footer_sec_btn unique-data-btn" style={{ height: '70px', padding: '0px', paddingTop: '0px' }}>
                        <Button onClick={handleApplyFilter} style={{ width: '80px' }}>Submit</Button>
                        <Button onClick={hideDialogHandler} style={{ width: '80px' }}>Cancel</Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default PaypalView;




