import React from "react";
import MonitoringThresholdsListTable from "../../Components/Cards/MonitoringThresholds/MonitoringThresholds";
import { useLocation } from 'react-router-dom';

export default function Settings({roleName}) {

  const location = useLocation();
  
  return (
    <>
        <div className="w-full">
            <MonitoringThresholdsListTable data={location?.state} roleName={roleName}/>
        </div>
    </>
  );
}
