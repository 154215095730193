// import React from 'react';
import axios from 'axios';
// import { CirclesWithBar } from 'react-loader-spinner';



class DesignationService {
    getProducts( accessToken) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let uri = `/designation`;

        return axios.get(process.env.REACT_APP_BACKEND_HOST + uri, requestOptions).then(res => res.data);

    }

    // updateProduct(_product) {

    //     const headers = {
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    //     };

    //     let uri = "/designation/designation" + _product.id;

    //     return axios.put(process.env.REACT_APP_BACKEND_HOST + uri, _product, {
    //       headers: headers
    //     }).then(res => res.data);

    //   }     

    // deleteProduct(_product) {
    //     const headers = {
    //         'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    //     };
    //     let uri = "/designation/" + _product.id;

    //     return axios.delete(process.env.REACT_APP_BACKEND_HOST + uri, { headers: headers })
    //         .then(res => res.data);
    // }
}

export default DesignationService
