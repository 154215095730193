import axios from "axios";

const instance = axios.create({
   baseURL: "https://idm.althisolutions.com",
  // baseURL: "http://192.168.68.102:8082",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;