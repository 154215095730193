import React from "react";
import './sidebar.css';
import Panelimage from '../../assets/logo-acaia.svg';
import { useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Althilogo from '../../assets/althilogo133 1.png';
import { FaUserCheck } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import { RiUserSearchFill } from "react-icons/ri";
import { MdHelpOutline } from "react-icons/md";
import { TbUserEdit } from "react-icons/tb";
import { FaChartLine } from "react-icons/fa6";
import { HiOutlineBell } from "react-icons/hi";
import { LuWallet } from "react-icons/lu";
import { AiOutlineTransaction } from "react-icons/ai";
import { FaUserPlus } from "react-icons/fa";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { RiAdminFill } from "react-icons/ri";
import { FaIdCard } from "react-icons/fa";
import { FaCodeBranch } from "react-icons/fa";
import { FaUsers } from "react-icons/fa6";
import { TbUserCog } from "react-icons/tb";
import { FaDesktop } from "react-icons/fa6";
import { MdWork } from "react-icons/md";
import { IoEarthSharp } from "react-icons/io5";
import { IoIosWarning } from "react-icons/io";
import { Sidebar } from 'primereact/sidebar';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
// import Header from '../Header/Header';
// import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

function Sidebars() {
    const navigate = useHistory();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const sidebarRef = useRef(null);
    // const history = useHistory();
    const location = useLocation();
    // const [visible, setVisible] = useState(false);
    // const [accessToken, setAccessToken] = useState(location?.state?.accessToken);
    const accessToken = location?.state?.accessToken;
    const refreshToken = location?.state?.refreshToken;

    // const [refreshToken, setRefreshToken] = useState(location?.state?.refreshToken);
    // const [email, setEmail] = useState(location?.state?.emailId);

    // const [href, setHref] = useState();
    const [showAdhoc, setShowAdhoc] = useState();
    const [accountPro, setAccountPro] = useState(false);
    const [watchfulness, setWatchfulness] = useState(false);
    const [administration, setAdministration] = useState(false);
    const [visibles, setvisibles] = useState(true);

    const [Role, setRole] = useState("");
    const [getUserData, setGetUserData] = useState();

    // const [id, setId] = useState("");

    const [designationData, setDesignationData] = useState();
    const [nationalitydata, setNationalitydata] = useState();
    const [branchdata, setBranchdata] = useState(null);

    // const email = location.state?.emailId;
    // const [dummy, setDummy] = useState();
    const [accPemding, setAccPemding] = useState();
    const [benPending, setBenPending] = useState();
    const [traPending, setTraPending] = useState();
    const [rolesdata, setRolesdata] = useState([]);

    useEffect(() => {

        axios.get(process.env.REACT_APP_BACKEND_HOST + '/get', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setGetUserData(response?.data);
                setRole(response?.data?.premiumPlan);
                // setDummy(response?.data)
                navigate.push({
                    state: { getUserData: response?.data, accessToken: accessToken, refreshToken: refreshToken, emailId: response?.data?.emailId },
                });

                if (response?.data?.premiumPlan !== 'FreeTrial') {
                    axios.get(process.env.REACT_APP_BACKEND_HOST + '/designation?status=Active', {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    })
                        .then(response => {
                            setDesignationData(response.data);
                        })
                        .catch(error => {
                            // console.log(error);
                        });
                }

                if (accessToken) {
                    axios.get(process.env.REACT_APP_BACKEND_HOST + '/country', {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    })
                        .then(response => {
                            setNationalitydata(response.data);
                        })
                        .catch(error => {
                            // console.log(error);
                        });
                }

                if (accessToken) {
                    axios.get(`${process.env.REACT_APP_IDM_BACKEND_HOST}/roles/all`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    })
                        .then(response => {
                            setRolesdata(response.data);
                        })
                        .catch((error) => {
                            if (error?.response && error?.response?.data && error?.response?.data?.message) {
                                const errorMessage = error?.response?.data?.message;
                                console.log(errorMessage);
                            }
                        });
                }

                if (response?.data?.premiumPlan !== 'FreeTrial') {
                    axios.get(process.env.REACT_APP_BACKEND_HOST + '/branches', {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    })
                        .then(response => {
                            const branch = response.data;
                            setBranchdata(branch);
                        })
                        .catch(error => {
                            // console.log(error);
                        });
                }

                if (response?.data?.premiumPlan !== 'FreeTrial') {
                    axios.get(process.env.REACT_APP_BACKEND_HOST + '/get/count', {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }
                    })
                        .then(response => {
                            const data = response.data;
                            const numbers = [];
                            for (const item of data) {
                                const match = item.match(/\d+/);
                                if (match) {
                                    numbers.push(parseInt(match[0]));
                                }
                            }
                            setAccPemding(numbers[0])
                            setBenPending(numbers[1])
                            setTraPending(numbers[2])
                        })
                        .catch(error => {
                            // console.log(error);
                        });
                };


            })
            .catch(error => {
                // console.log(error);
            });

    }, [accessToken, navigate]);

    const toggleMobileMenu = (event) => {
        setvisibles(!visibles)
        if (event.target.innerText === 'Dashboard') {
            navigate.push({
                pathname: '/admin/dashboard',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
            setAdministration(false);
            setShowAdhoc(false);
            setWatchfulness(false);
            setAccountPro(false);
        } else if (event.target.innerText === 'Adhoc Search') {
            navigate.push({
                pathname: '/admin/adhoc/search',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Account Registration') {
            navigate.push({
                pathname: '/admin/account/registration',
                state: {
                    emailId: getUserData?.emailId,
                    designationData: designationData,
                    nationalitydata: nationalitydata,
                    branchdata: branchdata,
                    accessToken: location?.state?.accessToken,
                    getUserData: getUserData,
                    countryData: nationalitydata,
                    branchData: branchdata,
                    refreshToken: refreshToken
                },
            });
        } else if (event.target.innerText === 'Account Search') {
            navigate.push({
                pathname: '/admin/account/search',
                state: { nationalitydata: nationalitydata, Role: Role, getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Pending Verification') {
            navigate.push({
                pathname: '/admin/account/pending',
                state: { traPending: traPending, benPending: benPending, accPemding: accPemding, getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Sanction Updates') {
            navigate.push({
                pathname: '/admin/account/Updates',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Risk Classification') {
            navigate.push({
                pathname: '/admin/account/classification',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Alert Preference') {
            navigate.push({
                pathname: '/admin/account/preference',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Account Profiling') {
            navigate.push('/admin/account/profiling');
        } else if (event.target.innerText === 'Transaction') {
            navigate.push({
                pathname: '/admin/transaction/monitoring',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Beneficiary') {
            navigate.push({
                pathname: '/admin/transaction/beneficiary',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId, countryData: nationalitydata },
            });
        } else if (event.target.innerText === 'Alert Criteria') {
            navigate.push({
                pathname: '/admin/transaction/threshold',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Company Profile') {
            navigate.push({
                pathname: '/admin/company',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Branches') {
            navigate.push({
                pathname: '/admin/branches',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId, countryData: nationalitydata },
            });
        } else if (event.target.innerText === 'Users') {
            navigate.push({
                pathname: '/admin/users',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId, rolesData: rolesdata },
            });
        } else if (event.target.innerText === 'Roles') {
            navigate.push({
                pathname: '/admin/roles',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId, rolesData: rolesdata },
            });
        } else if (event.target.innerText === 'Designation') {
            navigate.push({
                pathname: '/admin/designation',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        } else if (event.target.innerText === 'Country') {
            navigate.push({
                pathname: '/admin/country',
                state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
            });
        }

        if (event.target.innerText === 'Account Registrat6678ion') {
            // navigate.push({
            //     state: { getUserData: getUserData, accessToken: accessToken, emailId: getUserData?.emailId },
            // });
        }


        const href = event.target.baseURI;
        const split = href.split('/');
        const firstWord = split.length - 1;
        const secondWord = split.length - 2;
        let url = split[secondWord] + "/" + split[firstWord]
        // setHref(url)

        if (url === 'adhoc/search' || url === 'account/registration' || url === 'account/search' || url === 'account/pending' || url === 'account/Updates' || url === 'adhoc/individualdata') {
            setShowAdhoc(true)
        } else if (url === 'account/classification' || url === 'account/preference') {
            setAccountPro(true)
        } else if (url === 'transaction/monitoring' || url === 'transaction/beneficiary' || url === 'transaction/threshold') {
            setWatchfulness(true)
        } else if (url === 'admin/company' || url === 'admin/branches' || url === 'admin/users' || url === 'admin/roles' || url === 'admin/designation' || url === 'admin/country') {
            setAdministration(true)
        }

    };

    useEffect(() => {
        if (location.pathname === '/admin/adhoc/search' || location.pathname === '/admin/account/registration' || location.pathname === '/admin/account/search' || location.pathname === '/admin/account/pending' || location.pathname === '/admin/account/Updates' || location.pathname === 'adhoc/individualdata') {
            setShowAdhoc(true);
            setAdministration(false);
            setWatchfulness(false);
            setAccountPro(false);
        } else if (location.pathname === '/admin/account/classification' || location.pathname === '/admin/account/preference') {
            setAccountPro(true);
            setAdministration(false);
            setShowAdhoc(false);
            setWatchfulness(false);
        } else if (location.pathname === '/admin/transaction/monitoring' || location.pathname === '/admin/transaction/beneficiary' || location.pathname === '/admin/transaction/threshold') {
            setWatchfulness(true);
            setAdministration(false);
            setShowAdhoc(false);
            setAccountPro(false);
        } else if (location.pathname === '/admin/company' || location.pathname === '/admin/branches' || location.pathname === '/admin/users' || location.pathname === '/admin/roles' || location.pathname === '/admin/designation' || location.pathname === '/admin/country') {
            setAdministration(true);
            setShowAdhoc(false);
            setWatchfulness(false);
            setAccountPro(false);
        } else if (location.pathname === '/admin/userprofile' || location.pathname === '/admin/generalinformation' || location.pathname === '/admin/history' || location.pathname === '/admin/user/history/activity') {
            setAdministration(false);
            setShowAdhoc(false);
            setWatchfulness(false);
            setAccountPro(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
                // Click outside of the sidebar content
                setIsMobileMenuOpen(false);
            }
        };

        // Add event listener to the document for outside click detection
        document.addEventListener("mousedown", handleOutsideClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isMobileMenuOpen]);

    useEffect(() => {
        // navigate.push("/admin/dashboard")

        if (window.innerWidth <= 800) {
            setvisibles(true)
        } else {
            setvisibles(false)
        }
        function handleResize() {
            if (window.innerWidth >= 800) {
                setvisibles(false);
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        setTimeout(() => {
            localStorage.clear();
        }, 5000);

        const handleOutsideClick = (e) => {
            if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
                // Click outside of the sidebar content
                setIsMobileMenuOpen(false);
            }
        };

        // Add event listener to the document for outside click detection
        document.addEventListener("mousedown", handleOutsideClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isMobileMenuOpen]);

    const logoclick = () => {
        navigate.push({
            pathname: '/admin/dashboard',
            state: { getUserData: getUserData, accessToken: accessToken, refreshToken: refreshToken, emailId: getUserData?.emailId },
        });
        setAdministration(false);
        setShowAdhoc(false);
        setWatchfulness(false);
        setAccountPro(false);
    }

    const freeUser = (rowData) => {
        return (
            <>
                <div className="product_Img"><img src={Panelimage} style={{ cursor: 'pointer' }} onClick={logoclick} alt="panel" /></div>

                <div className={(location.pathname === '/admin/dashboard' ? "active abcd" : "menu")} onClick={toggleMobileMenu}>
                    <span style={{ display: 'flex', background: 'none' }}><FaDesktop />Dashboard</span>
                </div>

                <div className={`${showAdhoc ? "active abcd sub" : "menu"}`} onClick={() => setShowAdhoc((showAdhoc) => !showAdhoc) + setAccountPro(false) + setWatchfulness(false) + setAdministration(false)}>
                    <span style={{ display: 'flex', background: 'none' }}><FaUserCheck />Risk Check</span>
                    <i className={`bg pi ${showAdhoc ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {showAdhoc ? <>
                    <div className={(location.pathname === '/admin/adhoc/search' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><IoMdSearch />Adhoc Search</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/registration' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><FaRegUser />Account Registration</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/search' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><RiUserSearchFill />Account Search</span>
                    </div>
                    <div
                        className={`menu sub ${Role === 'FreeTrial' ? 'disabled' : location.pathname === '/admin/account/pending' ? 'active abcd' : ''}`}
                        onClick={Role !== 'FreeTrial' ? toggleMobileMenu : undefined}
                        style={{ color: Role === 'FreeTrial' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'FreeTrial' ? 'none' : 'auto' }}
                    >
                        <span style={{ display: 'flex', background: 'none' }}>
                            <MdHelpOutline /> Pending Verification
                        </span>
                    </div>
                    <div
                        className={`menu sub ${Role === 'FreeTrial' ? 'disabled' : location.pathname === '/admin/account/Updates' ? 'active abcd' : ''}`}
                        onClick={Role !== 'FreeTrial' ? toggleMobileMenu : undefined}
                        style={{ color: Role === 'FreeTrial' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'FreeTrial' ? 'none' : 'auto' }}
                    >
                        <span style={{ display: 'flex', background: 'none' }}>
                            <TbUserEdit />Sanction Updates
                        </span>
                    </div>
                </> : null}

                <div className={`${accountPro ? "active1 abcd sub" : "menu"}`} onClick={Role !== 'FreeTrial' ? () => setAccountPro((accountPro) => !accountPro) + setShowAdhoc(false) + setWatchfulness(false) + setAdministration(false) : undefined}>
                    <span style={{ display: 'flex', background: 'none', color: Role === 'FreeTrial' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'FreeTrial' ? 'none' : 'auto' }}><FaChartLine />Account Profiling</span>
                    <i className={`bg pi ${accountPro ? 'pi-angle-down' : 'pi-angle-right'}`} style={{ color: Role === 'FreeTrial' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'FreeTrial' ? 'none' : 'auto' }} />
                </div>

                {accountPro ? <>
                    <div
                        className={`menu sub ${Role === 'FreeTrial' ? 'disabled' : location.pathname === '/admin/account/classification' ? 'active abcd' : ''}`}
                        onClick={Role !== 'FreeTrial' ? toggleMobileMenu : undefined}
                        style={{ color: Role === 'FreeTrial' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'FreeTrial' ? 'none' : 'auto' }}
                    >
                        <span style={{ display: 'flex', background: 'none' }}>
                            <IoIosWarning />Risk Classification</span>
                    </div>
                    <div
                        className={`menu sub ${Role === 'FreeTrial' ? 'disabled' : location.pathname === '/admin/account/preference' ? 'active abcd' : ''}`}
                        onClick={Role !== 'FreeTrial' ? toggleMobileMenu : undefined}
                        style={{ color: Role === 'FreeTrial' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'FreeTrial' ? 'none' : 'auto' }}
                    >
                        <span style={{ display: 'flex', background: 'none' }}>
                            <HiOutlineBell />Alert Preference</span>
                    </div>

                </> : null}

                <div className={`${watchfulness ? "active abcd sub" : "menu"}`} onClick={() => setWatchfulness((watchfulness) => !watchfulness) + setShowAdhoc(false) + setAccountPro(false) + setAdministration(false)} >
                    <span style={{ display: 'flex', background: 'none' }}><LuWallet />Watchfulness</span>
                    <i className={`bg pi ${watchfulness ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {watchfulness ? <>
                    <div className={(location.pathname === '/admin/transaction/monitoring' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><AiOutlineTransaction />Transaction</span>
                    </div>
                    <div className={(location.pathname === '/admin/transaction/beneficiary' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><FaUserPlus />Beneficiary</span>
                    </div>
                    <div
                        className={`menu sub ${Role === 'FreeTrial' ? 'disabled' : location.pathname === '/admin/transaction/threshold' ? 'active abcd' : ''}`}
                        onClick={Role !== 'FreeTrial' ? toggleMobileMenu : undefined}
                        style={{ color: Role === 'FreeTrial' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'FreeTrial' ? 'none' : 'auto' }}
                    >
                        <span style={{ display: 'flex', background: 'none' }}>
                            <HiOutlineCog6Tooth />Alert Criteria</span>
                    </div>
                </> : null}


                <div className={`${administration ? "active abcd sub" : "menu"}`} onClick={() => setAdministration((administration) => !administration) + setShowAdhoc(false) + setWatchfulness(false) + setAccountPro(false)} >
                    <span style={{ display: 'flex', background: 'none' }}><RiAdminFill />Administration</span>
                    <i className={`bg pi ${administration ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {administration ? <>
                    <div className={(location.pathname === '/admin/company' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaIdCard />Company Profile</span>
                    </div>
                    <div className={(location.pathname === '/admin/branches' || location.pathname === '/admin/branches/activity' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaCodeBranch />Branches</span>
                    </div>
                    <div className={(location.pathname === '/admin/users' || location.pathname === '/admin/userregistration' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaUsers />Users</span>
                    </div>
                    <div className={(location.pathname === '/admin/roles' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><TbUserCog />Roles</span>
                    </div>
                    <div className={(location.pathname === '/admin/designation' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><MdWork />Designation</span>
                    </div>
                    <div className={(location.pathname === '/admin/country' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><IoEarthSharp />Country</span>
                    </div>

                </> : null}
            </>
        )
    };

    const BasicAdmin = (rowData) => {
        return (
            <>
                <div className="product_Img"><img src={Panelimage} style={{ cursor: 'pointer' }} onClick={logoclick} alt="panel" /></div>

                <div className={(location.pathname === '/admin/dashboard' ? "active abcd" : "menu")} onClick={toggleMobileMenu}>
                    <span style={{ display: 'flex', background: 'none' }}><FaDesktop />Dashboard</span>
                </div>

                <div className={`${showAdhoc ? "active abcd sub" : "menu"}`} onClick={() => setShowAdhoc((showAdhoc) => !showAdhoc) + setAccountPro(false) + setWatchfulness(false) + setAdministration(false)}>
                    <span style={{ display: 'flex', background: 'none' }}><FaUserCheck />Risk Check</span>
                    <i className={`bg pi ${showAdhoc ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {showAdhoc ? <>
                    <div className={(location.pathname === '/admin/adhoc/search' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><IoMdSearch />Adhoc Search</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/registration' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><FaRegUser />Account Registration</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/search' || location.pathname === '/admin/account/profilings' || location.pathname === '/admin/account/profiling/riskreason' || location.pathname === '/admin/account/individualaccountprofilingmoreinfo' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><RiUserSearchFill />Account Search</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/pending' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><MdHelpOutline />Pending Verification</span>
                    </div>
                    <div
                        className={`menu sub ${Role === 'BasicAdmin' ? 'disabled' : location.pathname === '/admin/account/Updates' ? 'active abcd' : ''}`}
                        onClick={Role !== 'BasicAdmin' ? toggleMobileMenu : undefined}
                        style={{ color: Role === 'BasicAdmin' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'BasicAdmin' ? 'none' : 'auto' }}
                    >
                        <span style={{ display: 'flex', background: 'none' }}>
                            <TbUserEdit />Sanction Updates
                        </span>
                    </div>
                </> : null}

                <div className={`${accountPro ? "active abcd sub" : "menu"}`} onClick={() => setAccountPro((accountPro) => !accountPro) + setShowAdhoc(false) + setWatchfulness(false) + setAdministration(false)}>
                    <span style={{ display: 'flex', background: 'none' }}><FaChartLine />Account Profiling</span>
                    <i className={`bg pi ${accountPro ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {accountPro ? <>
                    <div
                        className={`menu sub ${Role === 'BasicAdmin' ? 'disabled' : location.pathname === '/admin/account/classification' ? 'active abcd' : ''}`}
                        onClick={Role !== 'BasicAdmin' ? toggleMobileMenu : undefined}
                        style={{ color: Role === 'BasicAdmin' ? 'rgb(231 255 255 / 58%)' : 'inherit', pointerEvents: Role === 'BasicAdmin' ? 'none' : 'auto' }}
                    >
                        <span style={{ display: 'flex', background: 'none' }}>
                            <IoIosWarning />Risk Classification</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/preference' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><HiOutlineBell />Alert Preference</span>
                    </div>

                </> : null}

                <div className={`${watchfulness ? "active abcd sub" : "menu"}`} onClick={() => setWatchfulness((watchfulness) => !watchfulness) + setShowAdhoc(false) + setAccountPro(false) + setAdministration(false)} >
                    <span style={{ display: 'flex', background: 'none' }}><LuWallet />Watchfulness</span>
                    <i className={`bg pi ${watchfulness ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {watchfulness ? <>
                    <div className={(location.pathname === '/admin/transaction/monitoring' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><AiOutlineTransaction />Transaction</span>
                    </div>
                    <div className={(location.pathname === '/admin/transaction/beneficiary' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><FaUserPlus />Beneficiary</span>
                    </div>
                    <div className={(location.pathname === '/admin/transaction/threshold' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><HiOutlineCog6Tooth />Alert Criteria</span>
                    </div>
                </> : null}


                <div className={`${administration ? "active abcd sub" : "menu"}`} onClick={() => setAdministration((administration) => !administration) + setShowAdhoc(false) + setWatchfulness(false) + setAccountPro(false)} >
                    <span style={{ display: 'flex', background: 'none' }}><RiAdminFill />Administration</span>
                    <i className={`bg pi ${administration ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {administration ? <>
                    <div className={(location.pathname === '/admin/company' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaIdCard />Company Profile</span>
                    </div>
                    <div className={(location.pathname === '/admin/branches' || location.pathname === '/admin/branches/activity' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaCodeBranch />Branches</span>
                    </div>
                    <div className={(location.pathname === '/admin/users' || location.pathname === '/admin/userregistration' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaUsers />Users</span>
                    </div>
                    <div className={(location.pathname === '/admin/roles' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><TbUserCog />Roles</span>
                    </div>
                    <div className={(location.pathname === '/admin/designation' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><MdWork />Designation</span>
                    </div>
                    <div className={(location.pathname === '/admin/country' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><IoEarthSharp />Country</span>
                    </div>

                </> : null}
            </>
        )
    };

    const ProAdmin = (rowData) => {
        return (
            <>
                <div className="product_Img"><img src={Panelimage} style={{ cursor: 'pointer' }} onClick={logoclick} alt="panel" /></div>

                <div className={location.pathname === '/admin/dashboard' ? "active abcd" : "menu"} onClick={toggleMobileMenu}>
                    <span style={{ display: 'flex', background: 'none' }}><FaDesktop />Dashboard</span>
                </div>

                <div className={`${showAdhoc ? "active abcd sub" : "menu"}`} onClick={() => setShowAdhoc((showAdhoc) => !showAdhoc) + setAccountPro(false) + setWatchfulness(false) + setAdministration(false)}>
                    <span style={{ display: 'flex', background: 'none' }}><FaUserCheck />Risk Check</span>
                    <i className={`bg pi ${showAdhoc ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {showAdhoc ? <>
                    <div className={location.pathname === '/admin/adhoc/search' ? "active abcd sub" : "menu sub"} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><IoMdSearch />Adhoc Search</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/registration' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><FaRegUser />Account Registration</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/search' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><RiUserSearchFill />Account Search</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/pending' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><MdHelpOutline />Pending Verification</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/Updates' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><TbUserEdit />Sanction Updates</span>
                    </div>
                </> : null}

                <div className={`${accountPro ? "active abcd sub" : "menu"}`} onClick={() => setAccountPro((accountPro) => !accountPro) + setShowAdhoc(false) + setWatchfulness(false) + setAdministration(false)}>
                    <span style={{ display: 'flex', background: 'none' }}><FaChartLine />Account Profiling</span>
                    <i className={`bg pi ${accountPro ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {accountPro ? <>
                    <div className={(location.pathname === '/admin/account/classification' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><IoIosWarning />Risk Classification</span>
                    </div>
                    <div className={(location.pathname === '/admin/account/preference' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><HiOutlineBell />Alert Preference</span>
                    </div>

                </> : null}

                <div className={`${watchfulness ? "active abcd sub" : "menu"}`} onClick={() => setWatchfulness((watchfulness) => !watchfulness) + setShowAdhoc(false) + setAccountPro(false) + setAdministration(false)} >
                    <span style={{ display: 'flex', background: 'none' }}><LuWallet />Watchfulness</span>
                    <i className={`bg pi ${watchfulness ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {watchfulness ? <>
                    <div className={(location.pathname === '/admin/transaction/monitoring' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><AiOutlineTransaction />Transaction</span>
                    </div>
                    <div className={(location.pathname === '/admin/transaction/beneficiary' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><FaUserPlus />Beneficiary</span>
                    </div>
                    <div className={(location.pathname === '/admin/transaction/threshold' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu}>
                        <span style={{ display: 'flex', background: 'none' }}><HiOutlineCog6Tooth />Alert Criteria</span>
                    </div>
                </> : null}


                <div className={`${administration ? "active abcd sub" : "menu"}`} onClick={() => setAdministration((administration) => !administration) + setShowAdhoc(false) + setWatchfulness(false) + setAccountPro(false)} >
                    <span style={{ display: 'flex', background: 'none' }}><RiAdminFill />Administration</span>
                    <i className={`bg pi ${administration ? 'pi-angle-down' : 'pi-angle-right'}`} />
                </div>

                {administration ? <>
                    <div className={(location.pathname === '/admin/company' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaIdCard />Company Profile</span>
                    </div>
                    <div className={(location.pathname === '/admin/branches' || location.pathname === '/admin/branches/activity' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaCodeBranch />Branches</span>
                    </div>
                    <div className={(location.pathname === '/admin/users' || location.pathname === '/admin/userregistration' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><FaUsers />Users</span>
                    </div>
                    <div className={(location.pathname === '/admin/roles' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><TbUserCog />Roles</span>
                    </div>
                    <div className={(location.pathname === '/admin/designation' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><MdWork />Designation</span>
                    </div>
                    <div className={(location.pathname === '/admin/country' ? "active abcd sub" : "menu sub")} onClick={toggleMobileMenu} >
                        <span style={{ display: 'flex', background: 'none' }}><IoEarthSharp />Country</span>
                    </div>

                </> : null}
            </>
        )
    };
    const pushwebsite = () => {
        window.location.href = "http://www.althisolutions.com";
    }

    return (
        <>
            <div className="Bar-icon">
                <i className="pi pi-bars sidebar-btn" onClick={toggleMobileMenu}></i>
            </div>
            <div className="side_Bar">
                <div className="menuBar">
                    <div className="menu_Items">
                        <div className="mid_sec">

                            {Role === 'BasicAdmin' && BasicAdmin()}
                            {Role === 'ProAdmin' && ProAdmin()}
                            {Role === 'FreeTrial' && freeUser()}

                        </div>
                    </div>
                    <div className="footer_P">Copyright 2022ACAIA
                        <img src={Althilogo} style={{ cursor: 'pointer' }} onClick={pushwebsite} alt='logo' />
                    </div>
                    <Sidebar visible={visibles} onHide={() => setvisibles(false)} className="slider sidebar-mobile">
                        <div className="abcdef">
                            <div className="menu_Items2">
                                <div className="mid_sec">
                                    {Role === 'BasicAdmin' && BasicAdmin()}
                                    {Role === 'ProAdmin' && ProAdmin()}
                                    {Role === 'FreeTrial' && freeUser()}
                                </div>
                            </div>
                            <div className="footer_P">
                                Copyright 2022ACAIA
                                <img src={Althilogo} style={{ cursor: 'pointer' }} onClick={pushwebsite} alt='logo' />
                            </div>
                        </div>
                    </Sidebar>
                </div>
            </div>
        </>
    );
}
export default Sidebars;


