import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as XLSX from 'xlsx';
import { FaFilePdf, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

function UserActivity() {
    const location = useLocation();
    const UserDetails = location?.state?.userDetails;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const item = [
        {
            label: 'History', command: () =>
                history.push({
                    pathname: '/admin/history',
                    state: { getUserData: location?.state?.getUserData, accessToken: location?.state?.accessToken, emailId: location?.state?.getUserData?.emailId },
                })
        },
        { label: 'History Report' },
    ];
    const home = {
        icon: 'pi pi-home', command: () =>
            history.push({
                pathname: '/admin/dashboard',
                state: {
                    accessToken: location?.state?.accessToken,
                    emailId: location?.state?.emailId,
                    getUserData: location?.state?.getUserData,
                },
            })
    }
    const home1 = { icon: 'pi pi-angle-double-left', command: () => window.history.back() }

    const [visible, setVisible] = useState(false);
    let menu = null;

    const items = [
        {
            label: 'XLS',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToXLS();
            }
        },
        {
            label: 'XLSX',
            icon: () => <FaFileExcel />,
            command: () => {
                setVisible(false);
                exportToExcel();
            }
        },
        {
            label: 'CSV',
            icon: () => <FaFileCsv />,
            command: () => {
                setVisible(false);
                handleExportToCSV();
            }
        },
        {
            label: 'PDF',
            icon: () => <FaFilePdf />,
            command: () => {
                setVisible(false);
                handleExportToPDF();
            }
        }
    ];

    const toggleMenu = (event) => {
        setVisible(!visible);
        menu.toggle(event);
    };

    const cols = [
        { field: 'action', header: 'Action ' },
        { field: 'activityCount', header: 'Activity Count' },
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const handleExportToPDF = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, UserDetails.UserReport); // UserDetails.UserReport instead of UserDetails
                doc.save('history activity report.pdf');
            });
        });
    };

    const handleExportToCSV = () => {
        const csvContent = convertToCSV(UserDetails.UserReport); // UserDetails.UserReport instead of UserDetails
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', 'history activity report.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const csvRows = [];
        if (Array.isArray(data) && data.length > 0) {
            const headers = Object.keys(data[0]);
            csvRows.push(headers.join(','));
            for (const row of data) {
                const values = Object.values(row).join(',');
                csvRows.push(values);
            }
        }
        return csvRows.join('\n');
    };

    const exportToExcel = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(UserDetails.UserReport); // UserDetails.UserReport instead of UserDetails
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'history activity report.xlsx');
    };

    const exportToXLS = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(UserDetails.UserReport); // UserDetails.UserReport instead of UserDetails
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'history activity report.xls');
    };

    return (
        <>
            <div className="mainSec" >
                <>
                    <BreadCrumb model={item} className='breadCrumb' home={windowWidth <= 575 ? home1 : home} style={{ border: 'none' }} />
                    <div className="card card-Adhoc w-full bg-white" style={{ marginBottom: '8%', position: 'relative', background: '#fff', border: '1px solid #e8e9eb' }} >
                        <div className="individual-account-search" style={{ border: 'solid rgba(209, 209, 209, 1) 1px' }}>
                            <div className="tab-head-btns flex flex-wrap align-items-center justify-content-end" style={{ borderBottom: 'rgba(209, 209, 209, 1) 1px solid' }}>
                                <div className='country-conatent flex justify-content-end  pt-2 pb-2 px-3 bg-gray-50'>
                                    <div className="tab-head-btn flex justify-content-end flex-wrap w-full" >
                                        <div className="flex align-items-center justify-content-center">
                                            <Button className=' mr-2 w-3rem flex align-items-center justify-content-center' id="b-btn" style={{ border: 'none', backgroundColor: 'transparent' }} tooltip="Export" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} disabled={UserDetails} onClick={toggleMenu} ><i className='pi pi-upload pl-1'></i></Button>
                                            <Menu
                                                className='unique-menu-exp'
                                                model={items}
                                                popup
                                                ref={(el) => (menu = el)}
                                                onHide={() => setVisible(false)}
                                                appendTo={document.body}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable
                                value={UserDetails.UserReport}
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 20, 35, 50]}
                            >
                                {cols.map((col, index) => (
                                    <Column key={index} field={col.field} header={col.header} />
                                ))}
                            </DataTable>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
}

export default UserActivity;
