import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const CorporateChart1 = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const chartData1 = {
    labels: Object.keys(data),
    datasets: [
      {
        type: 'bar',  // Change to 'bar'
        label: 'Transaction Count',
        data: Object.values(data).map(item => item.transactionCount),
        backgroundColor: '#FFE0E6',
        borderColor: '#FFE0E6',
        borderWidth: 2
      },
      {
        type: 'bar',  // Change to 'bar'
        label: 'Total Amount',
        data: Object.values(data).map(item => item.totalAmount),
        backgroundColor: '#DCFCE7',
        borderColor: '#DCFCE7',
        borderWidth: 2
      },
    ],
  };

  const chartOptions1 = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: '#555555',
          usePointStyle: true
        },
        position: 'bottom',
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#555555',
          font: {
            size: 10
          },
        },
        grid: {
          color: '#E5E4E2',
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#555555'
        },
        grid: {
          color: '#E5E4E2',
          // display: false,
        }
      }
    }
  };

  return (
    <div className="width-height-chart" style={{ width: (windowWidth < 500 ? '90%' : ''), padding: '20px 0' }} >
      <h4 style={{ marginLeft: '5px', color: '#124A99' }} className='text-center'>Transaction</h4>
      <div className="flex justify-content-center w-full">
        <Chart type="bar" data={chartData1} options={chartOptions1} className="w-full md:w-28rem h-20rem" />
      </div>
    </div>
  );
};

export default CorporateChart1;
