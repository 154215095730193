import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Stomp from 'stompjs';
import { FaBell } from 'react-icons/fa'; // Import the icon you want to use

class WebSocketComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: JSON.parse(sessionStorage.getItem('messages')) || [],
      messagess: JSON.parse(sessionStorage.getItem('TodayactivityCount')) || [],
      notificationCount: parseInt(sessionStorage.getItem('notificationCount'), 10) || 0,
    };
    this.stompClient = null;
  }
  componentDidMount() {
    this.connectWebSocket();
  }

  componentWillUnmount() {
    this.disconnectWebSocket();
  }

  connectWebSocket = () => {
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_BACKEND_HOST + "/websocket");
    this.stompClient = Stomp?.over(socket);
    this.stompClient?.connect({}, (frame) => {

      this.stompClient.subscribe('/topic/company-', (message) => {
        try {
          const messageData = JSON.parse(message.body);

          const newMessages = [...this.state.messages, messageData.message];
          sessionStorage.setItem('messages', JSON.stringify(newMessages));

          const newNotificationCount = newMessages.length;
          sessionStorage.setItem('notificationCount', newNotificationCount);

          this.setState({ messages: newMessages, notificationCount: newNotificationCount }, () => {
            sessionStorage.setItem('messages', JSON.stringify(newMessages));
            sessionStorage.setItem('notificationCount', newNotificationCount);
          });
          toast.success(
            <div>
              <span style={{ marginRight: '8px' }}>
                <FaBell />
              </span>
              {messageData.message}
            </div>
          );
        } catch (error) {
          console.error('Error parsing message:', error);
          toast?.error('Error parsing message: Invalid JSON format');
        }
      });
    });
  };

  disconnectWebSocket = () => {
    if (this.stompClient && this.stompClient?.connected) {
      this.stompClient.disconnect();
    }
    //  else if (this.stompClient && this.stompClient?._client.ws?.readyState === WebSocket?.CONNECTING) {

    // }
  };

  render() {
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
}

export default WebSocketComponent;
