import React, { useRef, useEffect, useState } from 'react';
import "./BlogItem.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { FaFacebook, FaInstagram, FaSquareXTwitter } from 'react-icons/fa6';
import { IoLogoLinkedin } from 'react-icons/io';
import { Button } from 'primereact/button';

export default function BlogItem() {

    const location = useLocation();
    const blog_ = location?.state?.blogItemS;
    const post = location?.state?.blogItemS
    const history = useHistory()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (!post) {
            history.push({
                pathname: '/',
            });
        }
    }, []);

    const handleShare = (option) => {

        switch (option) {
            case 'Instagram':
                window.open(`https://www.instagram.com/`, '_blank');
                break;
            case 'Facebook':
                window.open(`https://www.facebook.com/`, '_blank');
                break;
            case 'X':
                window.open(`https://x.com/`, '_blank');
                break;
            case 'LinkedIn':
                window.open(`https://www.linkedin.com/`, '_blank');
                break;
            default:
                break;
        }
    };

    const socialMedia = [
        { icon: <FaFacebook />, name: 'Facebook' },
        { icon: <FaSquareXTwitter />, name: 'X' },
        { icon: <FaInstagram />, name: 'Instagram' },
        { icon: <IoLogoLinkedin />, name: 'LinkedIn' },
    ]

    return (
        <>
            <Header />
            <div className='blogItem_Sec'>
                <div className='blogItem_Sec_T  bj_IteMS' style={{ backgroundImage: 'url(' + blog_?.blogImage[0]?.fields?.file?.url + ')' }}>
                    <div className='blog_Hea'>
                        <div className='blogItem_Sec_T_H'>{blog_?.blogTitle}</div>
                        <div className='blogItem_Sec_T_S'>{blog_?.blogAuthor} | {post?.createdDate}</div>
                    </div>
                </div>

                <div className='blogItem_Sec_C max_W'>
                    <div className='blog_Note'>
                        <div className="blog-view-card">
                            <div>{post?.blogSummary}</div>

                            <div className="blog-view-body">
                                <ReactMarkdown children={post?.blogContent} style={{ fontSize: '25px' }} />
                            </div>
                        </div>

                        <div className='blog_Foo_'>
                            {socialMedia?.map((item, index) => (
                                <Button icon={item?.icon} className='blog_Foo_BTN' onClick={() => handleShare(item?.name)} />
                            ))}
                        </div>
                    </div>
                </div>

            </div >

            <Footer />
        </>
    );
}
