import axios from 'axios';

class RolesService {

    getProducts(accessToken) {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let uri = "/roles/all";

        return axios.get(process.env.REACT_APP_IDM_BACKEND_HOST + uri, requestOptions).then(res => res.data);
    }

    getScopes(accessToken) {

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        let uri = "/scopes";

        return axios.get(process.env.REACT_APP_IDM_BACKEND_HOST + uri, requestOptions).then(res => res.data);
    }

    // createProduct(_product) {

    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    //     };

    //     let uri = "/roles";

    //     return axios.post(process.env.REACT_APP_IDM_BACKEND_HOST + uri, _product, {
    //         headers: headers
    //     }).then(res => res.data);
    // }

    // updateProduct(_product) {

    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    //     };

    //     let uri = "/roles";

    //     return axios.put(process.env.REACT_APP_IDM_BACKEND_HOST + uri, _product, {
    //         headers: headers
    //     }).then(res => res.data);
    // }
}

export default RolesService
