import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import './NotificationPreferences.css';
import { Toast } from 'primereact/toast';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from 'primereact/button';
import { IoMailOutline } from "react-icons/io5";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { MdDoNotDisturb } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';

function NotificationPreferences(props) {
  const [data, setData] = useState([]);
  const [updatedPreferences, setUpdatedPreferences] = useState({});
  const toast = useRef(null);
  const history = useHistory();
  const [btnloading, setBtnLoading] = useState(false);
  const [btnloading1, setBtnLoading1] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const items = [{ label: 'Alert Preference' }];
  const home = {
    icon: 'pi pi-home', command: () =>
      history.push({
        pathname: '/admin/dashboard',
        state: {
          accessToken: location?.state?.accessToken,
          emailId: location?.state?.emailId,
          getUserData: location?.state?.getUserData,
        },
      })
  };

  const displayErrorToast = (message) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 5000 });
  };

  const displaySuccessToast = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 5000 });
  };

  const load1 = () => {
    setBtnLoading(true);

    setTimeout(() => {
      setBtnLoading(false);
    }, 1000);
  };

  const load = () => {
    setBtnLoading1(true);

    setTimeout(() => {
      setBtnLoading1(false);
    }, 1000);
  };

  const fetchNotificationData = async () => {
    load();
    setData([]);
    setLoading(true);
    try {
      const token = location?.state?.accessToken;
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/notifications`, requestOptions);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response && error?.response?.status === 403) {
        window?.location?.assign(process?.env?.REACT_APP_AUTH_URL);
        sessionStorage.clear();
      }
      else if (error?.response && error?.response?.data && error?.response?.data?.message) {
        const errorMessage = error?.response?.data?.message;
        displayErrorToast(errorMessage);
      } else {
        displayErrorToast('An error occurred. Please try again later.');
      }
    }
  };

  useEffect(() => {
    const fetchNotificationData = async () => {
      setLoading(true);
      try {
        const token = location?.state?.accessToken;
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_HOST}/notifications`, requestOptions);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response && error?.response?.status === 403) {
          window.location.assign(process.env.REACT_APP_AUTH_URL);
          sessionStorage.clear();
        }
        else if (error?.response && error?.response?.data && error?.response?.data?.message) {
          const errorMessage = error?.response?.data?.message;
          displayErrorToast(errorMessage);
        } else {
          displayErrorToast('An error occurred. Please try again later.');
        }
      }
    };

    fetchNotificationData();
  }, [location?.state?.accessToken]);


  const handleUpdateAllClick = async () => {
    load1();
    setLoading(true);
    try {
      const token = location?.state?.accessToken;
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };

      const updatedNotifications = [];

      for (const id in updatedPreferences) {
        updatedNotifications.push({
          id: id,
          ...updatedPreferences[id]
        });
      }

      await axios.put(`${process.env.REACT_APP_BACKEND_HOST}/notifications/update`, updatedNotifications, requestOptions);
      setLoading(false);
      displaySuccessToast('All notification preferences updated Successfully');
    } catch (error) {
      setLoading(false);
      if (error?.response && error?.response?.status === 403) {
        window.location.assign(process.env.REACT_APP_AUTH_URL);
        sessionStorage.clear();
      }
      else if (error?.response && error?.response?.data && error?.response?.data?.message) {
        const errorMessage = error?.response?.data?.message;
        displayErrorToast(errorMessage);
      } else {
        displayErrorToast('An error occurred while updating notification preferences');
      }
    }
  };

  const handleIconClick = (id, type, value) => {
    const updatedData = data.map(item => {
      const notificationDAOS = item.notificationDAOS.map(notification => {
        if (notification.id === id && notification.officer.toLowerCase() === type.toLowerCase()) {
          const updatedNotification = { ...notification, [type]: value };
          return updatedNotification;
        }
        return notification;
      });
      return { ...item, notificationDAOS };
    });

    setData(updatedData);

    const updatedPrefs = { ...updatedPreferences };
    updatedPrefs[id] = { ...updatedPrefs[id], [type]: value };
    setUpdatedPreferences(updatedPrefs);
    const iconColor = type === 'mail' ? '#11AB58' : type === 'sms' ? '#2267EF' : type === 'none' ? '#FE9707' : 'gray';
    const iconElement = document.getElementById(`${type}-${id}`);
    if (iconElement.style.color === 'gray') {
      iconElement.style.color = iconColor;
    } else {
      iconElement.style.color = 'gray'
    }
    const otherIconTypes = ['mail', 'sms', 'none'];
    otherIconTypes.forEach(iconType => {
      if (iconType !== type) {
        const otherIconElement = document.getElementById(`${iconType}-${id}`);
        if (otherIconElement) {
          otherIconElement.style.color = 'gray';
        }
      }
    });
  };

  return (
    <>
      <div className='mainSec noti'>
        <Toast ref={toast} />
        <BreadCrumb model={items} home={home} style={{ border: 'none' }} />
        {loading &&
          <div className="spin-wrapper" style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', margin: 'auto' }}>
            <Triangle
              height="80"
              width="80"
              color="#124A99"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        }
        <div className="card card-noti w-full bg-white">
          <div className="individual-account-search shadow-2" style={{ border: 'solid rgba(209, 209, 209, 1) 0px' }}>
            <div className='notification-flex-mainsec'>
              <div className='html-table bg-white w-full h-max'>
                <div className='html-tab-content text-center w-full border-collapse header-name '>
                  <p className='w-max' style={{ height: '1rem', paddingLeft: "20px", paddingRight: '20px', fontWeight: 'bold', color: '#124A99' }}>Admin Notification Alert</p>
                </div>
                <table className="html-tab-content text-center w-full border-collapse">
                  <thead className='w-max'>
                    <tr className="header-row" style={{ backgroundColor: 'rgb(248 248 248)', borderColor: '#68a1c1', color: '#124A99' }}>
                      <th className='AP-th' style={{ paddingLeft: '3rem', textAlign: 'left', width: '12rem', fontWeight: 700, fontSize: '15px' }}>
                        Customer
                      </th>
                      <th className="p-2 AP-th">
                        Branch Officer
                      </th>
                      <th className="abgj AP-th">
                        Area Officer
                      </th>
                      <th className="abgj AP-th">
                        Compliance Officer
                      </th>
                      <th className="abgj1 AP-th">
                        Chief Compliance Officer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index} className='' style={{ backgroundColor: 'white' }}>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-no-wrap p-5">{item.type}</td>
                        {['BRANCH OFFICER', 'AREA OFFICER', 'COMPLIANCE OFFICER', 'CHIEF COMPLIANCE OFFICER'].map((officerType, idx) => {
                          const notification = item.notificationDAOS.find(notification => notification.officer === officerType);
                          return (
                            <React.Fragment key={idx}>
                              <td className="border-t-0 align-middle border-l-0 border-r-0 whitespace-no-wrap">
                                {notification ? (
                                  <>
                                    <div className="notification-icon-bg " style={{ justifyContent: 'center', gap: '16px' }}>

                                      <IoChatbubbleEllipsesSharp
                                        id={`sms-${notification.id}`}
                                        style={{ color: notification.sms ? '#2267EF' : 'gray', cursor: 'pointer', padding: '3px' }}
                                        onClick={() => handleIconClick(notification.id, 'sms', !notification.sms)}
                                        className="footer_Sub_Btn alertCriteria"
                                      />
                                      <IoMailOutline
                                        id={`mail-${notification.id}`}
                                        style={{ color: notification.mail ? '#11AB58' : 'gray', cursor: 'pointer', padding: '3px' }}
                                        onClick={() => handleIconClick(notification.id, 'mail', !notification.mail)}
                                        className="footer_Sub_Btn alertCriteria notification"
                                      />
                                      <MdDoNotDisturb
                                        id={`none-${notification.id}`}
                                        style={{ color: !notification.mail && !notification.sms ? '#FE9707' : 'gray', cursor: 'pointer', padding: '3px' }}
                                        onClick={() => handleIconClick(notification.id, 'none', true)}
                                        className="footer_Sub_Btn alertCriteria"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <IoChatbubbleEllipsesSharp style={{ color: 'gray', cursor: 'pointer', padding: '3px' }} />
                                    <IoMailOutline style={{ color: 'gray', cursor: 'pointer', padding: '3px' }} />
                                    <MdDoNotDisturb style={{ color: 'blue', cursor: 'pointer', padding: '3px' }} />
                                  </>
                                )}
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='notificiation-btn' style={{ justifyContent: 'end' }}>
                {props.roleName === 'ProAdmin' &&
                  <Button className="flex-notificiation-btn" style={{ backgroundColor: '#124A99', border: '0px solid #124A99', borderRadius: '6px', fontSize: '14px', height: '2.3rem' }} onClick={handleUpdateAllClick} loading={btnloading}>Update All</Button>}
                <Button className="flex-notificiation-btn" style={{ backgroundColor: '#124A99', border: '0px solid #124A99', borderRadius: '6px', fontSize: '14px', height: '2.3rem' }} onClick={fetchNotificationData} loading={btnloading1}>Reset</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationPreferences;